import { Box } from "@swan-io/lake/src/components/Box";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { FaCalendar, FaInfo, FaRegCalendar } from "react-icons/fa6";
import { Pressable, StyleSheet, Text, TouchableOpacity } from "react-native";
import Popover, { PopoverPlacement } from "react-native-popover-view";
import { match } from "ts-pattern";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { getAlign } from "../../../utils/utils";

const styles = StyleSheet.create({
  cell: {
    flexGrow: 1,
    gap: 4,
  },
  tag: {
    borderRadius: 50,
    paddingHorizontal: 12,
    borderWidth: 0,
  },
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 4,
    color: fontColorVariants.neutral600,
    backgroundColor: backgroundColorVariants.neutral100,
    borderRadius: 50,
    width: 28,
    height: 28,
  },
  menu: {
    padding: 8,
    borderRadius: 8,
    boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.05), 0px 8px 24px -3px rgba(16, 24, 40, 0.10)",
  },
  menuItem: {
    display: "flex",
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
    gap: 6,
  },
});

const getColor = (status: string) =>
  match(status)
    .with("PAID", () => "partner" as const)
    .otherwise(() => "positive" as const);

type Props = {
  status: string;
  text: string;
  align?: "left" | "right" | "center";
  onChangeStatus: () => void;
  paymentDate?: string;
  chargeMatch?: string;
  onAction: () => void;
};

export const StatusCell = ({
  status,
  text,
  align,
  onChangeStatus,
  onAction,
  paymentDate = "",
  chargeMatch = "",
}: Props) => {
  return (
    <Box
      direction="row"
      style={{
        ...styles.cell,
        justifyContent: getAlign(align as "left" | "right" | "center") as
          | "center"
          | "flex-start"
          | "flex-end"
          | "space-between"
          | "space-around"
          | "space-evenly",
      }}
    >
      <Pressable
        onPress={() => {
          onChangeStatus();
          onAction();
        }}
      >
        <Tag color={getColor(status)} style={styles.tag}>
          {text}
        </Tag>
      </Pressable>

      {(paymentDate !== "" || chargeMatch !== "") && (
        <Popover
          placement={PopoverPlacement.LEFT}
          from={
            <TouchableOpacity style={styles.info}>
              <FaInfo size={12} />
            </TouchableOpacity>
          }
        >
          <Pressable
            style={styles.menu}
            onTouchEnd={e => {
              e.stopPropagation();
            }}
          >
            {paymentDate !== "" && (
              <Text style={styles.menuItem}>
                <FaRegCalendar /> {paymentDate}
              </Text>
            )}

            {chargeMatch !== "" && (
              <Text style={styles.menuItem}>
                <FaCalendar /> {chargeMatch}
              </Text>
            )}
          </Pressable>
        </Popover>
      )}
    </Box>
  );
};
