import trash from "@assets/icons/delete.svg";
import KebabMenu from "@components/KebabMenu";
import { KebabMenuItem } from "@components/KebabMenuItem";
import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { ContactRelay, DeleteCustomerDocument, DeleteSupplierDocument } from "@graphql/crm";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { ReactElement, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { errorHandling } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { useLoading } from "../../context/LoadingContext";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";
import { NameCell, TypeCell } from "./ListCells";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  contacts: ContactRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<ContactRelay, ExtraInfo>) => ReactElement;
  onRefreshRequest?: () => void;
};

type ExtraInfo = undefined;

export const DetailsList = ({
  contacts,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
  onRefreshRequest,
}: Props) => {
  const { setLoading } = useLoading();
  const [, deleteCustomer] = useUrqlMutation(DeleteCustomerDocument);
  const [, deleteSupplier] = useUrqlMutation(DeleteSupplierDocument);

  const columns: ColumnConfig<ContactRelay, ExtraInfo>[] = [
    {
      id: "type",
      width: 150,
      title: t("contact.type"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <TypeCell typename={item.type} />,
    },
    {
      id: "name",
      width: "grow",
      title: t("contact.name"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <NameCell name={item.name} />,
    },
    {
      id: "nif",
      width: "grow",
      title: t("contact.nif"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => <DefaultCell data={item.nif ?? ""} align="center" />,
    },
    {
      id: "options",
      width: 31,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <KebabMenu visible={visible} onChange={setVisible}>
          <KebabMenuItem
            onPress={() => handleDelete(item)}
            icon={trash}
            text={t("common.delete")}
          />
        </KebabMenu>
      ),
    },
  ];

  const smallColumns: ColumnConfig<ContactRelay, ExtraInfo>[] = columns.filter(c =>
    ["type", "name", "options"].includes(c.id),
  );

  const [visible, setVisible] = useState(false);

  const handleDelete = (item: ContactRelay) => {
    setLoading(true);

    if (item.type === "CUSTOMER") {
      deleteCustomer({ id: String(item.pk) }).mapOk(data => {
        setVisible(false);
        match(data.deleteCustomer)
          .with({ __typename: "DeleteContactOutput" }, successHandling)
          .with({ __typename: "OperationInfo" }, errorHandling);
        setLoading(false);
      });
    } else {
      deleteSupplier({ id: String(item.pk) }).mapOk(data => {
        setVisible(false);
        match(data.deleteSupplier)
          .with({ __typename: "DeleteContactOutput" }, successHandling)
          .with({ __typename: "OperationInfo" }, errorHandling);
        setLoading(false);
      });
    }

    const successHandling = () => {
      showToast({
        title: t("common.deleted"),
        variant: "success",
        autoClose: true,
      });
      onRefreshRequest?.();
    };
  };

  return (
    <PlainListView
      withoutScroll={true}
      data={contacts}
      keyExtractor={item => item.id as string}
      headerHeight={36}
      rowHeight={63}
      headerStyle={styles.header as ViewStyle}
      headerBackgroundColor="#EDEDED"
      groupHeaderHeight={36}
      extraInfo={undefined}
      columns={columns}
      smallColumns={smallColumns}
      activeRowId={activeRowId}
      onActiveRowChange={onActiveRowChange}
      loading={loading}
      onEndReached={onEndReached}
      getRowLink={getRowLink}
      renderEmptyList={renderEmptyList}
      breakpoint={breakpoints.tiny}
    />
  );
};
