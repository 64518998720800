import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { borderColorVariants } from "../styles/constants";

const styles = StyleSheet.create({
  menuItem: {
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
  },
  menuText: {
    marginLeft: 8,
  },
});

type Props = {
  onPress: () => void;
  icon?: string;
  renderIcon?: React.ReactNode;
  text: string;
};

export const KebabMenuItem = ({ onPress, icon, renderIcon, text }: Props) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.menuItem}>
      {icon != null && <AutoWidthImage sourceUri={icon} height={23} width={23} />}
      {renderIcon}

      <Text style={styles.menuText}>{text}</Text>
    </TouchableOpacity>
  );
};
