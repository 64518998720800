import { useEffect, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Popover from "react-native-popover-view";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: 4,
    width: "100%",
  },
  menu: {
    padding: 8,
    borderRadius: 8,
  },
});

type KebabMenuProps = {
  children: React.ReactNode;
  visible?: boolean;
  onChange?: (open: boolean) => void;
};

const KebabMenu = ({ children, visible = false, onChange }: KebabMenuProps) => {
  const [isVisible, setVisible] = useState<boolean>(visible);

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  useEffect(() => {
    onChange?.(isVisible);
  }, [isVisible]);

  return (
    <View
      style={styles.container}
      onTouchEnd={e => {
        e.stopPropagation();
      }}
    >
      <Popover
        isVisible={isVisible}
        popoverStyle={styles.menu}
        onRequestClose={() => setVisible(false)}
        from={
          <TouchableOpacity onPress={() => setVisible(true)}>
            <FiMoreVertical size={20} />
          </TouchableOpacity>
        }
      >
        <View
          style={styles.menu}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          {children}
        </View>
      </Popover>
    </View>
  );
};

export default KebabMenu;
