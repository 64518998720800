import { OperationInfo } from "@graphql/crm";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { DatePickerDate } from "@swan-io/shared-business/src/components/DatePicker";
import { isValidEmail } from "@swan-io/shared-business/src/utils/validation";
import dayjs from "dayjs";
import { Form, Validator } from "react-ux-form";
import { P, match } from "ts-pattern";
import { CombinedError } from "urql";
import { locale, t } from "./i18n";

export const passValidatorTrue =
  <Value, ErrorMessage = string>(
    validator: Validator<Value, ErrorMessage>,
    switcher: boolean | undefined,
  ): Validator<Value, ErrorMessage> =>
  value => {
    if (switcher !== undefined && !switcher) {
      return validator(value);
    }
  };

export const validateOptional =
  <Value, ErrorMessage = string>(
    validator: Validator<Value, ErrorMessage>,
  ): Validator<Value, ErrorMessage> =>
  value => {
    if (value !== undefined && value !== "") {
      return validator(value);
    }
  };

export const validateNullableRequired: Validator<string | undefined> = value => {
  if (value == null || !value) {
    return t("common.form.required");
  }
};

export const validateNumericNullableRequired: Validator<number | undefined> = value => {
  if (value == null) {
    return t("common.form.required");
  }
};

export const validateRequired: Validator<string> = value => {
  if (!value) {
    return t("common.form.required");
  }
};

export const validateChecked: Validator<boolean> = value => {
  if (!value) {
    return t("common.form.required");
  }
};

export const validateEmail: Validator<string> = value => {
  if (!isValidEmail(value)) {
    return t("common.form.invalidEmail");
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateHasItems: Validator<any[]> = value => {
  if (value.length === 0) {
    return t("common.form.required");
  }
};

export const errorHandling = (info: { messages: { message: string }[] }) => {
  tapError(info.messages[0] as Error);
};

export const tapError = (error: Error | CombinedError) => {
  showToast({ variant: "error", title: error.message, autoClose: true });
};

function hasKey<T extends object>(obj: T, key: PropertyKey): key is keyof T {
  return key in obj;
}

export function extractQueryData<T, R>(response: T, path: string): R | undefined {
  return path.split(".").reduce<R | undefined>(
    (acc, part) => {
      if (acc !== null && acc !== undefined && typeof acc === "object" && hasKey(acc, part)) {
        const value = acc[part];
        return value as R;
      }
      return undefined;
    },
    response as unknown as R | undefined,
  );
}

export const handlerErrors = (data: OperationInfo) => {
  let errorMessage = "";
  for (const error of data.messages) {
    errorMessage += `${error.field}: ${error.message}\n`;
  }
  showToast({
    variant: "error",
    title: errorMessage.trim(),
    autoClose: true,
  });
};

export const handlerFieldErrors = <T extends Record<string, unknown>>(
  data: OperationInfo,
  setFieldError: Form<T>["setFieldError"],
) => {
  for (const error of data.messages) {
    if (error.field != null) {
      setFieldError(error?.field as keyof T, error?.message ?? "");
    } else {
      showToast({
        variant: "error",
        title: error.message,
        autoClose: true,
      });
    }
  }
};

export const validateDate: Validator<string> = value => {
  if (!dayjs(value, locale.dateFormat, true).isValid()) {
    return t("common.form.invalidDate");
  }
};

export const isAfterEmitedAtSelectable = (date: DatePickerDate, filters: unknown) => {
  return match(filters)
    .with({ isBeforeEmitedAt: P.string }, ({ isBeforeEmitedAt }) => {
      const isAfterEmitedAt = dayjs().date(date.day).month(date.month).year(date.year).endOf("day");
      const isBeforeEmitedAtDate = dayjs(isBeforeEmitedAt).startOf("day");

      return isAfterEmitedAt.isBefore(isBeforeEmitedAtDate);
    })
    .otherwise(() => true);
};

// value comes from input text above the datepicker
// so the format of value is locale.dateFormat
export const validateAfterEmitedAt = (value: string, filters: unknown) => {
  return (
    validateRequired(value) ??
    validateDate(value) ??
    match(filters)
      .with({ isBeforeEmitedAt: P.string }, ({ isBeforeEmitedAt }) => {
        const isAfterEmitedAt = dayjs(value, locale.dateFormat).endOf("day");
        const isBeforeEmitedAtDate = dayjs(isBeforeEmitedAt).startOf("day");

        if (!isAfterEmitedAt.isBefore(isBeforeEmitedAtDate)) {
          const updatedBefore = isBeforeEmitedAtDate.format("LL");
          return t("common.form.chooseDateBefore", { date: updatedBefore });
        }
      })
      .otherwise(() => undefined)
  );
};

export const isBeforeEmitedAtSelectable = (date: DatePickerDate, filters: unknown) => {
  return match(filters)
    .with({ isAfterEmitedAt: P.string }, ({ isAfterEmitedAt }) => {
      const isBeforeEmitedAt = dayjs()
        .date(date.day)
        .month(date.month)
        .year(date.year)
        .startOf("day");
      const isAfterEmitedAtDate = dayjs(isAfterEmitedAt).endOf("day");

      return isBeforeEmitedAt.isAfter(isAfterEmitedAtDate);
    })
    .otherwise(() => true);
};

// value comes from input text above the datepicker
// so the format of value is locale.dateFormat
export const validateBeforeEmitedAt = (value: string, filters: unknown) => {
  return (
    validateRequired(value) ??
    validateDate(value) ??
    match(filters)
      .with({ isAfterEmitedAt: P.string }, ({ isAfterEmitedAt }) => {
        const isBeforeEmitedAt = dayjs(value, locale.dateFormat).startOf("day");
        const isAfterEmitedAtDate = dayjs(isAfterEmitedAt).endOf("day");

        if (!isBeforeEmitedAt.isAfter(isAfterEmitedAtDate)) {
          const updatedAfter = isAfterEmitedAtDate.format("LL");
          return t("common.form.chooseDateAfter", { date: updatedAfter });
        }
      })
      .otherwise(() => undefined)
  );
};

export const statusConfig = {
  PENDING: {
    disabled: ["seriesId", "issueDate", "expirationDate"],
  },
  OVERDUED: {
    disabled: [
      "customerId",
      "seriesId",
      "issueDate",
      "expirationDate",
      "paymentMethod",
      "iban",
      "notes",
      "concepts",
    ],
  },
  DEFAULTED: {
    disabled: [
      "customerId",
      "seriesId",
      "issueDate",
      "expirationDate",
      "paymentMethod",
      "iban",
      "notes",
      "concepts",
    ],
  },
  CHARGED: {
    disabled: [
      "customerId",
      "seriesId",
      "issueDate",
      "expirationDate",
      "paymentMethod",
      "iban",
      "notes",
      "concepts",
    ],
  },
  DRAFT: {
    disabled: [],
  },
  PROFORMA: {
    disabled: [],
  },
  QUOTE: {
    disabled: [],
  },
  DELIVERY: {
    disabled: [],
  },
  // SOME_NEW_STATUS: {
  //   disabled: ['seriesId'],
  //   customValidation: {issueDate: validateFutureDate}
  // }
};

export type Status = keyof typeof statusConfig;
