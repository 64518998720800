import dayjs from "dayjs";
import { locale } from "./i18n";

export const encodeDate = (date: string) => {
  const dateTime = dayjs(date, locale.dateFormat);
  const formattedDate = dateTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
  return dateTime.isValid() ? formattedDate : "";
};

export const encodeShortDate = (date: string) => {
  const dateTime = dayjs(date, locale.dateFormat);
  const formattedDate = dateTime.format("YYYY-MM-DD");
  return dateTime.isValid() ? formattedDate : "";
};

export const encodeDateISO = (date: string) => {
  const dateTime = dayjs(date);
  return dateTime.isValid() ? dateTime.format(locale.dateFormat) : "";
};

export const formatDate = (date: string, format: string) => {
  const dateTime = dayjs(date);
  return dateTime.isValid() ? dateTime.format(format) : "";
};

export const diffDays = (from: string, to: string) => {
  return dayjs(to, locale.dateFormat).diff(dayjs(from, locale.dateFormat), "day");
};

export const toDate = (date: string) => dayjs(date).toDate();

export const getToday = () => dayjs().format(locale.dateFormat);

export const addDaysToDate = (date: string, days: number) =>
  dayjs(date, locale.dateFormat).add(days, "day").format(locale.dateFormat);

export const getHour = () => dayjs().hour();
export const getYear = () => dayjs().year();

export const getFirstDayOfMonth = () => dayjs().startOf("month").format(locale.dateFormat);

export const getLastDayOfMonth = () => dayjs().endOf("month").format(locale.dateFormat);

export const isDateInCurrentQuarter = (date: string, grace: boolean = false) => {
  if (date == null) {
    return false;
  }

  const value = dayjs(date);
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const currentQuarter = Math.floor(currentMonth / 3) + 1;

  const quarterStartDate = dayjs()
    .year(currentYear)
    .month((currentQuarter - 1) * 3)
    .date(1);
  let quarterEndDate = dayjs()
    .year(currentYear)
    .month(currentQuarter * 3)
    .date(0);

  if (grace) {
    if (currentQuarter === 4) {
      quarterEndDate = dayjs()
        .year(currentYear + 1)
        .month(0)
        .date(30);
    } else {
      quarterEndDate = dayjs()
        .year(currentYear)
        .month(currentQuarter * 3)
        .date(20);
    }
  }

  return value >= quarterStartDate && value <= quarterEndDate;
};
