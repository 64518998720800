import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { InputRounded } from "@components/forms/Input";
import {
  ContractType,
  EmployeeRelay,
  EmployeesDocument,
  EmployeesStaticDocument,
  InputMaybe,
  Static,
} from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { FocusTrapRef } from "@swan-io/lake/src/components/FocusTrap";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ListRightPanel } from "@swan-io/lake/src/components/ListRightPanel";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlPaginatedQuery, useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useCallback, useMemo, useRef, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { extractQueryData } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { EmployeeCreate } from "../components/EmployeeCreate";
import { EmployeeDetail } from "../components/EmployeeDetail";
import { DetailsList } from "../components/EmployeesDetailsList";
import { EmployeeFiltersState, ListFilter } from "../components/EmployeesListFilter";
import { ImportWizard } from "../components/ImportWizard";
import { Navigation as ReportsNavigation } from "../components/Navigation";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  heading: {
    zIndex: 1,
  },
  list: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
});

const NUM_TO_RENDER = 7;

export const EmployeesArea = () => {
  const route = Router.useRoute(["HrEmployeesRoot"]);

  const params = match(route)
    .with({ name: "HrEmployeesRoot" }, ({ params }) => params)
    .otherwise(() => {});

  const filters: EmployeeFiltersState = useMemo(() => {
    return {
      search: params?.search,
      startDateFrom: params?.startDateFrom,
      startDateTo: params?.startDateTo,
      endDateFrom: params?.endDateFrom,
      endDateTo: params?.endDateTo,
      category: params?.category,
      contractType: params?.contractType,
    } as EmployeeFiltersState;
  }, [
    params?.search,
    params?.startDateFrom,
    params?.startDateTo,
    params?.endDateFrom,
    params?.endDateTo,
    params?.category,
    params?.contractType,
  ]);

  const { data: statics } = useUrqlQuery({ query: EmployeesStaticDocument }, []);

  const contractTypes = extractQueryData(
    statics,
    "value.value.employeesStatic.contractTypes",
  ) as Static[];
  const categories = extractQueryData(
    statics,
    "value.value.employeesStatic.categories",
  ) as Static[];

  const { desktop } = useResponsive();

  const {
    data: query,
    nextData,
    reload,
  } = useUrqlPaginatedQuery(
    {
      query: EmployeesDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          search: params?.search,
          startDate: { gte: params?.startDateFrom, lte: params?.startDateTo },
          endDate: { gte: params?.endDateFrom, lte: params?.endDateTo },
          category: { inList: params?.category as InputMaybe<string[]> },
          contractType: { inList: params?.contractType as InputMaybe<ContractType[]> },
        },
      },
    },
    [
      params?.search,
      params?.startDateFrom,
      params?.startDateTo,
      params?.endDateFrom,
      params?.endDateTo,
      params?.category,
      params?.contractType,
    ],
  );

  const [activeId, setActiveId] = useState<string | null>(null);

  const panelRef = useRef<FocusTrapRef | null>(null);

  const onActiveRowChange = useCallback((element: HTMLElement) => {
    return panelRef.current?.setInitiallyFocusedElement(element);
  }, []);

  const data = query
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ employees }) => Option.fromNullable(employees))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const onClose = () => {
    setActiveId(null);
    Router.push("HrEmployeesRoot");
    reload();
  };

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <ReportsNavigation />
            </Sidebar>
          )}

          <ScrollView>
            <View style={styles.root}>
              <View style={commonStyles.fill} role="main">
                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.header}
                >
                  <InputRounded>
                    <LakeSearchField
                      placeholder={t("common.search")}
                      initialValue={params?.search ?? ""}
                      onChangeText={search =>
                        Router.push("HrEmployeesRoot", { ...filters, search })
                      }
                    />
                  </InputRounded>

                  <Box direction="row" justifyContent="end" alignItems="end">
                    <ListFilter
                      filters={filters}
                      onChange={filters => Router.push("HrEmployeesRoot", filters)}
                      categories={categories}
                      contractTypes={contractTypes}
                    />
                  </Box>
                </Box>

                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.heading}
                >
                  <Heading title={t("common.employees")} />
                </Box>

                <Box style={styles.list}>
                  <DetailsList
                    data={data as EmployeeRelay[]}
                    onActiveRowChange={onActiveRowChange}
                    activeRowId={activeId ?? undefined}
                    onRefreshRequest={reload}
                    onEndReached={() => {}}
                    loading={{ isLoading: nextData.isLoading(), count: 20 }}
                    getRowLink={({ item }) => (
                      <Pressable onPress={() => setActiveId(item.id as string)} />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                  />
                </Box>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>

      <EmployeeCreate visible={params?.create !== undefined} onPressClose={onClose} />

      <ListRightPanel
        ref={panelRef}
        keyExtractor={item => item.id as string}
        activeId={activeId}
        onActiveIdChange={setActiveId}
        onClose={onClose}
        items={data}
        render={(employee, large) => (
          <EmployeeDetail large={large} data={employee as EmployeeRelay} onClose={onClose} />
        )}
        closeLabel={t("common.closeButton")}
        previousLabel={t("common.next")}
        nextLabel={t("common.back")}
      />

      <LakeModal
        title={t("employee.import.title")}
        visible={params?.import != null}
        maxWidth={1000}
        onPressClose={onClose}
      >
        <ImportWizard onPressClose={onClose} />
      </LakeModal>
    </WithPartnerAccentColor>
  );
};
