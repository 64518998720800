import { Button, ButtonGroup } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import Input from "@components/forms/Input";
import {
  CalculatePayrollDocument,
  CreatePayrollDocument,
  EarnExtraPayroll,
  Employee,
  OperationInfo,
  Payroll,
  PayrollCalculate,
  PayrollStatus,
  UpdatePayrollDocument,
} from "@graphql/crm";
import { Accordion } from "@swan-io/lake/src/components/Accordion";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { Space } from "@swan-io/lake/src/components/Space";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { hideToast, showToast } from "@swan-io/lake/src/state/toasts";
import { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { CombinedError } from "urql";
import { useNestedForm } from "../../../components/NestedForm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import {
  diffDays,
  encodeDate,
  encodeDateISO,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { Router } from "../../../utils/routes";
import { extractQueryData, tapError, validateDate } from "../../../utils/validations";
import { PayrollState } from "../types";
import { EarnExtra } from "./EarnExtra";
import { Tabs } from "./Tabs";
import { RowDecimalInput } from "./payroll/RowDecimalInput";
import { RowMultiHeader } from "./payroll/RowMultiHeader";
import { RowMultiType } from "./payroll/RowMultiType";
import { Total } from "./payroll/Total";

const styles = StyleSheet.create({
  contain: {
    backgroundColor: backgroundColorVariants.white,
    paddingHorizontal: 32,
    paddingVertical: 56,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    width: "100dvw",
    maxWidth: 900,
    marginBottom: 16,
  },
  title: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 24,
  },
  dates: {
    width: 548,
    gap: 12,
  },
  column: {
    flexBasis: 200,
  },
  days: {
    width: 120,
  },
  accordion: {
    backgroundColor: backgroundColorVariants.gray50,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: borderColorVariants.gray200,
  },
  accordionHeader: {
    backgroundColor: backgroundColorVariants.gray50,
    borderRadius: 12,
    margin: 12,
  },
  accordionContent: {
    paddingTop: 0,
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 12,
  },
  row: {
    display: "flex",
    marginTop: 2,
    marginBottom: 2,
    minHeight: 32,
    alignItems: "center",
  },
  pl0: {
    paddingLeft: 0,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  newItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    backgroundColor: backgroundColorVariants.neutral10,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
});

type Props = {
  payroll?: Payroll;
  employee?: Employee;
};

export const PayrollForm = ({ payroll, employee }: Props) => {
  const { onSubmit, setMainForm } = useNestedForm();

  const status = payroll?.status?.toUpperCase() as PayrollStatus;
  const disabled = status === "PAID";

  const earnExtraInit = (type: string) => {
    const earnExtra = payroll?.earnExtra ?? employee?.earnExtra ?? [];
    if (earnExtra === undefined) {
      return [];
    }
    return earnExtra
      ?.filter(row => row.type === type)
      .map(row => {
        const { __typename, ...rest } = row;
        return rest as EarnExtraPayroll;
      });
  };

  const [fields, setFields] = useState<PayrollState>({
    periodStart:
      payroll?.periodStart != null ? encodeDateISO(payroll?.periodStart) : getFirstDayOfMonth(),
    periodEnd: payroll?.periodEnd != null ? encodeDateISO(payroll?.periodEnd) : getLastDayOfMonth(),
    days: payroll?.days?.toString() ?? "30",
    baseSalary: payroll?.baseSalary?.toString() ?? (employee?.baseSalary as string) ?? "0.00",
    payments: (employee?.payments as string) ?? "12",
    overtimeNormal:
      payroll?.overtimeNormal?.toString() ?? (employee?.overtimeNormal as string) ?? "0.00",
    overtimeForceMajeure:
      payroll?.overtimeForceMajeure?.toString() ??
      (employee?.overtimeForceMajeure as string) ??
      "0.00",
    extraordinaryPayments:
      payroll?.extraordinaryPayments?.toString() ??
      (employee?.extraordinaryPayments as string) ??
      "0.00",
    outlays: payroll?.outlays?.toString() ?? (employee?.outlays as string) ?? "0.00",
    socialSecurityBenefits:
      payroll?.socialSecurityBenefits?.toString() ??
      (employee?.socialSecurityBenefits as string) ??
      "0.00",
    indemnitiesTransfers:
      payroll?.indemnitiesTransfers?.toString() ??
      (employee?.indemnitiesTransfers as string) ??
      "0.00",
    advances: payroll?.advances?.toString() ?? "0.00",
    inKindProducts: payroll?.inKindProducts?.toString() ?? "0.00",
    otherDeductions: payroll?.otherDeductions?.toString() ?? "0.00",
  });

  const { Field, getFieldState, setFieldValue, setFieldError, listenFields, submitForm } =
    useForm<PayrollState>({
      periodStart: { initialValue: fields.periodStart, validate: validateDate },
      periodEnd: { initialValue: fields.periodEnd, validate: validateDate },
      days: { initialValue: fields.days },
      baseSalary: { initialValue: fields.baseSalary },
      payments: { initialValue: fields.payments },
      overtimeNormal: { initialValue: fields.overtimeNormal },
      overtimeForceMajeure: { initialValue: fields.overtimeForceMajeure },
      extraordinaryPayments: { initialValue: fields.extraordinaryPayments },
      outlays: { initialValue: fields.outlays },
      socialSecurityBenefits: { initialValue: fields.socialSecurityBenefits },
      indemnitiesTransfers: { initialValue: fields.indemnitiesTransfers },
      advances: { initialValue: fields.advances },
      inKindProducts: { initialValue: fields.inKindProducts },
      otherDeductions: { initialValue: fields.otherDeductions },
      earnExtraSupplements: {
        initialValue: earnExtraInit("salary_complement"),
      },
      earnExtraOtherNonSalaryCompensations: {
        initialValue: earnExtraInit("other_non_salary_compensations"),
      },
      earnExtraInKindSalary: {
        initialValue: earnExtraInit("in_kind_salary"),
      },
    });

  const setFieldsDebounce = useDebounce<PayrollState>(fields => setFields(fields), 500);

  useEffect(() => {
    listenFields(["periodStart", "periodEnd"], fields => {
      const periodStart = fields.periodStart.value;
      const periodEnd = fields.periodEnd.value;

      if (periodStart && periodEnd) {
        const days = diffDays(periodStart, periodEnd);
        setFieldValue("days", (days + 1).toString());
      }
    });

    listenFields(
      [
        "days",
        "baseSalary",
        "payments",
        "overtimeNormal",
        "overtimeForceMajeure",
        "outlays",
        "indemnitiesTransfers",
        "socialSecurityBenefits",
        "inKindProducts",
        "otherDeductions",
        "periodStart",
        "periodEnd",
        "advances",
        "extraordinaryPayments",
        "earnExtraSupplements",
        "earnExtraOtherNonSalaryCompensations",
        "earnExtraInKindSalary",
      ],
      fields => {
        setFieldsDebounce({
          periodStart: fields.periodStart.value,
          periodEnd: fields.periodEnd.value,
          days: fields.days.value,
          baseSalary: fields.baseSalary.value,
          payments: fields.payments.value,
          overtimeNormal: fields.overtimeNormal.value,
          overtimeForceMajeure: fields.overtimeForceMajeure.value,
          extraordinaryPayments: fields.extraordinaryPayments.value,
          outlays: fields.outlays.value,
          indemnitiesTransfers: fields.indemnitiesTransfers.value,
          socialSecurityBenefits: fields.socialSecurityBenefits.value,
          advances: fields.advances.value,
          inKindProducts: fields.inKindProducts.value,
          otherDeductions: fields.otherDeductions.value,
        });
      },
    );
  }, []);

  const composeEarnExtra = () => {
    const get = (type: string) => {
      const value = getFieldState(type as keyof PayrollState).value as EarnExtraPayroll[];
      return (
        value?.map(earnExtra => {
          const { id, ...rest } = earnExtra;
          return rest;
        }) ?? []
      );
    };
    return get("earnExtraSupplements")
      .concat(get("earnExtraOtherNonSalaryCompensations"))
      .concat(get("earnExtraInKindSalary"));
  };

  const { data: query } = useUrqlQuery(
    {
      query: CalculatePayrollDocument,
      variables: {
        input: {
          id: payroll?.id,
          employeeId: parseInt(employee?.id as string),
          periodStart: encodeDate(fields?.periodStart),
          periodEnd: encodeDate(fields?.periodEnd),
          days: parseInt(fields?.days),
          baseSalary: parseFloat(fields?.baseSalary) || 0,
          overtimeNormal: parseFloat(fields?.overtimeNormal) || 0,
          overtimeForceMajeure: parseFloat(fields?.overtimeForceMajeure) || 0,
          extraordinaryPayments: parseFloat(fields?.extraordinaryPayments) || 0,
          outlays: parseFloat(fields?.outlays) || 0,
          socialSecurityBenefits: parseFloat(fields?.socialSecurityBenefits) || 0,
          indemnitiesTransfers: parseFloat(fields?.indemnitiesTransfers) || 0,
          advances: parseFloat(fields?.advances) || 0,
          inKindProducts: parseFloat(fields?.inKindProducts) || 0,
          otherDeductions: parseFloat(fields?.otherDeductions) || 0,
          payments: fields?.payments,
          earnExtra: composeEarnExtra(),
        },
      },
      pause: employee == undefined,
    },
    [fields],
  );
  const data = extractQueryData(query, "value.value.calculatePayroll") as PayrollCalculate;

  const [uid, setUID] = useState<string | null>(null);
  const calculateError = extractQueryData(query, "value.value") as CombinedError;
  useEffect(() => {
    hideToast(uid as string);
    if (calculateError?.message) {
      showToast({
        variant: "error",
        title: calculateError?.message,
        autoClose: true,
      });
      setUID(`error - ${calculateError?.message} - `);
    }
  }, [calculateError?.message]);

  const [, createPayroll] = useUrqlMutation(CreatePayrollDocument);
  const [, updatePayroll] = useUrqlMutation(UpdatePayrollDocument);

  const [submit, setSubmit] = useState(false);

  const onClick = (status: string) => {
    setSubmit(true);
    setMainForm(() => {
      return () => onSave(status);
    });
  };

  useEffect(() => {
    if (submit) {
      onSubmit();
      setSubmit(false);
    }
  }, [submit]);

  const onSave = (status: string) => {
    submitForm(values => {
      const input = {
        status,
        employeeId: parseInt(employee?.id as string),
        periodStart: encodeDate(values.periodStart as string),
        periodEnd: encodeDate(values.periodEnd as string),
        days: parseInt(values.days as string),
        baseSalary: parseFloat(values.baseSalary as string) || 0,
        overtimeNormal: parseFloat(values.overtimeNormal as string) || 0,
        overtimeForceMajeure: parseFloat(values.overtimeForceMajeure as string) || 0,
        extraordinaryPayments: parseFloat(values.extraordinaryPayments as string) || 0,
        outlays: parseFloat(values.outlays as string) || 0,
        socialSecurityBenefits: parseFloat(values.socialSecurityBenefits as string) || 0,
        indemnitiesTransfers: parseFloat(values.indemnitiesTransfers as string) || 0,
        advances: parseFloat(values.advances as string) || 0,
        inKindProducts: parseFloat(values.inKindProducts as string) || 0,
        otherDeductions: parseFloat(values.otherDeductions as string) || 0,
        totalEarned: data?.totalEarned,
        totalDeductions: data?.totalDeductions,
        netPay: data?.netPay,
        commonContingenciesRate: data?.commonContingenciesRate,
        commonContingenciesAmount: data?.commonContingenciesAmount,
        unemploymentRate: data?.unemploymentRate,
        unemploymentAmount: data?.unemploymentAmount,
        professionalTrainingRate: data?.professionalTrainingRate,
        professionalTrainingAmount: data?.professionalTrainingAmount,
        overtimeNormalRate: data?.overtimeNormalRate,
        overtimeNormalAmount: data?.overtimeNormalAmount,
        overtimeForceMajeureRate: data?.overtimeForceMajeureRate,
        overtimeForceMajeureAmount: data?.overtimeForceMajeureAmount,
        irpfRate: data?.irpfRate,
        irpfAmount: data?.irpfAmount,
        monthlyRemuneration: data?.monthlyRemuneration,
        prorrataExtraordinaryPayments: data?.prorrataExtraordinaryPayments,
        atAndEpRate: data?.atAndEpRate,
        atAndEpAmount: data?.atAndEpAmount,
        unemploymentCompanyRate: data?.unemploymentCompanyRate,
        unemploymentCompanyAmount: data?.unemploymentCompanyAmount,
        professionalTrainingCompanyRate: data?.professionalTrainingCompanyRate,
        professionalTrainingCompanyAmount: data?.professionalTrainingCompanyAmount,
        fogasaRate: data?.fogasaRate,
        fogasaAmount: data?.fogasaAmount,
        professionalContingenciesBase: data?.professionalContingenciesBase,
        overtimeNormalCompanyRate: data?.overtimeNormalCompanyRate,
        overtimeNormalCompanyAmount: data?.overtimeNormalCompanyAmount,
        overtimeForceMajeureCompanyRate: data?.overtimeForceMajeureCompanyRate,
        overtimeForceMajeureCompanyAmount: data?.overtimeForceMajeureCompanyAmount,
        irpfBase: data?.irpfBase,
        commonContingenciesCompanyRate: data?.commonContingenciesCompanyRate,
        commonContingenciesCompanyAmount: data?.commonContingenciesCompanyAmount,
        totalDeductionsEmployee: data?.totalDeductionsEmployee,
        totalDeductionsCompany: data?.totalDeductionsCompany,
        totalEmployeeContribution: data?.totalEmployeeContribution,
        totalCompanyAmount: data?.totalCompanyAmount,
        earnExtra: composeEarnExtra(),
      };
      if (payroll === undefined) {
        createPayroll({
          input,
        })
          .mapOk(data => {
            match(data.createPayroll)
              .with({ __typename: "OperationInfo" }, () => {
                for (const error of (data.createPayroll as OperationInfo).messages) {
                  if (error.field != null) {
                    setFieldError(error?.field as keyof PayrollState, error?.message ?? "");
                  } else {
                    showToast({
                      variant: "error",
                      title: error.message,
                      autoClose: true,
                    });
                  }
                }
              })
              .otherwise(() => {
                Router.push("HrPayrollsList");
              });
          })
          .tapError(tapError);
      } else {
        updatePayroll({
          input: {
            id: payroll.id,
            ...input,
          },
        })
          .mapOk(data => {
            match(data.updatePayroll)
              .with({ __typename: "OperationInfo" }, () => {
                for (const error of (data.updatePayroll as OperationInfo).messages) {
                  if (error.field != null) {
                    setFieldError(error?.field as keyof PayrollState, error?.message ?? "");
                  } else {
                    showToast({
                      variant: "error",
                      title: error.message,
                      autoClose: true,
                    });
                  }
                }
              })
              .otherwise(() => {
                Router.push("HrPayrollsList");
              });
          })
          .tapError(tapError);
      }
    });
  };

  const getNewEarnExtra = (type: string) => ({
    id: crypto.randomUUID(),
    label: "",
    amount: "0",
    type,
    impactEarning: false,
    impactIrpf: false,
  });

  const handleNewEarnExtra = (type: string) => {
    const newEarnExtra = getNewEarnExtra(type);
    let fieldName = "earnExtraSupplements" as keyof PayrollState;
    if (type === "other_non_salary_compensations") {
      fieldName = "earnExtraOtherNonSalaryCompensations";
    } else if (type === "in_kind_salary") {
      fieldName = "earnExtraInKindSalary";
    }
    let { value } = getFieldState(fieldName);
    if (value === undefined) {
      value = [];
    }
    setFieldValue(fieldName, [...(value as EarnExtraPayroll[]), newEarnExtra as EarnExtraPayroll]);
  };

  return (
    <View>
      <Tabs employee={employee} />
      <Space height={12} />

      <View style={styles.contain}>
        <Box direction="row" alignItems="center" justifyContent="spaceBetween">
          <Text style={styles.title}>{t("hr.payroll")}</Text>

          <Box direction="row" alignItems="start" justifyContent="spaceAround" style={styles.dates}>
            <View style={styles.column}>
              <Field name="periodStart">
                {({ value, onChange, error }) => (
                  <DatePicker
                    format={locale.dateFormat}
                    firstWeekDay={locale.firstWeekday}
                    value={value}
                    onChange={onChange}
                    label={t("hr.periodStart") + "*"}
                    error={error}
                    disabled={disabled}
                  />
                )}
              </Field>
            </View>

            <View style={styles.column}>
              <Field name="periodEnd">
                {({ value, onChange, error }) => (
                  <DatePicker
                    format={locale.dateFormat}
                    firstWeekDay={locale.firstWeekday}
                    value={value}
                    onChange={onChange}
                    label={t("hr.periodEnd") + "*"}
                    error={error}
                    disabled={disabled}
                  />
                )}
              </Field>
            </View>

            <View style={styles.days}>
              <Field name="days">
                {Input({
                  label: t("hr.days"),
                  inputMode: "numeric",
                  disabled,
                })}
              </Field>
            </View>
          </Box>
        </Box>

        <Space height={24} />

        <View style={styles.accordion}>
          <Accordion
            trigger={`I. ${t("hr.accruals").toUpperCase()}`}
            style={styles.accordionHeader}
            contentContainerStyle={styles.accordionContent}
          >
            <Text style={styles.row}>1. {t("hr.salaryPayments")}</Text>

            <RowDecimalInput
              label={t("hr.baseSalary")}
              Field={Field}
              name="baseSalary"
              disabled={disabled}
            />

            <RowDecimalInput
              label={t("hr.overtimeNormal")}
              Field={Field}
              name="overtimeNormal"
              disabled={disabled}
            />

            <RowDecimalInput
              label={t("hr.overtimeForceMajeure")}
              Field={Field}
              name="overtimeForceMajeure"
              disabled={disabled}
            />

            <RowDecimalInput
              label={t("hr.extraordinaryPayments")}
              Field={Field}
              name="extraordinaryPayments"
              disabled={disabled}
            />

            <Text style={styles.row}>{t("hr.inKindSalary")}</Text>

            <Field name="earnExtraInKindSalary">
              {({ value, error }) => (
                <Box>
                  {value
                    ?.filter(row => row.type === "in_kind_salary")
                    .map(earnExtra => (
                      <EarnExtra
                        key={earnExtra.id}
                        earnExtra={earnExtra}
                        disabled={disabled}
                        onDelete={() => {
                          const { value } = getFieldState("earnExtraInKindSalary");
                          setFieldValue(
                            "earnExtraInKindSalary",
                            value?.filter(c => c.id !== earnExtra.id),
                          );
                        }}
                        onChange={earnExtra => {
                          const { value } = getFieldState("earnExtraInKindSalary");
                          setFieldValue(
                            "earnExtraInKindSalary",
                            value?.map(c =>
                              c.id === earnExtra.id ? { ...c, ...earnExtra } : c,
                            ) as EarnExtraPayroll[],
                          );
                        }}
                      />
                    ))}

                  <Text style={styles.red}>{error}</Text>
                </Box>
              )}
            </Field>

            {!disabled && (
              <Pressable
                style={styles.newItem}
                onPress={() => handleNewEarnExtra("in_kind_salary")}
              >
                <Icon name="add-circle-regular" size={20} color={fontColorVariants.neutral800} />
                <Text>{t("invoices.addNewItem")}</Text>
              </Pressable>
            )}

            <Text style={styles.row}>{t("hr.salarySupplements")}</Text>

            <Field name="earnExtraSupplements">
              {({ value, error }) => (
                <Box>
                  {value
                    ?.filter(row => row.type === "salary_complement")
                    .map(earnExtra => (
                      <EarnExtra
                        key={earnExtra.id}
                        earnExtra={earnExtra}
                        disabled={disabled}
                        onDelete={() => {
                          const { value } = getFieldState("earnExtraSupplements");
                          setFieldValue(
                            "earnExtraSupplements",
                            value?.filter(c => c.id !== earnExtra.id),
                          );
                        }}
                        onChange={earnExtra => {
                          const { value } = getFieldState("earnExtraSupplements");
                          setFieldValue(
                            "earnExtraSupplements",
                            value?.map(c =>
                              c.id === earnExtra.id ? { ...c, ...earnExtra } : c,
                            ) as EarnExtraPayroll[],
                          );
                        }}
                      />
                    ))}

                  <Text style={styles.red}>{error}</Text>
                </Box>
              )}
            </Field>

            {!disabled && (
              <Pressable
                style={styles.newItem}
                onPress={() => handleNewEarnExtra("salary_complement")}
              >
                <Icon name="add-circle-regular" size={20} color={fontColorVariants.neutral800} />
                <Text>{t("invoices.addNewItem")}</Text>
              </Pressable>
            )}

            <Space height={48} />
            <Text style={styles.row}>2. {t("hr.nonSalaryPayments")}</Text>

            <RowDecimalInput
              label={t("hr.outlays")}
              Field={Field}
              name="outlays"
              disabled={disabled}
            />

            <RowDecimalInput
              label={t("hr.socialSecurityBenefits")}
              Field={Field}
              name="socialSecurityBenefits"
              disabled={disabled}
            />

            <RowDecimalInput
              label={t("hr.indemnitiesTransfers")}
              Field={Field}
              name="indemnitiesTransfers"
              disabled={disabled}
            />

            <Text style={styles.row}>{t("hr.otherNonSalaryCompensations")}</Text>

            <Field name="earnExtraOtherNonSalaryCompensations">
              {({ value, error }) => (
                <Box>
                  {value
                    ?.filter(row => row.type === "other_non_salary_compensations")
                    .map(earnExtra => (
                      <EarnExtra
                        key={earnExtra.id}
                        earnExtra={earnExtra}
                        disabled={disabled}
                        onDelete={() => {
                          const { value } = getFieldState("earnExtraOtherNonSalaryCompensations");
                          setFieldValue(
                            "earnExtraOtherNonSalaryCompensations",
                            value?.filter(c => c.id !== earnExtra.id),
                          );
                        }}
                        onChange={earnExtra => {
                          const { value } = getFieldState("earnExtraOtherNonSalaryCompensations");
                          setFieldValue(
                            "earnExtraOtherNonSalaryCompensations",
                            value?.map(c =>
                              c.id === earnExtra.id ? { ...c, ...earnExtra } : c,
                            ) as EarnExtraPayroll[],
                          );
                        }}
                      />
                    ))}

                  <Text style={styles.red}>{error}</Text>
                </Box>
              )}
            </Field>

            {!disabled && (
              <Pressable
                style={styles.newItem}
                onPress={() => handleNewEarnExtra("other_non_salary_compensations")}
              >
                <Icon name="add-circle-regular" size={20} color={fontColorVariants.neutral800} />
                <Text>{t("invoices.addNewItem")}</Text>
              </Pressable>
            )}

            <Total label={`A. ${t("hr.totalAccruals")}`} amount={data?.totalEarned as string} />
          </Accordion>
        </View>

        <Space height={24} />

        <View style={styles.accordion}>
          <Accordion
            trigger={`II. ${t("hr.deductions").toUpperCase()}`}
            style={styles.accordionHeader}
            contentContainerStyle={styles.accordionContent}
          >
            <Text style={styles.row}>1. {t("hr.employeeContributions")}</Text>
            <Space height={32} />
            <RowMultiHeader label2={t("hr.type")} />

            <RowMultiType
              label={t("hr.commonContingencies")}
              rate={data?.commonContingenciesRate as string}
              company={data?.commonContingenciesAmount as string}
            />

            <RowMultiType
              label={t("hr.unployment")}
              rate={data?.unemploymentRate as string}
              company={data?.unemploymentAmount as string}
            />

            <RowMultiType
              label={t("hr.proffesionalTraining")}
              rate={data?.professionalTrainingRate as string}
              company={data?.professionalTrainingAmount as string}
            />

            <RowMultiType
              label={t("hr.overtimeNormal")}
              rate={data?.overtimeNormalRate as string}
              company={data?.overtimeNormalAmount as string}
            />

            <RowMultiType
              label={t("hr.overtimeForceMajeure")}
              rate={data?.overtimeForceMajeureRate as string}
              company={data?.overtimeForceMajeureAmount as string}
            />

            <Total
              label={t("hr.totalContributions")}
              amount={data?.totalDeductionsEmployee as string}
            />

            <Space height={32} />

            <RowMultiType
              label={`2. ${t("hr.irpf")}`}
              rate={data?.irpfRate as string}
              company={data?.irpfAmount as string}
            />

            <RowDecimalInput
              label={`3. ${t("hr.advances")}`}
              Field={Field}
              name="advances"
              disabled={disabled}
            />

            <RowDecimalInput
              label={`4. ${t("hr.inKindProducts")}`}
              Field={Field}
              name="inKindProducts"
              disabled={disabled}
            />

            <RowDecimalInput
              label={`5. ${t("hr.otherDeductions")}`}
              Field={Field}
              name="otherDeductions"
              disabled={disabled}
            />

            <Total
              label={`B. ${t("hr.totalDeductions")}`}
              amount={data?.totalDeductions as string}
            />

            <Total
              label={`${t("hr.totalCashReceived").toUpperCase()} (A-B)`}
              amount={data?.netPay as string}
            />
          </Accordion>
        </View>

        <Space height={24} />

        <View style={styles.accordion}>
          <Accordion
            trigger={`III. ${t("hr.determinationContribution").toUpperCase()}`}
            style={styles.accordionHeader}
            contentContainerStyle={styles.accordionContent}
          >
            <Text style={styles.row}>1. {t("hr.contributionCommonContingencies")}</Text>

            <RowMultiType
              label={t("hr.monthlyCompenstation")}
              company={data?.monthlyRemuneration as string}
            />

            <RowMultiType
              label={t("hr.prorationBonusPayments")}
              company={data?.prorrataExtraordinaryPayments as string}
            />

            <Total label={t("hr.total")} amount={data?.professionalContingenciesBase as string} />
            <Text style={styles.row}>2. {t("hr.occupationalContingencies")}</Text>
            <RowMultiHeader label1={t("hr.base")} label2="Tipo" label3="Aportación Empresa" />

            <RowMultiType
              label={""}
              rate={data?.commonContingenciesCompanyRate as string}
              company={data?.commonContingenciesCompanyAmount as string}
            />

            <RowMultiType
              label={t("hr.atep")}
              rate={data?.atAndEpRate as string}
              company={data?.atAndEpAmount as string}
            />

            <RowMultiType
              label={t("hr.unployment")}
              employee={data?.professionalContingenciesBase as string}
              rate={data?.unemploymentCompanyRate as string}
              company={data?.unemploymentCompanyAmount as string}
            />

            <RowMultiType
              label={t("hr.proffesionalTraining")}
              rate={data?.professionalTrainingCompanyRate as string}
              company={data?.professionalTrainingCompanyAmount as string}
            />

            <RowMultiType
              label={t("hr.fogasa")}
              rate={data?.fogasaRate as string}
              company={data?.fogasaAmount as string}
            />

            <RowMultiType
              label={`3. ${t("hr.overtimeQuote")}`}
              employee={data?.overtimeNormal as string}
              rate={data?.overtimeNormalCompanyRate as string}
              company={data?.overtimeNormalCompanyAmount as string}
              style={styles.pl0}
            />

            <RowMultiType
              label={`4. ${t("hr.overtimeQuoteForceMajeure")}`}
              employee={data?.overtimeForceMajeure as string}
              rate={data?.overtimeForceMajeureCompanyRate as string}
              company={data?.overtimeForceMajeureCompanyAmount as string}
              style={styles.pl0}
            />

            <RowMultiType
              label={`5. ${t("hr.baseSubjectPersonalIncomeTax")}`}
              employee={data?.irpfBase as string}
              style={styles.pl0}
            />

            <Total label={t("hr.total")} amount={data?.totalDeductionsCompany as string} />
          </Accordion>
        </View>
      </View>

      <Box direction="row" justifyContent="end">
        <Button
          mode="tertiary"
          size="large"
          disabled={false}
          onPress={() => Router.push("HrPayrollsList")}
        >
          {t("common.cancel")}
        </Button>

        {!disabled && (
          <ButtonGroup transparent={false}>
            <Button
              style="group"
              size="large"
              disabled={disabled}
              icon={<SaveIcon />}
              reverse={true}
              onPress={() => onClick("draft")}
            >
              {t("hr.saveDraft")}
            </Button>

            <Button
              style="group"
              size="large"
              disabled={disabled}
              icon={<SaveIcon />}
              reverse={true}
              onPress={() => onClick("issued")}
            >
              {t("common.save")}
            </Button>
          </ButtonGroup>
        )}
      </Box>
    </View>
  );
};
