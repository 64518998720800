import { NestedFormProvider } from "@components/NestedForm";
import { SalesInvoiceRelayQuery } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { FullViewportLayer } from "@swan-io/lake/src/components/FullViewportLayer";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Router } from "../../../../utils/routes";
import { COLORS } from "../../../../values/colors";
import useGetSalesInvoiceData from "../../hooks/useGetSalesInvoiceData";
import { LeftPanel } from "../LeftPanel";
import { ProformaEditor } from "./ProformaEditor";

const styles = StyleSheet.create({
  gradient1: {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translate(-60%, -80%)",
    opacity: 0.9,
    width: "50%",
    height: "70%",
    backgroundImage:
      "radial-gradient(33.64% 25.02% at 50% 50%, rgba(89, 226, 255, 0.22) 0%, rgba(108, 168, 243, 0.00) 100%)",
  },
  gradient2: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(0, -30%)",
    width: "50%",
    height: "70%",
    backgroundImage:
      "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.15) 0%, rgba(245, 228, 255, 0.00) 100%)",
  },
  background: {
    position: "absolute",
    bottom: 0,
    backgroundColor: COLORS.WHITE,
    width: "100dvw",
    height: "50dvh",
  },
  container: {
    backgroundImage: COLORS.BACKGROUND,
  },
  grid: {
    display: "grid",
    placeContent: "center",
    width: "100dvw",
    minHeight: "100dvh",
    paddingVertical: 16,
    transitionProperty: "transform",
    transitionDuration: "500ms",
    transitionTimingFunction: "ease-in-out",
  },
  invoiceContainer: {
    transition: "all 0.5s ease",
    width: "100dvw",
    height: "100%",
  },
});

type Props = {
  onRefreshRequest?: () => void;
};

export type FormValues = {
  speechToTextPrompt: string;
};

export const ProformaCreate = ({ onRefreshRequest }: Props) => {
  const route = Router.useRoute([
    "InvoicesSalesProformaCreate",
    "InvoicesSalesProformaDetail",
    "InvoicesSalesQuoteCreate",
    "InvoicesSalesQuoteDetail",
    "InvoicesSalesDeliveryCreate",
    "InvoicesSalesDeliveryDetail",
  ]);
  const [invoice, setInvoice] = useState<SalesInvoiceRelayQuery>();

  const invoiceData = useGetSalesInvoiceData(route?.params);
  const isQuote = Boolean(route?.name.toLocaleLowerCase().includes("quote"));
  const isProforma = Boolean(route?.name.toLocaleLowerCase().includes("proforma"));
  const isDelivery = Boolean(route?.name.toLocaleLowerCase().includes("delivery"));

  const [currentInvoice, setCurrentInvoice] = useState(invoice);

  useEffect(() => {
    if (invoiceData !== undefined) {
      setInvoice(invoiceData);
    }
  }, [invoiceData]);

  return (
    <FullViewportLayer visible={true}>
      <ScrollView style={styles.container}>
        <View style={styles.gradient1} />
        <View style={styles.gradient2} />
        <View style={styles.background} />

        <ResponsiveContainer breakpoint={1280}>
          {({ large }) => (
            <View style={styles.grid}>
              <Box direction="row" justifyContent="center" style={styles.invoiceContainer}>
                {(!invoice || invoice.file != null) && large && (
                  <LeftPanel setCurrentInvoice={setCurrentInvoice} />
                )}

                <NestedFormProvider>
                  <ProformaEditor
                    invoice={currentInvoice ?? invoice}
                    isQuote={isQuote}
                    isProforma={isProforma}
                    isDelivery={isDelivery}
                    onRefreshRequest={onRefreshRequest}
                  />
                </NestedFormProvider>
              </Box>
            </View>
          )}
        </ResponsiveContainer>
      </ScrollView>
    </FullViewportLayer>
  );
};
