import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import { SalesInvoiceRelayQuery, SpeechToTextDocument, TextToInvoiceDocument } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { FaMicrophone } from "react-icons/fa6";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { common } from "../../../styles/common";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { CheckBoolRuleLock, checkRule, useSubscription } from "../../../utils/subscription";
import { errorHandling, extractQueryData, validateRequired } from "../../../utils/validations";
import { useLoading } from "../../context/LoadingContext";
import { useUser } from "../../context/UserContext";

const styles = StyleSheet.create({
  card: {
    minWidth: 380,
    backgroundColor: backgroundColorVariants.white,
    padding: 32,
    borderWidth: 1,
    borderColor: backgroundColorVariants.gray200,
    borderStyle: "dashed",
    borderRadius: 5,
  },
  noBorder: {
    borderWidth: 0,
  },
  shadow: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
  },
  subtitle: {
    fontSize: 16,
    color: backgroundColorVariants.gray400,
  },
  audio: {
    width: "100%",
    height: 42,
  },
});

type AudioVoiceRecorderProps = {
  setCurrentInvoice: (invoice: SalesInvoiceRelayQuery) => void;
};

export const AudioVoiceRecorder = ({ setCurrentInvoice }: AudioVoiceRecorderProps) => {
  const { subscription } = useUser();
  const [audioSrc, setAudioSrc] = useState<string>();
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const { setLoading } = useLoading();
  const [, textToInvoice] = useUrqlMutation(TextToInvoiceDocument);
  const [, speechToText] = useUrqlMutation(SpeechToTextDocument);

  const [hover, setHover] = useState(false);

  type FormValues = {
    audioText: string;
  };

  const { Field, submitForm, setFieldValue } = useForm<FormValues>({
    audioText: { initialValue: "", validate: validateRequired },
  });

  const handleFormSubmit = () => {
    setLoading(true);
    submitForm(values => {
      textToInvoice({
        input: {
          textPrompt: values.audioText as string,
        },
      }).mapOk(data => {
        setLoading(false);
        match(data.textToInvoice)
          .with({ __typename: "SalesInvoiceRelayQuery" }, data => {
            setCurrentInvoice(data as SalesInvoiceRelayQuery);
          })
          .with({ __typename: "OperationInfo" }, errorHandling);
      });
    });
  };

  const simulateTypewriterEffect = (
    text: string,
    setFieldValueWritter: typeof setFieldValue,
    onFinish: () => void,
  ) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index <= text.length) {
        const nextChar = text.slice(0, index);
        setFieldValueWritter("audioText", nextChar);
        index++;
      } else {
        clearInterval(interval);
        onFinish();
      }
    }, 50);
  };

  const addAudioElement = (blob: Blob) => {
    setLoading(true);
    setFieldValue("audioText", "");
    setIsTypingComplete(false);
    const url = URL.createObjectURL(blob);
    setAudioSrc(url);
    const fileAux = new File([blob], `audio_${Date.now()}.webm`, { type: "audio/webm" });
    speechToText({ file: fileAux })
      .mapOk(data => {
        setLoading(false);
        match(data.speechToText)
          .with({ __typename: "SpeechToTextOutput" }, data => {
            const transcribedText = extractQueryData(data, "text") as string;
            simulateTypewriterEffect(transcribedText, setFieldValue, () =>
              setIsTypingComplete(true),
            );
          })
          .with({ __typename: "OperationInfo" }, e => {
            setAudioSrc(undefined);
            errorHandling(e);
          });
      })
      .mapError(() => {
        setAudioSrc(undefined);
        setLoading(false);
      });
  };

  const recorderControls = useAudioRecorder();

  const { showUpgrade } = useSubscription();

  return (
    <>
      <Pressable
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}
        onPress={() => {
          checkRule(subscription, "has_voice_assistant")
            ? recorderControls.startRecording()
            : showUpgrade({});
        }}
        style={[styles.card, hover && styles.shadow]}
      >
        <View style={!recorderControls.isRecording && common.hidden}>
          <AudioRecorder
            onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadFileExtension="webm"
            mediaRecorderOptions={{
              audioBitsPerSecond: 128000,
            }}
            showVisualizer={true}
            recorderControls={recorderControls}
          />
        </View>

        {!recorderControls.isRecording && (
          <Box alignItems="center">
            <FaMicrophone color={fontColorVariants.primary500} size={32} />
            <Space height={12} />

            <Box direction="row" alignItems="center">
              <Text style={styles.title}>{t("invoices.audio")}</Text>
              <Space width={8} />

              {CheckBoolRuleLock(subscription, "has_voice_assistant")}
            </Box>

            <Space height={4} />
            <Text style={styles.subtitle}>{t("invoices.audioText")}</Text>
          </Box>
        )}
      </Pressable>

      {audioSrc != null && (
        <Box style={[styles.card, styles.shadow, styles.noBorder]}>
          <audio controls={true} style={styles.audio}>
            <source src={audioSrc} type="audio/wav" />
          </audio>

          <Field name="audioText">
            {Input({
              disabled: !isTypingComplete,
              label: "",
              multiline: true,
              customOnChange: (value: string) => {
                setFieldValue("audioText", value);
              },
            })}
          </Field>

          <Button onPress={handleFormSubmit} disabled={!isTypingComplete}>
            {t("invoice.generate")}
          </Button>
        </Box>
      )}
    </>
  );
};
