import dayjs from "dayjs";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type Store = {
  from: string;
  to: string;
  setDates: (from: Date | null, to: Date | null) => void;
};

export const useDateFilter = create<Store>()(
  persist(
    set => ({
      from: dayjs().subtract(2, "month").toISOString(),
      to: dayjs().toISOString(),
      setDates: (from, to) => set({ from: from?.toISOString(), to: to?.toISOString() }),
    }),
    { name: "date-filter" },
  ),
);
