import basic from "@assets/icons/basic.png";
import master from "@assets/icons/master.png";
import premium from "@assets/icons/premium.png";
import {
  CancelSubscriptionDocument,
  CreateCheckoutSessionDocument,
  Plan,
  UpdateSubscriptionDocument,
} from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useState } from "react";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import { StyleSheet, Text } from "react-native";
import { match } from "ts-pattern";
import { useLoading } from "../features/context/LoadingContext";
import { t } from "../utils/i18n";
import { handlerErrors } from "../utils/validations";
import { COLORS } from "../values/colors";

import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../styles/constants";
import { Button } from "./forms/Button";

const styles = StyleSheet.create({
  container: {
    width: 340,
    flexDirection: "column",
    padding: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: borderColorVariants.transparent,
    backgroundColor: backgroundColorVariants.plans,
    transitionDuration: "0.3s",
    transitionProperty: "box-shadow",
  },
  shadow: {
    boxShadow:
      "0px 4.985px 14.954px -1.869px rgba(16, 24, 40, 0.10), 0px 4.985px 14.954px -1.869px rgba(16, 24, 40, 0.05)",
  },
  header: {
    gap: 5,
  },
  icon: {
    display: "inline-flex",
    padding: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    color: COLORS.NEUTRAL900,
  },
  subtitle: {
    fontSize: 12,
    color: COLORS.NEUTRAL500,
    textAlign: "center",
    marginVertical: 15,
    paddingVertical: 8,
    borderTopWidth: 1,
    borderTopColor: COLORS.NEUTRAL200,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.NEUTRAL200,
  },
  price: {
    fontSize: 15,
    fontWeight: "700",
  },
  period: {
    fontSize: 9,
    fontWeight: "500",
    color: COLORS.NEUTRAL400,
  },
  advantages: {
    gap: 16,
  },
  advantage: {
    fontSize: 12,
    gap: 8,
    color: COLORS.NEUTRAL700,
  },
  fontSelected: {
    color: fontColorVariants.white,
  },
  selected: {
    width: 340,
    flexDirection: "column",
    padding: 12,
    borderRadius: 8,
    transitionDuration: "0.3s",
    backgroundImage:
      "linear-gradient(to bottom right, #1296EB 0%, #0065A6 50%), linear-gradient(to bottom left, #1296EB 0%, #0065A6 50%), linear-gradient(to top left, #1296EB 0%, #0065A6 50%), linear-gradient(to top right, #1296EB 0%, #0065A6 50%)",
    transitionProperty: "box-shadow",
    color: fontColorVariants.white,
  },
});

type Props = {
  plan: Plan;
  currentPlan?: boolean;
  cancelUrl?: string;
  updating?: boolean;
  isPendingCancel?: boolean;
  reload?: () => void;
};

const GREEN = "#22C55E";

export const PlanCard = ({
  plan,
  currentPlan = false,
  cancelUrl = "plans",
  updating = false,
  isPendingCancel = false,
  reload = () => null,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { setLoading } = useLoading();

  const [, updateSubscription] = useUrqlMutation(UpdateSubscriptionDocument);
  const [, cancelSubscription] = useUrqlMutation(CancelSubscriptionDocument);
  const [, createCheckoutSession] = useUrqlMutation(CreateCheckoutSessionDocument);

  const handleClick = () => {
    if (currentPlan === true) {
      setLoading(true);
      cancelSubscription({})
        .mapOk(data => {
          match(data.cancelSubscription)
            .with({ __typename: "OperationInfo" }, handlerErrors)
            .otherwise(() => {
              reload();
              showToast({
                variant: "success",
                title: t("plan.updated"),
                autoClose: true,
              });
            });
          setLoading(false);
        })
        .mapError(() => {
          showToast({ variant: "error", title: t("plan.updatedError"), autoClose: true });
          setLoading(false);
        });
    } else if (updating === true) {
      setLoading(true);
      updateSubscription({ newPlanId: Number(plan.id) })
        .mapOk(data => {
          match(data.updateSubscription)
            .with({ __typename: "OperationInfo" }, handlerErrors)
            .otherwise(() => {
              const errors: string[] = [];
              Object.entries(data.updateSubscription).forEach(([key, value]) => {
                if (key !== "__typename" && value !== "" && isNotNullish(value)) {
                  errors.push(String(value));
                }
              });

              if (errors.length !== 0) {
                showToast({
                  variant: "error",
                  title: t("plan.updateUnavailable"),
                  autoClose: true,
                  description: errors.join("\n"),
                });
              } else {
                reload();
                showToast({
                  variant: "success",
                  title: t("plan.updated"),
                  autoClose: true,
                });
              }
            });
          setLoading(false);
        })
        .mapError(() => {
          showToast({ variant: "error", title: t("plan.updatedError"), autoClose: true });
          setLoading(false);
        });
    } else {
      createCheckoutSession({
        input: {
          planId: Number(plan.id),
          successUrl: "home",
          cancelUrl,
        },
      })
        .mapOk(data => {
          match(data.createCheckoutSession)
            .with({ __typename: "OperationInfo" }, handlerErrors)
            .with(
              { __typename: "CreateCheckoutSessionOutput" },
              row => (window.location.href = row.redirectUrl),
            )
            .otherwise(() => null);
        })
        .mapError(() => {
          showToast({ variant: "error", title: t("plan.updatedError"), autoClose: true });
        });
    }
  };

  return (
    <Pressable
      onHoverIn={() => !currentPlan && setIsHovered(true)}
      onHoverOut={() => !currentPlan && setIsHovered(false)}
      onPress={() => void handleClick()}
    >
      <Box
        style={[
          !currentPlan && styles.container,
          isHovered && styles.shadow,
          currentPlan && styles.selected,
          currentPlan && styles.shadow,
        ]}
      >
        <Box alignItems="center" style={styles.header}>
          <Box style={styles.icon}>
            {plan.id === "1" && <AutoWidthImage sourceUri={basic} height={60} />}
            {plan.id === "2" && <AutoWidthImage sourceUri={master} height={60} />}
            {plan.id === "3" && <AutoWidthImage sourceUri={premium} height={60} />}
            {plan.id === "6" && <AutoWidthImage sourceUri={premium} height={60} />}
          </Box>

          <LakeHeading level={2} style={[styles.title, currentPlan && styles.fontSelected]}>
            {plan.title}
          </LakeHeading>

          <Box direction="row" alignItems="center">
            {plan.code === "trial" ? (
              <Text style={[styles.price, currentPlan && styles.fontSelected]}>
                {t("plan.trial")}
              </Text>
            ) : (
              <>
                <Text style={[styles.price, currentPlan && styles.fontSelected]}>
                  {plan.monthlyPrice as string}€ /
                </Text>

                <Text style={[styles.period, currentPlan && styles.fontSelected]}>
                  {" "}
                  {"por mes"}
                </Text>
              </>
            )}
          </Box>
        </Box>

        <Text style={[styles.subtitle, currentPlan && styles.fontSelected]}>
          Aenean at lectus posuere enim id nec. Molestie neque.
        </Text>

        <Box style={styles.advantages}>
          {plan.advantages.map(advantage => (
            <Box key={advantage.id} direction="row" style={styles.advantage}>
              {advantage.isActive ? (
                <FaCircleCheck size={18} style={{ color: GREEN }} />
              ) : (
                <FaCircleXmark size={18} />
              )}

              <Text style={currentPlan && styles.fontSelected}>{advantage.description}</Text>
            </Box>
          ))}
        </Box>

        <Space height={20} />

        <Button mode="secondary" style="solid" onPress={() => void handleClick()}>
          {isPendingCancel && currentPlan
            ? t("plan.restore")
            : currentPlan
              ? t("plan.cancel")
              : t("plan.elegir")}
        </Button>
      </Box>
    </Pressable>
  );
};
