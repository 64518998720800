import { Button } from "@components/forms/Button";
import Select from "@components/forms/Select";
import {
  AssignInvoiceToProjectDocument,
  OperationInfo,
  ProjectsAssignmetDocument,
} from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { StyleSheet } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { CombinedError } from "urql";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { extractQueryData, validateRequired } from "../../../utils/validations";
import { ProjectInvoiceAssignmentState } from "../../projects/types";

const styles = StyleSheet.create({
  grid: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "75%",
    alignSelf: "flex-end",
    gap: 48,
  },
});

type ProjectAssignmentModalProps = {
  showAssignment: boolean;
  invoiceId: string;
  type: "PURCHASE" | "SALES";
  onPressClose: () => void;
};
type Project = {
  id: string;
  name: string;
};

export const ProjectAssignmentModal = ({
  showAssignment,
  invoiceId,
  type,
  onPressClose,
}: ProjectAssignmentModalProps) => {
  const { Field, submitForm, setFieldError } = useForm<ProjectInvoiceAssignmentState>({
    projectId: { initialValue: "", validate: validateRequired },
    invoiceId: { initialValue: invoiceId, validate: validateRequired },
    type: { initialValue: type, validate: validateRequired },
  });

  const { data } = useUrqlQuery({ query: ProjectsAssignmetDocument });
  const [, create] = useUrqlMutation(AssignInvoiceToProjectDocument);

  const projects = extractQueryData(data, "value.value.projects") as Project[];

  const tapError = (error: Error | CombinedError) => {
    showToast({ variant: "error", title: error.message, autoClose: true });
  };

  const parsedProjects = projects?.map(project => ({
    value: project.id,
    name: project.name,
  }));

  const onSave = () => {
    submitForm(values => {
      create({
        input: {
          projectId: Number(values.projectId),
          invoiceId: Number(invoiceId),
          type,
        },
      })
        .mapOk(data => {
          match(data.assignInvoiceToProject)
            .with({ __typename: "OperationInfo" }, () => {
              for (const error of (data.assignInvoiceToProject as OperationInfo).messages) {
                if (error.field != null) {
                  setFieldError(
                    error?.field as keyof ProjectInvoiceAssignmentState,
                    error?.message ?? "",
                  );
                } else {
                  showToast({
                    variant: "error",
                    title: error.message,
                    autoClose: true,
                  });
                }
              }
            })
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("project.assinged"),
                autoClose: true,
              });
              onPressClose();
            });
        })
        .tapError(tapError);
    });
  };

  return (
    <LakeModal visible={showAssignment} onPressClose={onPressClose} maxWidth={660}>
      <>
        <Box style={styles.grid}>
          <Field name="projectId">
            {Select({
              label: "Proyecto",
              items: parsedProjects,
              hasEmptyRow: true,
            })}
          </Field>
        </Box>

        <Space height={24} />

        <Box style={styles.gap} alignItems="end">
          <Button onPress={onPressClose} mode="secondary">
            {t("common.cancel")}
          </Button>

          <Button onPress={onSave} icon={<SaveIcon />}>
            {t("common.save")}
          </Button>
        </Box>
      </>
    </LakeModal>
  );
};
