import { Employee } from "@graphql/crm";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { NestedFormProvider } from "../../../components/NestedForm";
import { t } from "../../../utils/i18n";
import { EmployeeForm } from "./EmployeeForm";

type Props = {
  visible?: boolean;
  data?: Employee;
  onPressClose?: () => void;
};

export const EmployeeCreate = ({ visible = false, data, onPressClose }: Props) => {
  return (
    <LakeModal
      visible={visible}
      title={data?.id !== undefined ? t("hr.editEmployee") : t("hr.newEmployee")}
      onPressClose={onPressClose}
      maxWidth={660}
    >
      <NestedFormProvider>
        <EmployeeForm onPressClose={onPressClose} showCancel={true} />
      </NestedFormProvider>
    </LakeModal>
  );
};
