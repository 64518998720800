import background from "@assets/img/clock-card.png";
import logo from "@assets/img/logo-trexx.svg";
import { Button } from "@components/forms/Button";
import { LabelInput } from "@components/forms/Input";
import { ActivateDocument, ResendDocument } from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text } from "react-native";
import { common } from "../styles/common";
import { backgroundColorVariants, fontColorVariants } from "../styles/constants";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateEmail } from "../utils/validations";

const COLOR_TITLE = "#042064";

const styles = StyleSheet.create({
  page: {
    backgroundColor: backgroundColorVariants.primary50,
    paddingVertical: 64,
    paddingHorizontal: 112,
    backgroundImage: `url(${background})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
  },
  h3: {
    fontSize: 32,
    fontWeight: "500",
    textAlign: "center",
    color: COLOR_TITLE,
  },
  container: {
    paddingTop: 48,
    alignItems: "center",
  },
  contain: {
    maxWidth: 600,
    alignItems: "center",
  },
  button: {
    width: 440,
  },
  link: {
    color: fontColorVariants.primary500,
    marginTop: 12,
  },
  resend: {
    gap: 16,
  },
});

type Props = {
  uid?: string;
  token?: string;
};

export const ActivatePage = ({ uid, token }: Props) => {
  const [active, setActive] = useState(false);

  const [formResend, setFormResend] = useState(false);
  const [email, setEmail] = useState("");

  const [, activate] = useUrqlMutation(ActivateDocument);

  useEffect(() => {
    if (uid == null || token == null) {
      return;
    }

    activate({
      input: {
        uidb64: uid,
        token,
      },
    }).mapOk(({ activate }) => {
      setActive(activate);
    });
  }, [uid, token]);

  const isActivating = uid != null && token != null;

  const [, resend] = useUrqlMutation(ResendDocument);

  const handleResend = () => {
    const error = validateEmail(email);
    if (error != null) {
      return showToast({ variant: "error", title: error as string, autoClose: true });
    }

    resend({
      input: {
        email,
      },
    }).mapOk(() => {
      setFormResend(false);
    });
  };

  return (
    <ScrollView style={styles.page}>
      <AutoWidthImage sourceUri={logo} height={52} alt="Trexx" />

      <Box style={styles.container}>
        <Box direction="column" style={styles.contain}>
          {formResend ? (
            <>
              <Text style={styles.h3}>{t("activate.resendTitle")}</Text>
              <Space height={16} />

              <Box direction="row" alignItems="end" style={styles.resend}>
                <LabelInput label={t("common.email")} value={email} onChange={setEmail} />

                <Button mode="primary" onPress={handleResend}>
                  {t("common.send")}
                </Button>
              </Box>

              <Link
                to={Router.ActivateRoot()}
                onPress={() => setFormResend(false)}
                style={[styles.link, common.underline]}
              >
                {t("common.back")}
              </Link>
            </>
          ) : (
            <>
              <Text style={styles.h3}>
                {isActivating
                  ? active
                    ? t("activate.actived")
                    : t("activate.error")
                  : t("activate.title")}
              </Text>

              <Space height={40} />

              <Box style={styles.button}>
                <Button mode="primary" onPress={() => Router.push("SigninPage")}>
                  {t("customTile.ok")}
                </Button>
              </Box>

              <Link
                to={Router.ActivateRoot()}
                onPress={() => setFormResend(true)}
                style={[styles.link, common.underline]}
              >
                {t("activate.resend")}
              </Link>
            </>
          )}
        </Box>
      </Box>
    </ScrollView>
  );
};
