import { CreateCustomerDocument, CreateSupplierDocument, OperationInfo } from "@graphql/crm";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { RadioGroup } from "@swan-io/lake/src/components/RadioGroup";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useState } from "react";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { CombinedError } from "urql";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { subscriptionError, useSubscription } from "../../../utils/subscription";
import {
  handlerFieldErrors,
  validateEmail,
  validateOptional,
  validateRequired,
} from "../../../utils/validations";
import { FormContactState } from "../types";
import { Editor } from "./Editor";

type Props = {
  visibleParam?: string;
  onRefreshRequest: () => void;
};

const types = [
  { name: t("contact.customer"), value: "customer" },
  { name: t("contact.supplier"), value: "supplier" },
];

export const Create = ({ visibleParam, onRefreshRequest }: Props) => {
  const {
    Field,
    submitForm,
    setFieldValue,
    getFieldState,
    FieldsListener,
    setFieldError,
    resetForm,
  } = useForm<FormContactState>({
    type: { initialValue: "customer" },
    name: {
      initialValue: "",
      validate: validateRequired,
      sanitize: (value: string) => value.trim(),
    },
    nif: { initialValue: "" },
    country: { initialValue: "ESP", validate: validateRequired },
    postalCode: { initialValue: "" },
    phone: { initialValue: "" },
    email: {
      initialValue: "",
      validate: async value => {
        if (value) {
          return await validateEmail(value);
        }
      },
    },
    address: { initialValue: "" },
    paymentMethod: { initialValue: "" },
    iban: { initialValue: "", validate: validateOptional(validateIban) },
    sequential: { initialValue: undefined },
  });

  const [, crmCustomer] = useUrqlMutation(CreateCustomerDocument);
  const [, crmSupplier] = useUrqlMutation(CreateSupplierDocument);

  const [contactType, setContactType] = useState("customer");

  const close = () => {
    return Router.push("CrmContactsList", { new: undefined });
  };

  const otherwise = () => {
    onRefreshRequest();
    close();
    resetForm();
  };

  const tapError = (error: Error | CombinedError) => {
    showToast({ variant: "error", title: error.message, autoClose: true });
  };

  const { showUpgrade } = useSubscription();

  const onPressCreate = () => {
    submitForm(values => {
      const commonInput = {
        name: values.name ?? "",
        nif: values.nif,
        address: values.address,
        postalCode: values.postalCode,
        country: values.country ?? "ESP",
        phone: values.phone,
        email: values.email,
        sequential: values.sequential,
      };

      match(contactType)
        .with("customer", () => {
          crmCustomer({
            input: { ...commonInput },
          })
            .mapOk(data => {
              match(data.createCustomer)
                .with({ __typename: "OperationInfo" }, row =>
                  match(row.messages[0])
                    .with({ field: "numClients" }, () =>
                      showUpgrade({
                        title: t("plan.limitTitle"),
                        description: subscriptionError(row),
                      }),
                    )
                    .otherwise(() =>
                      handlerFieldErrors<FormContactState>(
                        data.createCustomer as OperationInfo,
                        setFieldError,
                      ),
                    ),
                )
                .otherwise(otherwise);
            })
            .tapError(tapError);
        })
        .with("supplier", () => {
          crmSupplier({
            input: {
              ...commonInput,
              iban: values.iban,
              paymentMethod: values.paymentMethod,
            },
          })
            .mapOk(data => {
              match(data.createSupplier)
                .with({ __typename: "OperationInfo" }, row =>
                  match(row.messages[0])
                    .with({ field: "numSuppliers" }, () => {
                      showUpgrade({
                        title: t("plan.limitTitle"),
                        description: subscriptionError(row),
                      });
                    })
                    .otherwise(() =>
                      handlerFieldErrors<FormContactState>(
                        data.createSupplier as OperationInfo,
                        setFieldError,
                      ),
                    ),
                )
                .otherwise(otherwise);
            })
            .tapError(tapError);
        });
    });
  };

  return (
    <LakeModal
      visible={visibleParam != null}
      title={t("contact.new")}
      maxWidth={740}
      onPressClose={close}
    >
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {({ large }) => (
          <>
            <LakeLabel
              label={t("contact.type")}
              render={() => (
                <Field name="type">
                  {({ value, onChange }) => (
                    <RadioGroup
                      items={types}
                      value={value}
                      direction="row"
                      onValueChange={value => {
                        setContactType(value);
                        onChange(value);
                      }}
                    />
                  )}
                </Field>
              )}
            />

            <Editor
              contactType={contactType}
              large={large}
              Field={Field}
              setFieldValue={setFieldValue}
              getFieldState={getFieldState}
              FieldsListener={FieldsListener}
              onSave={onPressCreate}
            />
          </>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
