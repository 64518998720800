import { ExportInvoicesDocument } from "@graphql/crm";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { t } from "../../../utils/i18n";

type Props = {
  dateFrom?: string;
  dateTo?: string;
};

export const useExport = () => {
  const [, exportInvoices] = useUrqlMutation(ExportInvoicesDocument);

  const handleExportInvoices = ({ dateFrom, dateTo }: Props) => {
    exportInvoices({
      input: {
        dateFrom,
        dateTo,
      },
    }).mapOk(() => {
      showToast({
        variant: "success",
        title: t("invoices.exportSuccess"),
        description: t("invoices.exportSuccessDescription"),
        autoClose: true,
      });
    });
  };

  return { handleExportInvoices };
};
