import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DateCell } from "@components/forms/DateCell";
import { DefaultCell } from "@components/forms/DefaultCell";
import { PriceCell } from "@components/forms/PriceCell";
import { TicketRelay } from "@graphql/crm";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { ReactElement, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { encodeDateISO } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";
import { ColumnHeader } from "./ColumnHeader";
import { StatusCell } from "./StatusCell";
import { TicketUpdateStatusModal } from "./TicketUpdateStatusModal";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  tickets: TicketRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<TicketRelay, ExtraInfo>) => ReactElement;
  sortBy?: string;
  setSort?: (columnId: string) => void;
};
type ExtraInfo = undefined;

export const DetailsList = ({
  tickets,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  onRefreshRequest,
  renderEmptyList,
  sortBy,
  setSort,
}: Props) => {
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false);
  const [ticket, setTicket] = useState<TicketRelay>();

  const StatusTag = (item: TicketRelay) => (
    <StatusCell
      status={item.status ?? ""}
      text={item.statusDescription ?? ""}
      paymentDate={encodeDateISO(item.paymentDate ?? "")}
      align="left"
      onChangeStatus={() => {
        if (item.status === "DRAFT") {
          setTicket(item);
          setUpdateStatusModal(true);
        }
      }}
      invoiceId={String(item.id)}
      setInvoiceId={() => null}
      onAction={() => null}
    />
  );

  const columns: ColumnConfig<TicketRelay, ExtraInfo>[] = [
    {
      id: "issueDate",
      width: 120,
      title: t("invoices.issueDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DateCell date={item.issueDate ?? ""} align="left" />,
      sortable: true,
    },
    {
      id: "paymentDate",
      width: 120,
      title: t("invoices.paymentDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DateCell date={item.paymentDate ?? ""} align="left" />,
      sortable: true,
    },
    {
      id: "seriesCurrent",
      width: "grow",
      title: t("invoices.seriesNumber"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.seriesCurrent ?? ""} />,
      sortable: true,
    },
    {
      id: "issuer",
      width: "grow",
      title: t("contact.supplier"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.issuer?.name ?? ""} />,
      sortable: true,
    },
    {
      id: "status",
      width: 150,
      title: t("common.status"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="left" />,
      renderCell: ({ item }) => StatusTag(item),
      sortable: true,
    },
    {
      id: "total",
      width: 100,
      title: t("invoices.total"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.total as number} align="right" />,
      sortable: true,
    },
  ];

  const smallColumns: ColumnConfig<TicketRelay, ExtraInfo>[] = columns.filter(c =>
    ["issueDate", "issuer", "total"].includes(c.id),
  );

  return (
    <>
      <PlainListView
        withoutScroll={true}
        data={tickets}
        keyExtractor={item => item.id as string}
        headerHeight={36}
        rowHeight={63}
        headerStyle={styles.header as ViewStyle}
        headerBackgroundColor="#EDEDED"
        groupHeaderHeight={36}
        extraInfo={undefined}
        columns={columns}
        smallColumns={smallColumns}
        activeRowId={activeRowId}
        onActiveRowChange={onActiveRowChange}
        loading={loading}
        onEndReached={onEndReached}
        getRowLink={getRowLink}
        renderEmptyList={renderEmptyList}
        breakpoint={breakpoints.medium}
        sortBy={sortBy}
        setSort={setSort}
      />

      <TicketUpdateStatusModal
        visible={updateStatusModal}
        onPressClose={() => {
          onRefreshRequest();
          setUpdateStatusModal(false);
        }}
        ticket={ticket}
      />
    </>
  );
};
