import { GraphData } from "@graphql/crm";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Color,
  Legend,
  LinearScale,
  ScriptableContext,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { t } from "../../../utils/i18n";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const convert_string_to_number = (value: string | null): number | null => {
  if (value === null) {
    return null;
  }
  return parseFloat(parseFloat(value).toFixed(2));
};

type ProjectGraphProps = {
  graphData: GraphData | null | undefined;
};

export const ProjectGraph = ({ graphData }: ProjectGraphProps) => {
  const employeeData = graphData?.employee?.map(datapoint =>
    convert_string_to_number(datapoint as string),
  );
  const expensesInvoiceData = graphData?.invoiceExpense?.map(datapoint =>
    convert_string_to_number(datapoint as string),
  );
  const incomeInvoiceData = graphData?.invoiceRevenue?.map(datapoint =>
    convert_string_to_number(datapoint as string),
  );
  const pendingData = graphData?.pending?.map(datapoint =>
    convert_string_to_number(datapoint as string),
  );

  const data = {
    labels: ["Ingreso", "Gasto"],
    datasets: [
      {
        label: String(t("project.charged")),
        data: expensesInvoiceData,
        fill: "start",
        backgroundColor: (context: ScriptableContext<"bar">) => {
          const { chart, parsed } = context;
          const { ctx, chartArea, scales } = chart;

          if (chartArea == null) {
            return "blue";
          }

          const value = parsed.x;
          const max = scales.x != null ? scales.x.max : 0;
          const barWidth = (value / max) * chartArea.width;

          const gradient = ctx.createLinearGradient(0, 0, barWidth, 0);
          gradient.addColorStop(0, "#FF5A0F");
          gradient.addColorStop(0.5018, "#FF0F0F");
          gradient.addColorStop(1, "rgba(255, 15, 100, 0.6)");

          return gradient;
        },
        borderColor: "#FF1E0F",
      },
      {
        label: t("project.invoiced") ?? "",
        data: incomeInvoiceData,
        backgroundColor: (context: ScriptableContext<"bar">) => {
          const { chart, parsed } = context;
          const { ctx, chartArea, scales } = chart;

          if (chartArea == null) {
            return "blue";
          }

          const value = parsed.x;
          const max = scales.x != null ? scales.x.max : 0;
          const barWidth = (value / max) * chartArea.width;

          const gradient = ctx.createLinearGradient(0, 0, barWidth, 0);
          gradient.addColorStop(0, "rgba(65, 185, 230, 0.70)");
          gradient.addColorStop(0.2833, "rgba(5, 85, 250, 0.70)");
          gradient.addColorStop(0.7, "rgba(5, 85, 250, 0.70)");
          gradient.addColorStop(1, "rgba(65, 185, 230, 0.70)");
          return gradient;
        },
        borderColor: "#41B9E6",
      },
      {
        label: t("project.payroll") ?? "",
        data: employeeData,
        backgroundColor: (context: ScriptableContext<"bar">) => {
          const { chart, parsed } = context;
          const { ctx, chartArea, scales } = chart;

          if (chartArea == null) {
            return "blue";
          }

          const value = parsed.x;
          const max = scales.x != null ? scales.x.max : 0;
          const barWidth = (value / max) * chartArea.width;

          const gradient = ctx.createLinearGradient(0, 0, barWidth, 0);
          gradient.addColorStop(0, "#FF0F64");
          gradient.addColorStop(0.4, "#870FFF");
          gradient.addColorStop(0.6, "#870FFF");
          gradient.addColorStop(0.9, "#B40FC5");
          gradient.addColorStop(1, "#B90FBF");

          return gradient;
        },
        borderColor: "#870FFF",
      },
      {
        label: t("project.pending") ?? "",
        data: pendingData,
        backgroundColor: (context: ScriptableContext<"bar">) => {
          const { chart, parsed } = context;
          const { ctx, chartArea, scales } = chart;

          if (chartArea == null) {
            return "blue";
          }

          const value = parsed.x;
          const max = scales.x != null ? scales.x.max : 0;
          const barWidth = (value / max) * chartArea.width;

          const gradient = ctx.createLinearGradient(0, 0, barWidth, 0);
          gradient.addColorStop(0.1, "#D4D3D5");
          gradient.addColorStop(0.5, "#D4D3D5");
          gradient.addColorStop(1, "#7D7B82");

          return gradient;
        },
        borderColor: "#D4D3D5",
      },
    ],
  };

  const options = {
    indexAxis: "y" as "x" | "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as "top" | "left" | "bottom" | "right" | "chartArea" | "center",
        labels: {
          color: "black",
          boxWidth: 15,
          usePointStyle: true,
          generateLabels: (chart: ChartJS) => {
            const { datasets } = chart.data;
            return datasets.map((dataset, i) => ({
              text: (dataset.label as string) ?? "",
              fillStyle: dataset.borderColor as Color,
              datasetIndex: i,
              hidden: !chart.isDatasetVisible(i),
              rotation: 0,
            }));
          },
        },
      },
      tooltip: {
        callbacks: {
          label(context: TooltipItem<"bar">) {
            let label = context.dataset.label ?? "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR",
              }).format(context.parsed.x);
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: "end" as "start" | "end" | "center",
        align: "left" as number | "start" | "end" | "center" | "bottom" | "left" | "right" | "top",
        color: "white",
        font: {
          size: 15,
        },
        formatter: (
          val: {
            toLocaleString: (arg0: string, arg1: { style: string; currency: string }) => unknown;
          } | null,
        ) => {
          if (val !== null) {
            return val.toLocaleString("es-ES", { style: "currency", currency: "EUR" });
          }
          return val;
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
  };

  return (
    <Bar data={data} width="100px" plugins={[ChartDataLabels]} height="100px" options={options} />
  );
};
