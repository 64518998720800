import KebabMenu from "@components/KebabMenu";
import { KebabMenuItem } from "@components/KebabMenuItem";
import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { CompanyPaymentMethod, Static, UpdateCompanyPaymentMethodDocument } from "@graphql/crm";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { ReactElement, ReactNode, useState } from "react";
import { GoArchive } from "react-icons/go";
import { StyleSheet, ViewStyle } from "react-native";
import { match } from "ts-pattern";
import { StaticCell } from "../../../components/forms/StaticCell";
import { t } from "../../../utils/i18n";
import { errorHandling } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { useLoading } from "../../context/LoadingContext";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  data: CompanyPaymentMethod[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<CompanyPaymentMethod, ExtraInfo>) => ReactElement;
  paymentMethods: Static[];
};
type ExtraInfo = undefined;

export const PaymentMethodsList = ({
  data,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
  paymentMethods,
}: Props) => {
  const { setLoading } = useLoading();
  const [, updateCompanyPaymentMethod] = useUrqlMutation(UpdateCompanyPaymentMethodDocument);

  const columns: ColumnConfig<CompanyPaymentMethod, ExtraInfo>[] = [
    {
      id: "name",
      width: "grow",
      title: t("contact.name"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.name ?? ""} />,
    },
    {
      id: "type",
      width: "grow",
      title: t("invoice.type"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => (
        <StaticCell data={item.type.toLowerCase()} items={paymentMethods ?? []} />
      ),
    },
    {
      id: "default",
      width: 80,
      title: t("common.main"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => <DefaultCell data={item.default ? "✓" : ""} align="center" />,
    },
    {
      id: "options",
      width: 31,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <KebabMenu visible={visible} onChange={setVisible}>
          <KebabMenuItem
            onPress={() => handleArchive(item)}
            renderIcon={<GoArchive size={20} />}
            text={item.archived ? t("common.unarchive") : t("common.archive")}
          />
        </KebabMenu>
      ),
    },
  ];

  const smallColumns: ColumnConfig<CompanyPaymentMethod, ExtraInfo>[] = columns.filter(c =>
    ["name", "default"].includes(c.id),
  );

  const [visible, setVisible] = useState(false);

  const handleArchive = (item: CompanyPaymentMethod) => {
    setLoading(true);
    updateCompanyPaymentMethod({
      input: {
        id: item.id,
        name: item.name,
        iban: item.iban,
        default: item.default,
        archived: !item.archived,
        sequential: item.sequential,
      },
    }).mapOk(data => {
      setLoading(false);
      match(data.updateCompanyPaymentMethod)
        .with({ __typename: "CompanyPaymentMethod" }, _ => {
          showToast({
            title: !item.archived ? t("common.archived") : t("common.unarchived"),
            variant: "success",
            autoClose: true,
          });
          setVisible(false);
        })
        .with({ __typename: "OperationInfo" }, errorHandling);
    });
  };

  return (
    <PlainListView
      withoutScroll={true}
      data={data}
      keyExtractor={item => item.id.toString()}
      headerHeight={36}
      rowHeight={63}
      headerStyle={styles.header as ViewStyle}
      headerBackgroundColor="#EDEDED"
      groupHeaderHeight={36}
      extraInfo={undefined}
      columns={columns}
      smallColumns={smallColumns}
      activeRowId={activeRowId}
      onActiveRowChange={onActiveRowChange}
      loading={loading}
      onEndReached={onEndReached}
      getRowLink={getRowLink}
      renderEmptyList={renderEmptyList}
      breakpoint={breakpoints.tiny}
    />
  );
};
