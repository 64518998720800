import { SalesInvoiceRelayQuery } from "@graphql/crm";
import { BottomPanel } from "@swan-io/lake/src/components/BottomPanel";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa6";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { AudioVoiceRecorder } from "./AudioRecorder";
import { UploadFile } from "./UploadFile";
import { WebCam } from "./WebCam";

const styles = StyleSheet.create({
  container: {
    position: "sticky",
    top: 16,
    left: 0,
    zIndex: 999,
    backgroundColor: backgroundColorVariants.transparent,
    height: "100%",
    maxHeight: "calc(100vh - 32px)",
    width: "25%",
    transition: "all 0.5s ease",
    marginRight: 20,
  },
  containerFile: {
    backgroundColor: backgroundColorVariants.white,
    width: "36%",
  },
  imageContainer: {
    width: "fit-content",
  },
  pdf: {
    width: "100%",
    height: "100%",
    backgroundSize: "unset",
  },
  card: {
    display: "flex",
    alignItems: "center",
    backgroundColor: backgroundColorVariants.white,
    padding: 32,
    borderWidth: 1,
    borderColor: backgroundColorVariants.gray200,
    borderStyle: "dashed",
    borderRadius: 5,
  },
  shadow: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
  },
  subtitle: {
    fontSize: 16,
    color: backgroundColorVariants.gray400,
  },
  gap: {
    gap: 16,
  },
});

type Props = {
  inputFile?: string;
  onUpload?: (file: File) => Promise<void>;
  setCurrentInvoice?: (invoice: SalesInvoiceRelayQuery) => void;
};

export const LeftPanel = ({ inputFile, onUpload, setCurrentInvoice }: Props) => {
  const [file, setFile] = useState<string>(inputFile as string);
  const [cameraImage, setCameraImage] = useState<File | null>(null);

  useEffect(() => {
    if (Boolean(inputFile)) {
      setFile(inputFile as string);
    }
  }, [inputFile]);

  return (
    <Box
      justifyContent="center"
      style={[
        styles.container,
        file?.includes(".pdf") ? styles.containerFile : styles.imageContainer,
      ]}
    >
      {file ? (
        <FileViewer url={file} />
      ) : (
        <Box style={styles.gap}>
          {setCurrentInvoice && <AudioVoiceRecorder setCurrentInvoice={setCurrentInvoice} />}

          {onUpload && (
            <>
              <UploadFile onUpload={onUpload} cameraImage={cameraImage} />
              <OpenCam setCameraImage={setCameraImage} />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

const FileViewer = ({ url }: { url: string }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    Image.getSize(url, (width, height) => setDimensions({ width, height }));
  }, [url]);

  return (
    <View style={styles.pdf}>
      {url.includes(".pdf") ? (
        <iframe src={url} style={styles.pdf}>
          This browser does not support PDFs. Please download the PDF to view it:{" "}
          <a href={url}>Download PDF</a>.
        </iframe>
      ) : (
        <Image
          source={{ uri: url }}
          style={[
            styles.pdf,
            {
              width: dimensions.width,
              height: dimensions.height,
            },
          ]}
          resizeMode="contain"
        />
      )}
    </View>
  );
};

type OpenCamProps = {
  setCameraImage: (image: File) => void;
};

const OpenCam = ({ setCameraImage }: OpenCamProps) => {
  const [hover, setHover] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Pressable
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}
        onPress={() => {
          setVisible(true);
        }}
      >
        <Box style={[styles.card, hover && styles.shadow]}>
          <FaCamera size={32} color={fontColorVariants.primary500} />
          <Space height={12} />
          <Text style={styles.title}>{t("invoices.scan")}</Text>
          <Space height={4} />
          <Text style={styles.subtitle}>{t("invoices.scanText")}</Text>
        </Box>
      </Pressable>

      <BottomPanel visible={visible} onPressClose={() => setVisible(false)}>
        <WebCam setCameraImage={setCameraImage} setVisible={setVisible} />
      </BottomPanel>
    </>
  );
};
