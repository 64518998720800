import background from "@assets/img/bg-signin.webp";
import control from "@assets/img/control.svg";
import favicon from "@assets/img/favicon.svg";
import logoWhite from "@assets/img/logo-trexx-white.svg";
import logo from "@assets/img/logo-trexx.svg";
import receipt from "@assets/img/receipt.svg";
import virtual from "@assets/img/virtual.svg";
import { InfoMarkenting } from "@components/InfoMarketing";
import { Button } from "@components/forms/Button";
import { FormEnter } from "@components/forms/FormEnter";
import Input from "@components/forms/Input";
import { RecoveryDocument } from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { Image, ScrollView, Text, View, useWindowDimensions } from "react-native";
import { combineValidators, useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { useUser } from "../features/context/UserContext";
import { common } from "../styles/common";
import { styles } from "../styles/sign";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateEmail, validateRequired } from "../utils/validations";

type FormState = {
  email: string;
};

export type InfoStep = {
  id: number;
  subtitle: string;
  description: string;
  icon: string;
};

const info: InfoStep[] = [
  {
    id: 1,
    subtitle: "Generador de facturas integrado",
    description: "Ahorra tiempo administrando y pagando facturas, además puedes sincronízarlas.",
    icon: receipt,
  },
  {
    id: 2,
    subtitle: "Iban virtual infinito",
    description:
      "Agregue subcuentas con números de cuenta designados para realizar un mejor presupuesto.",
    icon: virtual,
  },
  {
    id: 3,
    subtitle: "Controla las finanzas de tu negocio",
    description: "Sin saldo mínimo y con transacciones ilimitadas",
    icon: control,
  },
];

export const RecoveryPage = () => {
  const { user } = useUser();

  if (user) {
    Router.replace("SummaryRoot");
  }

  const [, crmRecovery] = useUrqlMutation(RecoveryDocument);

  const { Field, submitForm } = useForm<FormState>({
    email: { initialValue: "", validate: combineValidators(validateRequired, validateEmail) },
  });

  const { width } = useWindowDimensions();
  const isDesktop = width > 980;

  const handleSubmit = () => {
    submitForm((values: Partial<FormState>) => {
      crmRecovery({ input: { email: values.email ?? "" } })
        .mapOk(data => {
          match(data.recovery).otherwise(() => {
            showToast({
              variant: "success",
              title: t("common.sendPasswordSuccessfully"),
              autoClose: true,
            });
            Router.replace("SigninPage");
          });
        })
        .tapError(error => {
          showToast({ variant: "error", title: error.message, autoClose: true });
        });
    });
  };

  return (
    <ScrollView centerContent={true} style={common.bgPrimary50}>
      <Box direction={!isDesktop ? "column" : "row"} style={isDesktop && common.h100}>
        {isDesktop && (
          <Box style={[styles.left, styles.bgGradient]}>
            <View style={styles.leftContainer}>
              <AutoWidthImage sourceUri={logoWhite} height={34} alt="Trexx" />
            </View>

            <View style={styles.leftContainer}>
              <Text style={styles.h4}>Tú creaste tu negocio, nosotros le ayudamos a crecer</Text>

              {info.map(({ id, subtitle, description, icon }) => (
                <InfoMarkenting
                  key={id}
                  subtitle={subtitle}
                  description={description}
                  icon={icon}
                />
              ))}
            </View>

            <Image
              source={background}
              style={styles.background}
              alt="Background image sigin page"
            />
          </Box>
        )}

        <Box
          style={[styles.form, isDesktop && common.flexBasis50]}
          direction="column"
          justifyContent="center"
        >
          <FormEnter onSubmit={handleSubmit}>
            <View style={styles.box}>
              {!isDesktop ? (
                <AutoWidthImage sourceUri={logo} height={32} alt="Trexx" />
              ) : (
                <AutoWidthImage sourceUri={favicon} height={37} alt="Trexx" />
              )}

              <Box
                direction="row"
                alignItems="center"
                justifyContent="spaceBetween"
                style={styles.welcome}
              >
                <Text style={styles.h5}>{t("signxx.change_password")}</Text>
              </Box>

              <Text>{t("common.recovery_instructions")}</Text>

              <Field name="email">
                {Input({
                  label: t("signxx.yourEmail"),
                  placeholder: "juan@mail.com",
                  inputMode: "email",
                })}
              </Field>

              <Space height={4} />

              <Button mode="primary" onPress={handleSubmit}>
                {t("common.change_password")}
              </Button>

              <Link to={Router.SigninPage()} style={[styles.small, styles.link, common.underline]}>
                {t("signxx.back_to_login")}
              </Link>

              <Space height={12} />

              <Text style={styles.small}>
                Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                <Link to="" style={common.underline}>
                  Política de privacidad
                </Link>{" "}
                y los{" "}
                <Link to="" style={common.underline}>
                  Términos de servicio de Google
                </Link>
                .
              </Text>

              <Text style={styles.small}>
                También acepta recibir correos electrónicos de marketing relacionados con nuestros
                productos, de los cuales puede cancelar su suscripción en cualquier momento.
              </Text>
            </View>
          </FormEnter>
        </Box>
      </Box>
    </ScrollView>
  );
};
