import avatar from "@assets/icons/avatar.svg";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Image, StyleSheet, Text, View } from "react-native";
import { backgroundColorVariants, fontColorVariants } from "../../../../styles/constants";

type EmployeeCardProps = {
  photo: string | null | undefined;
  name: string;
  role: string;
};

const EmployeeCard = ({ photo, name, role }: EmployeeCardProps) => {
  return (
    <View style={styles.card}>
      <View style={styles.photoContainer}>
        {photo != null ? (
          <Image source={{ uri: photo }} style={styles.photo} />
        ) : (
          <View style={styles.defaultAvatarContainer}>
            <AutoWidthImage sourceUri={avatar} height={45} width={45} />
          </View>
        )}
      </View>

      <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
        {name}
      </Text>

      <Text numberOfLines={1} ellipsizeMode="tail" style={styles.role}>
        {role}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    width: 100,
    padding: 6,
    borderRadius: 10,
    alignItems: "center",
    elevation: 3,
  },
  photoContainer: {
    width: 60,
    height: 60,
    borderRadius: 100,
    overflow: "hidden",
    marginBottom: 5,
  },
  photo: {
    width: "100%",
    height: "100%",
  },
  defaultAvatarContainer: {
    width: 60,
    height: 60,
    backgroundColor: backgroundColorVariants.neutral10,
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.gray500,
    marginBottom: 1,
    textAlign: "center",
  },
  role: {
    fontSize: 12,
    color: fontColorVariants.gray500,
    textAlign: "center",
  },
});

export default EmployeeCard;
