import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { es } from "date-fns/locale/es";
import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../styles/constants";
import { t } from "../../utils/i18n";
import { Button } from "./Button";
// always import after react-datepicker/dist/react-datepicker.css
import "@assets/css/react-datepicker.css";

const LOCALE = "es";

if (LOCALE === "es") {
  registerLocale(LOCALE, es);
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    right: 0,
    top: 12,
    zIndex: 10,
    width: 360,
    paddingVertical: 12,
    paddingLeft: 16,
    paddingRight: 12,
    borderRadius: 8,
    backgroundColor: backgroundColorVariants.white,
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.25)",
    marginTop: 32,
  },
  periods: {
    gap: 6,
  },
  quarters: {
    paddingTop: 44,
    borderRightWidth: 1,
    borderRightColor: borderColorVariants.gray200,
    gap: 4,
  },
  dates: {
    fontSize: 14,
    fontWeight: "500",
    color: fontColorVariants.gray600,
  },
});

type Props = {
  from?: Date;
  to?: Date;
  onChange?: (dates: [Date | null, Date | null]) => void;
  style?: ViewStyle;
};

export const DatePickerComplex = ({ from, to, onChange, style }: Props) => {
  const [year, setYear] = useState(from?.getFullYear() ?? new Date().getFullYear());
  const [month, setMonth] = useState(from?.getMonth() ?? new Date().getMonth());

  const [startDate, setStartDate] = useState<Date | null>(from ?? new Date(year, month, 1));
  const [endDate, setEndDate] = useState<Date | null>(to ?? new Date(year, month + 1, 0));

  const onChangeRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onChangeMonth = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    if (!end) {
      setEndDate(end);
      return;
    }
    const lastDay = new Date(end.getFullYear(), end.getMonth() + 1, 0);
    setEndDate(lastDay);
  };

  const handleQuarter = (quarter: number) => {
    const firstDay = new Date(year, (quarter - 1) * 3, 1);
    const lastDay = new Date(year, quarter * 3, 0);
    setStartDate(firstDay);
    setEndDate(lastDay);
  };

  const handleFullMonth = () => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    setStartDate(firstDay);
    setEndDate(lastDay);
  };

  const [activeTabId, setActiveTabId] = useState("periods");

  const handleApply = () => {
    return onChange?.([startDate, getEndDate(activeTabId, startDate, endDate)]);
  };

  const getEndDate = (activeTabId: string, startDate: Date | null, endDate: Date | null) => {
    if (endDate) {
      return endDate;
    }
    if (activeTabId === "periods" && startDate) {
      return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    }
    return startDate;
  };

  return (
    <View style={[styles.container, style]}>
      <TabView
        tabs={[
          {
            id: "periods",
            label: t("common.periods"),
          },
          {
            id: "calendar",
            label: t("common.calendar"),
          },
        ]}
        otherLabel=""
        activeTabId={activeTabId}
        onChange={setActiveTabId}
      />

      <Space height={12} />

      {activeTabId === "periods" && (
        <>
          <Box direction="row" style={styles.periods}>
            <Box style={styles.quarters}>
              {[1, 2, 3, 4].map(quarter => (
                <Button key={quarter} mode="tertiary" onPress={() => handleQuarter(quarter)}>
                  T{quarter}
                </Button>
              ))}
            </Box>

            <Box>
              <DatePicker
                locale={LOCALE}
                onChange={onChangeMonth}
                startDate={startDate ?? undefined}
                endDate={endDate ?? undefined}
                onYearChange={date => {
                  const fullYear = new Date(date).getFullYear();
                  setYear(fullYear);
                }}
                showMonthYearPicker={true}
                selectsRange={true}
                inline={true}
              />

              <Button
                mode="tertiary"
                onPress={() => {
                  setStartDate(new Date(year, 0, 1));
                  setEndDate(new Date(year, 11, 31));
                }}
              >
                {t("common.fullYear", { year })}
              </Button>
            </Box>
          </Box>

          <Space height={12} />
        </>
      )}

      {activeTabId === "calendar" && (
        <>
          <DatePicker
            locale={LOCALE}
            selected={startDate}
            onChange={onChangeRange}
            startDate={startDate ?? undefined}
            endDate={endDate ?? undefined}
            onMonthChange={date => {
              const month = new Date(date).getMonth();
              setMonth(month);
            }}
            onYearChange={date => {
              const fullYear = new Date(date).getFullYear();
              setYear(fullYear);
            }}
            selectsRange={true}
            inline={true}
          />

          <Button mode="tertiary" onPress={handleFullMonth}>
            {t("common.fullMonth")}
          </Button>
        </>
      )}

      <Box direction="row" alignItems="center" justifyContent="spaceBetween">
        <Text style={styles.dates}>
          {startDate?.toLocaleDateString() ?? "Inicio"} -{" "}
          {getEndDate(activeTabId, startDate, endDate)?.toLocaleDateString()}
        </Text>

        <Button disabled={!startDate} onPress={handleApply}>
          {t("common.apply")}
        </Button>
      </Box>
    </View>
  );
};
