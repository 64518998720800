import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { Button, ButtonGroup } from "@components/forms/Button";
import { InputRounded } from "@components/forms/Input";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { StyleSheet, View } from "react-native";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { Navigation as InvoicesNavigation } from "../components/Navigation";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
  header: {
    paddingHorizontal: 24,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
});

export const Area = () => {
  const { desktop } = useResponsive();

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <InvoicesNavigation />
            </Sidebar>
          )}

          <View style={styles.root}>
            <View style={commonStyles.fill} role="main">
              <Box
                direction="row"
                alignItems="center"
                justifyContent="spaceBetween"
                style={styles.header}
              >
                <InputRounded>
                  <LakeSearchField
                    placeholder={t("common.search")}
                    initialValue={""}
                    onChangeText={search =>
                      Router.push("InvoicesSalesList", {
                        search,
                      })
                    }
                  />
                </InputRounded>

                <ButtonGroup>
                  <Button
                    style="group"
                    reverse={true}
                    onPress={() => {
                      Router.push("InvoicesSalesList", { visible: "1" });
                    }}
                  >
                    {t("invoices.createSales")}{" "}
                  </Button>

                  <Button
                    style="group"
                    reverse={true}
                    onPress={() => {
                      Router.push("InvoicesPurchaseList", { visible: "1" });
                    }}
                  >
                    {t("invoices.createPurchase")}{" "}
                  </Button>
                </ButtonGroup>
              </Box>

              <Heading title={t("invoices")} />
            </View>
          </View>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
