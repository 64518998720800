import {
  CreateCustomerPortalDocument,
  GetNextInvoiceDocument,
  Plan,
  PlansDocument,
} from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { match } from "ts-pattern";
import { useUser } from "../features/context/UserContext";
import { borderColorVariants, fontColorVariants } from "../styles/constants";
import { formatDate } from "../utils/date";
import { formatCurrency, t } from "../utils/i18n";
import { COLORS } from "../values/colors";
import { Heading } from "./Heading";
import { PlanCard } from "./PlanCard";

const styles = StyleSheet.create({
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
  },
  plans: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 20,
  },
  currentPlan: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
  },
  infoContainer: {
    paddingHorizontal: 32,
    paddingBottom: 24,
  },
  titleText: {
    fontSize: 24,
    fontWeight: "bold",
    color: fontColorVariants.gray700,
  },
  info: {
    gap: 6,
  },
  infoText: {
    fontSize: 15,
    color: fontColorVariants.gray500,
  },
  invoiceText: {
    textDecorationLine: "underline",
    color: fontColorVariants.primary500,
  },
});

type NextInvoice = {
  nextPaymentdate: string;
  amount: number;
};

export const UserSubscription = () => {
  const { company, subscription, reload } = useUser();
  const { data } = useUrqlQuery({ query: PlansDocument }, []);
  const [, customerPortal] = useUrqlMutation(CreateCustomerPortalDocument);
  const [, getNextInvoice] = useUrqlMutation(GetNextInvoiceDocument);
  const [nextInvoice, setNextInvoice] = useState<NextInvoice | null>(null);
  const [customerPortalUrl, setCustomerPortalUrl] = useState<string | null>(null);

  useEffect(() => {
    customerPortal({}).mapOk(data => {
      match(data.createCustomerPortal).with(
        { __typename: "CreateCustomerPortalOutput" },
        portal => {
          setCustomerPortalUrl(portal.redirectUrl);
        },
      );
    });
    getNextInvoice({}).mapOk(data => {
      match(data.getNextInvoice).with({ __typename: "NextInvoiceOutput" }, invoice => {
        setNextInvoice({
          nextPaymentdate: (invoice as { nextPaymentDate: string }).nextPaymentDate,
          amount: Number((invoice as { amount: string }).amount),
        });
      });
    });
  }, []);

  let plans = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.plans)
    .getWithDefault([])
    .filter(row => row.constitution === company?.constitution);

  if (subscription?.plan?.code !== undefined && subscription?.plan?.code !== "trial") {
    plans = plans.filter(row => row.code !== "trial");
  }

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="end" style={styles.header}></Box>
      <Heading title={t("plan.manageTitle")} />

      <View style={styles.infoContainer}>
        <Text style={styles.titleText}>
          {t("plan.have")} {subscription?.plan?.title}
        </Text>

        <Space height={8} />

        <Box style={styles.info}>
          {nextInvoice?.amount != null && (
            <>
              <Text style={styles.infoText}>{t("plan.nextInvoice")}</Text>

              <Text style={styles.infoText}>
                {formatCurrency(nextInvoice?.amount, "EUR")}

                {nextInvoice?.nextPaymentdate != null &&
                  ` - ${formatDate(nextInvoice?.nextPaymentdate, "DD/MM/YYYY")}`}
              </Text>
            </>
          )}

          <Text style={styles.invoiceText} href={customerPortalUrl as string}>
            {t("plan.listInvoices")}
          </Text>
        </Box>
      </View>

      <Space height={8} />

      <Box style={styles.plans}>
        {plans.map(plan => (
          <View key={plan.id} style={subscription?.plan?.id !== undefined && styles.currentPlan}>
            <PlanCard
              key={plan.id}
              plan={plan as Plan}
              currentPlan={subscription?.plan?.id === plan.id}
              cancelUrl="user/subscription"
              updating={true}
              isPendingCancel={subscription?.isPendingCancel ?? false}
              reload={reload}
            />
          </View>
        ))}
      </Box>
    </>
  );
};
