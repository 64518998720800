import { ProjectInfo as ProjectInfoGraphQL } from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text, View } from "react-native";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";
import EmployeeCard from "../../hr/components/employees/Card";
import { ProjectGraph } from "./ProjectGraph";

const styles = StyleSheet.create({
  outerContainer: {
    width: "100%",
    minWidth: 640,
    position: "relative",
    overflow: "hidden",
    borderRadius: 20,
    backgroundColor: backgroundColorVariants.white,
    border: "2px solid #FFFFFF",
    boxShadow:
      "0 61.607px 49.285px 0 rgba(201, 201, 201, 0.07), 0 25.738px 20.59px 0 rgba(108, 73, 172, 0.05), 0 13.761px 11.009px 0 rgba(108, 73, 172, 0.04), 0 7.714px 6.171px 0 rgba(108, 73, 172, 0.04), 0 4.097px 3.278px 0 rgba(108, 73, 172, 0.03), 0 1.705px 1.364px 0 rgba(108, 73, 172, 0.02)",
  },
  backgroundContainer: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(to bottom, transparent 135px, white 155px), linear-gradient(to right, #D44D19 0%, #5299EA 100%)",
    opacity: 0.6,
    filter: "blur(21px)",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
    zIndex: 1,
    padding: 32,
  },
  descriptionContainer: {
    width: "100%",
    height: "40px",
    marginTop: 12,
  },
  employeeContainer: {
    width: "100%",
    height: "130px",
  },
  graphContainer: {
    height: "150px",
  },
  nameContainer: {
    gap: 3,
    width: "40%",
  },
  logoContainer: {
    width: "20%",
    height: "100%",
    padding: 10,
  },
  label: {
    fontSize: 13,
    fontWeight: "500",
    color: fontColorVariants.gray500,
  },
  value: {
    fontSize: 36,
    fontWeight: "400",
  },
  result: {
    fontSize: 36,
    fontWeight: "500",
  },
  employeeCardContainer: {
    gap: 10,
    width: "100%",
  },
  white: {
    color: fontColorVariants.white,
  },
  small: {
    fontSize: 12,
    color: fontColorVariants.gray500,
    marginTop: 20,
  },
});

type ProjectInfoProps = {
  project: ProjectInfoGraphQL;
};

const maxEmployeesToShow = 4;

export const ProjectInfo = ({ project }: ProjectInfoProps) => {
  return (
    <View style={styles.outerContainer}>
      <View style={styles.backgroundContainer} />

      <View style={styles.contentContainer}>
        <Box direction="row">
          <Box justifyContent="center" style={styles.nameContainer}>
            <Text style={[styles.label, styles.white]}>{t("project")}</Text>
            <Text style={[styles.value, styles.white]}>{project?.name}</Text>
          </Box>

          <Box justifyContent="center" style={styles.nameContainer}>
            <Text style={[styles.label, styles.white]}>{t("project.result")}</Text>

            <Text style={[styles.result, styles.white]}>
              {formatCurrency(Number(project?.result), "EUR")}
            </Text>
          </Box>

          <View style={styles.logoContainer}>
            {project?.logo != null && (
              <AutoWidthImage sourceUri={project?.logo} height={60} width={60} />
            )}
          </View>
        </Box>

        <View style={styles.descriptionContainer}>
          <Text numberOfLines={2} ellipsizeMode="tail" style={[styles.label, styles.white]}>
            {project?.description}
          </Text>
        </View>

        <Box style={styles.employeeContainer}>
          <Text style={styles.label}>{t("project.employees")}</Text>

          <Box direction="row" style={styles.employeeCardContainer}>
            {project?.employees
              .slice(0, maxEmployeesToShow)
              .map(employee => (
                <EmployeeCard
                  key={employee.id}
                  name={employee.name}
                  role={employee.role as string}
                  photo={employee.photo}
                />
              ))}
          </Box>
        </Box>

        <View style={styles.graphContainer}>
          <Text style={styles.label}>{t("project.analysis")}</Text>
          <ProjectGraph graphData={project?.graphData} />
        </View>

        <Text style={styles.small}>* No incluye los costes de estructura generales</Text>
      </View>
    </View>
  );
};
