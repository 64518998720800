import {
  InvoiceHistoryOutput,
  PurchaseInvoiceHistoryDocument,
  SalesInvoiceHistoryDocument,
} from "@graphql/crm";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { extractQueryData } from "../../../utils/validations";

type Props = {
  salesInvoiceId?: string;
  purchaseInvoiceId?: string;
};

export const useInvoiceHistory = ({ salesInvoiceId, purchaseInvoiceId }: Props) => {
  const { data: sales } = useUrqlQuery({
    query: SalesInvoiceHistoryDocument,
    variables: {
      id: salesInvoiceId as string,
    },
    pause: salesInvoiceId == null || isNaN(Number(salesInvoiceId)),
  });

  const { data: purchase } = useUrqlQuery({
    query: PurchaseInvoiceHistoryDocument,
    variables: {
      id: purchaseInvoiceId as string,
    },
    pause: purchaseInvoiceId == null || isNaN(Number(salesInvoiceId)),
  });

  if (sales.isDone()) {
    return extractQueryData(sales, "value.value.salesInvoiceHistory") as InvoiceHistoryOutput[];
  }

  if (purchase.isDone()) {
    return extractQueryData(
      purchase,
      "value.value.purchaseInvoiceHistory",
    ) as InvoiceHistoryOutput[];
  }

  return undefined;
};
