import { DatePicker } from "@components/forms/DatePicker";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Item, LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { useEffect, useState } from "react";
import { addDaysToDate } from "../../../utils/date";
import { locale } from "../../../utils/i18n";

const CHOOSE_DAY = "choose_day";

type Props = {
  label: string;
  items: Item<string>[];
  forceCalendar: boolean;
  issueDate: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
};

export const ExpirationDate = ({
  label,
  items,
  forceCalendar,
  issueDate,
  value,
  onChange,
  error,
  disabled = false,
}: Props) => {
  const [expirationDateValue, setExpirationDateValue] = useState(forceCalendar ? CHOOSE_DAY : "");

  useEffect(() => {
    if (!value) {
      setExpirationDateValue("");
    }
  }, [value]);

  const handleChange = (value: string) => {
    if (value === CHOOSE_DAY) {
      onChange("");
    } else {
      const days = value.replace(/\D/g, "") || "0";
      const expirationDate = addDaysToDate(issueDate, parseInt(days));
      onChange(expirationDate);
    }
    setExpirationDateValue(value);
  };

  return expirationDateValue === CHOOSE_DAY ? (
    <DatePicker
      label={label}
      format={locale.dateFormat}
      firstWeekDay={locale.firstWeekday}
      value={value}
      onChange={value => !disabled && onChange(value)}
      error={error}
      disabled={disabled}
    />
  ) : (
    <LakeLabel
      label={label}
      render={() => (
        <LakeSelect
          items={items}
          value={expirationDateValue}
          onValueChange={handleChange}
          error={error}
        />
      )}
    />
  );
};
