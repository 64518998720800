import { UploadLogoDocument } from "@graphql/crm";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { UploadArea, UploadFileStatus } from "@swan-io/shared-business/src/components/UploadArea";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { checkRule, useSubscription } from "../../../utils/subscription";
import { useUser } from "../../context/UserContext";

export type FormValues = {
  File: FormValue;
};

type FormValue = UploadFileStatus[];

const MAX_SIZE_MB = 0.3;

type Props = {
  onUpload: (url: string) => void;
};

export const UploadLogoCompany = ({ onUpload }: Props) => {
  const { subscription } = useUser();
  const { Field, getFieldState, setFieldValue } = useForm<FormValues>({
    File: { initialValue: [], strategy: "onSubmit" },
  });

  const [, uploadLogo] = useUrqlMutation(UploadLogoDocument);

  const setFinishedStatus = (filename: string, error?: string) => {
    const state = getFieldState("File").value;
    const status = error != null ? "failed" : "finished";
    setFieldValue(
      "File",
      state
        .filter(file => file.id !== filename)
        .map(file =>
          file.id === filename || file.id === "NO_ID_YET"
            ? { ...file, status, error, name: filename, id: filename }
            : null,
        )
        .filter(Boolean) as UploadFileStatus[],
    );
  };

  const { showUpgrade } = useSubscription();

  const handleUpload = (files: File[]) => {
    const file = files[0];
    if (isNullish(file)) {
      return;
    }
    if (checkRule(subscription, "has_custom_invoices") === false) {
      showUpgrade({});
      return;
    }

    uploadLogo({ file }).mapOk(data => {
      match(data.uploadLogo)
        .with({ __typename: "OperationInfo" }, info => {
          setFinishedStatus(file.name, info?.messages[0]?.message ?? "");
        })
        .with({ __typename: "UploadLogoResponse" }, response => {
          onUpload(response.url);
          setFinishedStatus(file.name);
        });
    });
  };

  return (
    <Field name="File">
      {({ value, onChange, error }) => (
        <UploadArea
          accept={["image/png", "image/jpeg"]}
          documents={value}
          onDropAccepted={files => {
            onChange([{ id: "NO_ID_YET", status: "uploading", progress: 0 }]);
            handleUpload(files);
          }}
          onDropRejected={files => {
            onChange([{ id: "NO_ID_YET", status: "uploading", progress: 0 }]);
            if (files.length > 0 && files[0]?.file) {
              const message = match(files[0].errors?.[0]?.code)
                .with("file-invalid-type", () =>
                  t("upload.invalidType", { supportedTypes: "PNG, JPG" }),
                )
                .with("file-too-large", () =>
                  t("upload.hugeFile", {
                    maxSizeMB: MAX_SIZE_MB,
                  }),
                )
                .otherwise(() => files[0]?.errors?.[0]?.message);
              setFinishedStatus(files[0].file.name, message);
            }
          }}
          icon="arrow-upload-regular"
          description={t("upload.description", {
            supportedTypes: "PNG, JPG",
            maxSizeMB: MAX_SIZE_MB,
          })}
          maxSize={MAX_SIZE_MB * 1000 * 1000}
          error={error}
        />
      )}
    </Field>
  );
};
