import handOk from "@assets/img/hand_ok.png";
import { ImportStepReview } from "@components/ImportStepReview";
import { ImportStepUpload } from "@components/ImportStepUpload";
import { Button } from "@components/forms/Button";
import {
  EmployeeColumnsMapping,
  ErrorDetail,
  ImportEmployeesDocument,
  ImportEmployeesValidationDocument,
} from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeStepper, Step } from "@swan-io/lake/src/components/LakeStepper";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { useBoolean } from "@swan-io/lake/src/hooks/useBoolean";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { UploadFileStatus } from "@swan-io/shared-business/src/components/UploadArea";
import { useEffect, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { Validator, useForm } from "react-ux-form";
import { P, match } from "ts-pattern";
import { TrackingProvider } from "../../../../../onboarding/src/utils/matomo";
import { NotFoundPage } from "../../../pages/NotFoundPage";
import { t } from "../../../utils/i18n";
import { ArrowLeft, ArrowRight, DocumentPlus } from "../../../utils/icons";
import { COLORS } from "../../../values/colors";
import { useLoading } from "../../context/LoadingContext";

const styles = StyleSheet.create({
  stepper: {
    width: "100%",
    marginTop: 12,
  },
  buttons: {
    gap: 24,
    marginTop: 48,
  },
  tileEnd: {
    display: "grid",
    placeContent: "center",
    "--text-color": COLORS.NEUTRAL800,
    "--text-regular-font-size": 14,
    "--text-regular-font-weight": "500",
    borderWidth: 1,
    borderColor: COLORS.NEUTRAL300,
    "--color-background-default-accented": COLORS.NEUTRAL50,
    boxShadow: "none",
    marginTop: 48,
    marginHorizontal: 40,
  },
  handOk: {
    marginHorizontal: "auto",
    marginBottom: -40,
  },
});

type Props = {
  onPressClose?: () => void;
};

export type FormValues = {
  file: FormValue;
};

type FormValue = UploadFileStatus[];

const validateNotEmpty: Validator<FormValue> = value => {
  if (value.length === 0) {
    return t("common.form.required");
  }
};

export const ImportWizard = ({ onPressClose }: Props) => {
  const [errors, setErrors] = useState<ErrorDetail[]>();
  const [count, setCount] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [mappedColumns, setMappedColumns] = useState<EmployeeColumnsMapping>();

  const [currentStep, setStep] = useState("upload");

  const [finalized] = useBoolean(false);
  const steps = useMemo<WizardStep<string>[]>(
    () => [
      {
        id: "upload",
        label: t("import.step.title.upload"),
        errors: [],
      },
      {
        id: "review",
        label: t("import.step.title.review"),
        errors: [],
      },
      { id: "import", label: t("common.import"), errors: [] },
    ],
    [],
  );

  const stepperSteps = useMemo<Step[]>(
    () =>
      steps.map(step => ({
        id: step.id,
        label: step.label,
        url: "",
        hasErrors: finalized && step.errors.length > 0,
      })),
    [steps, finalized],
  );

  const { Field, getFieldState, setFieldValue, submitForm } = useForm<FormValues>({
    file: { initialValue: [], strategy: "onSubmit", validate: validateNotEmpty },
  });

  useEffect(() => {
    if (currentStep === "upload") {
      setFieldValue("file", []);
      setErrors(undefined);
      setMappedColumns(undefined);
    }
  }, [currentStep, setFieldValue]);

  const [, importValidation] = useUrqlMutation(ImportEmployeesValidationDocument);
  const [, importEmployees] = useUrqlMutation(ImportEmployeesDocument);
  const { setLoading } = useLoading();

  const setFinishedStatus = (filename: string, error?: string) => {
    const state = getFieldState("file").value;
    const status = error != null ? "failed" : "finished";
    setFieldValue(
      "file",
      state
        .filter(file => file.id !== filename)
        .map(file =>
          file.id === filename || file.id === "NO_ID_YET"
            ? { ...file, status, error, name: filename, id: filename }
            : null,
        )
        .filter(Boolean) as FormValue,
    );
  };

  const onRemoveDocument = (filename: string) => {
    const state = getFieldState("file").value;
    setFieldValue(
      "file",
      state.filter(file => file.id !== filename),
    );
  };

  const handleUpload = (files: File[]) => {
    setLoading(true);
    const file = files[0];
    if (isNullish(file)) {
      return;
    }
    setFile(file);

    importValidation({ file }).mapOk(data => {
      match(data.importEmployeesValidation)
        .with({ __typename: "OperationInfo" }, info => {
          setFinishedStatus(file.name, info?.messages[0]?.message ?? "");
        })
        .with(
          { __typename: "ImportPreviewResponse", errors: P.array({ __typename: "ErrorDetail" }) },
          ({ errors }) => {
            setErrors(errors as ErrorDetail[]);
            setFinishedStatus(file.name);
          },
        )
        .otherwise(() => setFinishedStatus(file.name));

      match(data.importEmployeesValidation).with(
        {
          __typename: "ImportPreviewResponse",
          columnsMapping: { __typename: "EmployeeColumnsMapping" },
        },
        ({ columnsMapping }) => {
          setMappedColumns(columnsMapping);

          setStep("review");
        },
      );
      setLoading(false);
    });
  };

  const handleImport = () => {
    setLoading(true);
    submitForm(() => {
      importEmployees({ file }).mapOk(data =>
        match(data.importEmployees).with({ __typename: "ImportEmployeesResponse" }, ({ count }) =>
          setCount(Number(count)),
        ),
      );
      setLoading(false);
      setStep("import");
    });
  };

  return (
    <>
      <LakeStepper activeStepId={currentStep} steps={stepperSteps} style={styles.stepper} />

      {match(currentStep)
        .with("upload", () => (
          <TrackingProvider category="Upload">
            <ImportStepUpload
              handleUpload={handleUpload}
              onRemoveDocument={onRemoveDocument}
              setFinishedStatus={setFinishedStatus}
              Field={Field}
            />
          </TrackingProvider>
        ))
        .with("review", () => (
          <TrackingProvider category="Review">
            <ImportStepReview
              errors={errors}
              mappedColumns={(mappedColumns as EmployeeColumnsMapping) ?? undefined}
            />

            <Box direction="row" justifyContent="end" style={styles.buttons}>
              <Button
                mode="secondary"
                size="large"
                onPress={() => setStep("upload")}
                icon={ArrowLeft}
                reverse={true}
              >
                {t("common.back")}
              </Button>

              <Button
                mode="primary"
                size="large"
                onPress={handleImport}
                icon={DocumentPlus}
                disabled={
                  (errors && errors?.length > 0) ?? getFieldState("file").value.length === 0
                }
              >
                {t("common.import")}
              </Button>
            </Box>
          </TrackingProvider>
        ))
        .with("import", () => (
          <TrackingProvider category="Import">
            <Tile style={styles.tileEnd}>
              <LakeText>{t("employee.import.step.importOk", { count })}</LakeText>
              <AutoWidthImage sourceUri={handOk} height={219} style={styles.handOk} />
            </Tile>

            <Box direction="row" justifyContent="end" style={styles.buttons}>
              <Button
                mode="secondary"
                size="large"
                onPress={() => setStep("upload")}
                icon={ArrowLeft}
                reverse={true}
              >
                {t("import.again")}
              </Button>

              <Button mode="primary" size="large" onPress={onPressClose} icon={ArrowRight}>
                {t("employee.import.continue")}
              </Button>
            </Box>
          </TrackingProvider>
        ))
        .otherwise(() => (
          <NotFoundPage />
        ))}
    </>
  );
};
