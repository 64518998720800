import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 40,
    padding: 20,
  },
  field: {
    flex: 1,
  },
  halfField: {
    flex: 0.5,
  },
  verticalBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 20,
  },
  formatPreview: {
    fontSize: 12,
  }
});
