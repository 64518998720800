import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import { CreateOrUpdateProjectDocument, OperationInfo, Project } from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { UploadArea } from "@swan-io/shared-business/src/components/UploadArea";
import { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { StyleSheet } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { CombinedError } from "urql";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { validateRequired } from "../../../utils/validations";
import { ProjectState } from "../types";

const styles = StyleSheet.create({
  grid: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "75%",
    alignSelf: "flex-end",
    gap: 48,
  },
  photoPreview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
});

const MAX_SIZE_MB = 0.3;

type Props = {
  data?: Project;
  onPressClose?: () => void;
  showCancel: boolean;
};

export const ProjectForm = ({ data, onPressClose, showCancel }: Props) => {
  const { Field, submitForm, setFieldError } = useForm<ProjectState>({
    name: { initialValue: data?.name ?? "", validate: validateRequired },
    description: { initialValue: data?.description ?? "", validate: validateRequired },
    logo: { initialValue: null },
  });

  const [, create_or_update] = useUrqlMutation(CreateOrUpdateProjectDocument);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);

  const tapError = (error: Error | CombinedError) => {
    showToast({ variant: "error", title: error.message, autoClose: true });
  };

  const otherwise = () => {
    onPressClose?.();
  };

  const onSave = () => {
    submitForm(values => {
      const input = {
        id: Number(data?.id) ?? undefined,
        name: values.name,
        description: values.description,
        logo: values.logo,
      };
      create_or_update({
        input,
      })
        .mapOk(data => {
          match(data.createOrUpdateProject)
            .with({ __typename: "OperationInfo" }, () => {
              for (const error of (data.createOrUpdateProject as OperationInfo).messages) {
                if (error.field != null) {
                  setFieldError(error?.field as keyof ProjectState, error?.message ?? "");
                } else {
                  showToast({
                    variant: "error",
                    title: error.message,
                    autoClose: true,
                  });
                }
              }
            })
            .otherwise(otherwise);
        })
        .tapError(tapError);
    });
  };
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (submit) {
      setSubmit(false);
    }
  }, [submit]);

  return (
    <>
      <Box style={styles.grid}>
        <Field name="logo">
          {({ onChange, error }) => (
            <>
              <UploadArea
                accept={["image/png", "image/jpeg", "image/jpg"]}
                onDropAccepted={files => {
                  const file = files[0];
                  onChange(file);
                  if (file && file.type.startsWith("image/")) {
                    const newPreviewUrl = URL.createObjectURL(file);
                    if (logoPreview != null) {
                      URL.revokeObjectURL(logoPreview);
                    }
                    setLogoPreview(newPreviewUrl);
                  }
                }}
                onDropRejected={files => {
                  if (files.length > 0 && files[0]?.file) {
                    const message = match(files[0].errors?.[0]?.code)
                      .with("file-invalid-type", () =>
                        t("upload.invalidType", { supportedTypes: "PNG, JPG" }),
                      )
                      .with("file-too-large", () =>
                        t("upload.hugeFile", {
                          maxSizeMB: MAX_SIZE_MB,
                        }),
                      )
                      .otherwise(() => files[0]?.errors?.[0]?.message);
                    showToast({ variant: "error", title: message as string, autoClose: true });
                  }
                }}
                icon="arrow-upload-regular"
                description={t("upload.description", {
                  supportedTypes: "PNG, JPG",
                  maxSizeMB: MAX_SIZE_MB,
                })}
                maxSize={MAX_SIZE_MB * 1000 * 1000}
                error={error}
              />

              {(logoPreview != null || data?.logo != null) && (
                <Box style={styles.photoPreview}>
                  <AutoWidthImage
                    sourceUri={(logoPreview ?? data?.logo) as string}
                    height={100}
                    width={100}
                  />
                </Box>
              )}
            </>
          )}
        </Field>
      </Box>

      <Space height={8} />

      <Box style={styles.grid}>
        <Field name="name">
          {Input({
            label: t("hr.name"),
            required: true,
          })}
        </Field>

        <Field name="description">
          {Input({
            label: t("invoices.description"),
            required: true,
          })}
        </Field>
      </Box>

      <Space height={24} />

      <Box style={showCancel === true && styles.gap} alignItems="end">
        {showCancel === true ? (
          <Button onPress={onPressClose} mode="secondary">
            {t("common.cancel")}
          </Button>
        ) : null}

        <Button onPress={onSave} icon={<SaveIcon />}>
          {t("common.save")}
        </Button>
      </Box>
    </>
  );
};
