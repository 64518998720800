import { ProjectInfo as ProjectInfoGraphQL } from "@graphql/crm";
import { StyleSheet, View } from "react-native";
import { ProjectInfo } from "./ProjectInfo";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  projectsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(640px, 1fr))",
    justifyItems: "center",
    width: "100%",
    alignItems: "center",
    gap: 40,
  },
});

type ProjectsListProps = {
  projects: ProjectInfoGraphQL[];
};

export const ProjectList = ({ projects }: ProjectsListProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.projectsContainer}>
        {projects.map(project => (
          <ProjectInfo key={project.id} project={project} />
        ))}
      </View>
    </View>
  );
};
