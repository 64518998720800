import taxes from "@assets/img/taxes.webp";
import { Calendar } from "@components/Calendar";
import { HorizonalValues } from "@components/HorizontalValues";
import { Sidebar } from "@components/Sidebar";
import { Taxes } from "@components/Taxes";
import { Bars } from "@components/chart/Bars";
import { HorizontalBar } from "@components/chart/HorizontalBar";
import { Growth } from "@components/summary/Growth";
import {
  GroupedOutput,
  GrowthOutput,
  SummaryDocument,
  SummationOutput,
  SummationPendingOutput,
} from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useUser } from "../features/context/UserContext";
import { useDateFilter } from "../hooks/useDateFilter";
import { backgroundColorVariants, fontColorVariants } from "../styles/constants";
import { getHour } from "../utils/date";
import { toNumber } from "../utils/decimal";
import { formatCurrency, t } from "../utils/i18n";
import { extractQueryData } from "../utils/validations";
import { COLORS } from "../values/colors";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
  headContainer: {
    zIndex: 1,
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  heading: {
    color: fontColorVariants.gray500,
    fontSize: 28,
    fontWeight: "500",
  },
  charts: {
    paddingHorizontal: 24,
  },
  box: {
    borderRadius: 25,
    boxShadow:
      "0 67.609px 54.087px 0 rgba(201, 201, 201, 0.07), 0 28.246px 22.596px 0 rgba(108, 73, 172, 0.05), 0 15.101px 12.081px 0 rgba(108, 73, 172, 0.04), 0 8.466px 6.773px 0 rgba(108, 73, 172, 0.04), 0 4.496px 3.597px 0 rgba(108, 73, 172, 0.03), 0 1.871px 1.497px 0 rgba(108, 73, 172, 0.02)",
    paddingVertical: 24,
    paddingHorizontal: 18,
    backgroundColor: backgroundColorVariants.dark,
    color: fontColorVariants.white,
    marginBottom: 12,
  },
  row: {
    display: "grid",
    gridTemplateColumns: ".6fr .4fr",
    gap: 12,
  },
  title: {
    fontSize: 24,
    fontWeight: "500",
    color: fontColorVariants.white,
  },
});

export const Summary = () => {
  const { desktop } = useResponsive();

  const { user, company } = useUser();

  const name = user?.firstName != "" ? user?.firstName : company?.name;

  const { from, to } = useDateFilter();

  const { data } = useUrqlQuery(
    {
      query: SummaryDocument,
      variables: {
        filters: {
          dateRange: { gte: from, lte: to },
        },
      },
    },
    [from, to],
  );
  const summation = extractQueryData(data, "value.value.summarySummation") as SummationOutput;
  const grouped = extractQueryData(data, "value.value.summaryGrouped") as GroupedOutput;
  const summationPending = extractQueryData(
    data,
    "value.value.summarySummationPending",
  ) as SummationPendingOutput;
  const growth = extractQueryData(data, "value.value.summaryGrowth") as GrowthOutput;

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && <Sidebar />}

          <ScrollView style={styles.root}>
            <ResponsiveContainer breakpoint={breakpoints.medium}>
              {({ large }) => (
                <View style={commonStyles.fill} role="main">
                  <Box direction="row" alignItems="center" style={styles.headContainer}>
                    <Text style={styles.heading}>
                      {t("greeting", {
                        greeting: getHour() < 12 ? t("goodMorning") : t("goodAfternoon"),
                        name: name != null ? `, ${name}` : "",
                      })}
                    </Text>

                    <Space width={24} />
                    <Calendar />
                  </Box>

                  <Space height={large ? 12 : 32} />

                  <View style={styles.charts}>
                    <HorizonalValues
                      data={[
                        {
                          title: t("common.income"),
                          value: formatCurrency((summation?.income as number) ?? 0, "EUR"),
                        },
                        {
                          title: t("common.expenses"),
                          value: formatCurrency((summation?.expenses as number) ?? 0, "EUR"),
                        },
                        {
                          title: t("common.result"),
                          value: formatCurrency((summation?.result as number) ?? 0, "EUR"),
                        },
                      ]}
                      image={taxes}
                      imageHeight={142}
                    />

                    <Box style={styles.box}>
                      <Text style={styles.title}>{t("common.summary")}</Text>

                      <Bars
                        labels={grouped?.labels as []}
                        datasets={[
                          {
                            data: grouped?.dataset1.map(value => toNumber(value as string)),
                          },
                          {
                            data: grouped?.dataset2.map(value => toNumber(value as string)),
                          },
                        ]}
                        showAverage={true}
                      />
                    </Box>

                    <Box justifyContent="spaceBetween" style={large && styles.row}>
                      <Box style={styles.box}>
                        <Text style={styles.title}>{t("common.salesReceivable")}</Text>

                        <HorizontalBar
                          labels={[""]}
                          datasets={[
                            {
                              label: t("common.pending"),
                              data: [Math.max(0, summationPending?.pending as number)],
                            },
                            {
                              label: t("common.overdued"),
                              data: [Math.max(0, summationPending?.overdued as number)],
                            },
                          ]}
                        />
                      </Box>

                      <Box style={styles.box}>
                        <Text style={styles.title}>{t("common.growth")}</Text>
                        <Growth data={growth} />
                      </Box>
                    </Box>

                    <Taxes from={from} to={to} />
                    <Space height={12} />
                  </View>
                </View>
              )}
            </ResponsiveContainer>
          </ScrollView>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
