import { Product } from "@graphql/crm";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { t } from "../../../utils/i18n";
import { ProductEditor } from "./ProductEditor";

type Props = {
  visible?: boolean;
  product?: Product;
  onPressClose?: () => void;
};

export const ProductCreate = ({ visible = false, product, onPressClose }: Props) => {
  return (
    <LakeModal
      visible={visible}
      title={product?.id !== undefined ? t("invoices.editProduct") : t("invoices.newProduct")}
      onPressClose={onPressClose}
      maxWidth={660}
    >
      <ProductEditor onPressClose={onPressClose} showCancel={true} />
    </LakeModal>
  );
};
