import { Employee, EmployeeDocument } from "@graphql/crm";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { extractQueryData } from "../../../utils/validations";

export const useGetEmployee = (id?: string) => {
  const { data: query } = useUrqlQuery({
    query: EmployeeDocument,
    variables: {
      id: id as string,
    },
    pause: id == null,
  });

  return extractQueryData(query, "value.value.employee") as Employee;
};
