import { NavigationItem } from "@components/NavigationItem";
import { Space } from "@swan-io/lake/src/components/Space";
import { FaArrowUp } from "react-icons/fa6";
import { View } from "react-native";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";

export const Navigation = () => {
  return (
    <View role="navigation">
      <NavigationItem to={Router.AccountingList()} label={t("accounting.ledger")} />
      <Space height={24} />

      <NavigationItem
        to={Router.AccountingList({ import: "" })}
        renderIcon={<FaArrowUp />}
        label={t("common.import")}
      />
    </View>
  );
};
