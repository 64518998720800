import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { StyleSheet, Text } from "react-native";
import { t } from "../utils/i18n";
import { Button } from "./forms/Button";

const styles = StyleSheet.create({
  verticalBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 20,
  },
  modalButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    width: "100%",
  },
  modalButton: {
    width: "50%",
    padding: 10,
  },
});

type Props = {
  visible?: boolean;
  title: string;
  text: string;
  confirmButton: string;
  onPressClose?: () => void;
  onPressConfirm?: () => void;
};

export const ConfirmModal = ({
  visible = false,
  title,
  text,
  confirmButton,
  onPressClose,
  onPressConfirm,
}: Props) => {
  return (
    <LakeModal visible={visible} title={title} maxWidth={600} onPressClose={onPressClose}>
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {({ large }) => (
          <>
            <Box style={large && styles.verticalBox}>
              <Text>{text}</Text>
            </Box>

            <Box direction={"row"} style={large && styles.modalButtons}>
              <Box style={styles.modalButton}>
                <Button onPress={onPressClose} reverse={true}>
                  {t("common.cancel")}
                </Button>
              </Box>

              <Box style={styles.modalButton}>
                <Button onPress={onPressConfirm} reverse={true}>
                  {confirmButton}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
