import ingresosFinancierosIcon from "@assets/icons/ingresos_financieros.svg";
import otrosIngresosIcon from "@assets/icons/otros_ingresos.svg";
import resultadosExtraordinariosIcon from "@assets/icons/resultados_extraordinarios.svg";
import ingresosArrendamientosIcon from "@assets/icons/sales/otros_ingresos_explotacion/ingresos_arrendamientos.svg";
import ingresosComisionesIcon from "@assets/icons/sales/otros_ingresos_explotacion/ingresos_comisiones.svg";
import ingresosPropiedadIndustrialIcon from "@assets/icons/sales/otros_ingresos_explotacion/ingresos_propiedad_industrial.svg";
import ingresosServiciosDiversosIcon from "@assets/icons/sales/otros_ingresos_explotacion/ingresos_servicios_diversos.svg";
import ingresosServiciosPersonalIcon from "@assets/icons/sales/otros_ingresos_explotacion/ingresos_servicios_personal.svg";
import beneficiosInmovilizadoIcon from "@assets/icons/sales/resultados_extraordinarios/beneficios_procedentes_inmobilizado.svg";
import beneficiosCapitalIcon from "@assets/icons/sales/resultados_extraordinarios/beneficios_procedentes_participantes.svg";
import diferenciasCambioIcon from "@assets/icons/sales/resultados_extraordinarios/diferencias_cambio.svg";
import ingresosExtraorinariosIcon from "@assets/icons/sales/resultados_extraordinarios/ingresos_extraordinarios.svg";
import otrasSubvencionesIcon from "@assets/icons/sales/subvenciones/otras_subvenciones.svg";
import subvencionesCapitalIcon from "@assets/icons/sales/subvenciones/subvencion_capital.svg";
import subvencionesExplotacionIcon from "@assets/icons/sales/subvenciones/subvencion_explotacion.svg";
import descuentosProntoPagoIcon from "@assets/icons/sales/venta_productos/descuentos_pronto_pago.svg";
import devolucionesVentasIcon from "@assets/icons/sales/venta_productos/devoluciones_ventas.svg";
import prestacionServiciosIcon from "@assets/icons/sales/venta_productos/prestacion_servicios.svg";
import rappelsVentasIcon from "@assets/icons/sales/venta_productos/rappels_ventas.svg";
import ventasMercaderiasProductosIcon from "@assets/icons/sales/venta_productos/ventas_mercancias_productos.svg";
import subvencionesIcon from "@assets/icons/subvenciones.svg";
import trabajoEmpresasIcon from "@assets/icons/trabajo_empresas.svg";
import ventaProductosIcon from "@assets/icons/venta_productos.svg";
import { ChoicePicker } from "@components/ChoicePicker";
import { IconChoice } from "@components/IconChoice";
import { Button } from "@components/forms/Button";
import { SalesCategory, SalesInvoiceCreateStaticDocument, Static } from "@graphql/crm";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { StyleSheet, Text } from "react-native";
import { match } from "ts-pattern";
import { common } from "../../../styles/common";
import { t } from "../../../utils/i18n";
import { CategoryConcept, CreateInvoiceState } from "../types";
import { extractSalesStatic } from "../utils";
import { SalesCategorySuggestion } from "./SalesCategorySuggestion";

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "600",
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 8,
  },
});

type Props = {
  form: CategoryConcept;
};

const categoriesIcon: { [key: string]: string } = {
  VENTA_PRODUCTOS: ventaProductosIcon,
  TRABAJO_EMPRESAS: trabajoEmpresasIcon,
  SUBVENCIONES: subvencionesIcon,
  OTROS_INGRESOS: otrosIngresosIcon,
  INGRESOS_FINANCIEROS: ingresosFinancierosIcon,
  RESULTADOS_EXTRAORDINARIOS: resultadosExtraordinariosIcon,
};

const SubcategoriesIcons: { [key: string]: string } = {
  venta: ventasMercaderiasProductosIcon,
  prestacion_servicios: prestacionServiciosIcon,
  descuento: descuentosProntoPagoIcon,
  devoluciones: devolucionesVentasIcon,
  rappels: rappelsVentasIcon,
  trabajos: trabajoEmpresasIcon,
  subvenciones_explotacion: subvencionesExplotacionIcon,
  subvenciones_capital: subvencionesCapitalIcon,
  otras_subvenciones: otrasSubvencionesIcon,
  ingresos_arrendamiento: ingresosArrendamientosIcon,
  ingresos_propiedad: ingresosPropiedadIndustrialIcon,
  ingresos_comisiones: ingresosComisionesIcon,
  ingresos_servicios_personal: ingresosServiciosPersonalIcon,
  ingresos_servicios_diversos: ingresosServiciosDiversosIcon,
  ingresos_financieros: ingresosFinancierosIcon,
  beneficios_inmovilizado: beneficiosInmovilizadoIcon,
  beneficios_capital: beneficiosCapitalIcon,
  ingresos_extraordinarios: ingresosExtraorinariosIcon,
  diferencias_cambio: diferenciasCambioIcon,
};

export const SalesRightPanel = ({ form }: Props) => {
  const [subcategories, setSubcategories] = useState<Static[]>([]);
  const [categoriesWithIcons, setCategoriesWithIcons] = useState<Static[]>([]);
  const [subcategoriesWithIcons, setSubcategoriesWithIcons] = useState<Static[]>([]);

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  const categories = extractSalesStatic(data, "saleCategory");

  const ventaProductos = extractSalesStatic(data, "ventaProductos");
  const trabajoEmpresas = extractSalesStatic(data, "trabajoEmpresas");
  const subvenciones = extractSalesStatic(data, "subvenciones");
  const otrosIngresos = extractSalesStatic(data, "otrosIngresos");
  const ingresosFinancieros = extractSalesStatic(data, "ingresosFinancieros");
  const resultadosExtraordinarios = extractSalesStatic(data, "resultadosExtraordinarios");

  function changeFields({ category }: Partial<CreateInvoiceState>) {
    match(category)
      .with("VENTA_PRODUCTOS", () => setSubcategories(ventaProductos))
      .with("TRABAJO_EMPRESAS", () => setSubcategories(trabajoEmpresas))
      .with("SUBVENCIONES", () => setSubcategories(subvenciones))
      .with("OTROS_INGRESOS", () => setSubcategories(otrosIngresos))
      .with("INGRESOS_FINANCIEROS", () => setSubcategories(ingresosFinancieros))
      .with("RESULTADOS_EXTRAORDINARIOS", () => setSubcategories(resultadosExtraordinarios))
      .otherwise(() => setSubcategories([]));
  }

  useEffect(() => {
    const listener = form.listenFields(["category", "subcategory"], ({ category }) => {
      changeFields({ category: category?.value });
    });

    changeFields({ category: form.getFieldState("category").value });

    return () => listener();
  }, [form.listenFields, data]);

  useEffect(() => {
    if (categories.length > 0) {
      const subcategories = [
        ...ventaProductos,
        ...trabajoEmpresas,
        ...subvenciones,
        ...otrosIngresos,
        ...ingresosFinancieros,
        ...resultadosExtraordinarios,
      ];
      setCategoriesWithIcons(
        categories.map(category => {
          return {
            ...category,
            id: category.id.toUpperCase(),
            icon: categoriesIcon[category.value],
          };
        }),
      );
      setSubcategoriesWithIcons(
        subcategories.map(subcategory => {
          return {
            ...subcategory,
            icon: SubcategoriesIcons[subcategory.value],
          };
        }),
      );
    }
  }, [data]);

  const renderItem = (item: string, selected?: string) => {
    const icons: { [key: string]: string } = {
      VENTA_PRODUCTOS: ventaProductosIcon,
      TRABAJO_EMPRESAS: trabajoEmpresasIcon,
      SUBVENCIONES: subvencionesIcon,
      OTROS_INGRESOS: otrosIngresosIcon,
      INGRESOS_FINANCIEROS: ingresosFinancierosIcon,
      RESULTADOS_EXTRAORDINARIOS: resultadosExtraordinariosIcon,
    };
    const sourceUri = icons[item] ?? "";
    const name = categories.find(({ value }) => value.toUpperCase() === item)?.name ?? item;
    return <IconChoice sourceUri={sourceUri} name={name} isSelected={selected === item} />;
  };

  const renderItemSubcategories = (item: string, selected?: string) => {
    const icons: { [key: string]: string } = {
      venta: ventasMercaderiasProductosIcon,
      prestacion_servicios: prestacionServiciosIcon,
      descuento: descuentosProntoPagoIcon,
      devoluciones: devolucionesVentasIcon,
      rappels: rappelsVentasIcon,
      trabajos: trabajoEmpresasIcon,
      subvenciones_explotacion: subvencionesExplotacionIcon,
      subvenciones_capital: subvencionesCapitalIcon,
      otras_subvenciones: otrasSubvencionesIcon,
      ingresos_arrendamiento: ingresosArrendamientosIcon,
      ingresos_propiedad: ingresosPropiedadIndustrialIcon,
      ingresos_comisiones: ingresosComisionesIcon,
      ingresos_servicios_personal: ingresosServiciosPersonalIcon,
      ingresos_servicios_diversos: ingresosServiciosDiversosIcon,
      ingresos_financieros: ingresosFinancierosIcon,
      beneficios_inmovilizado: beneficiosInmovilizadoIcon,
      beneficios_capital: beneficiosCapitalIcon,
      ingresos_extraordinarios: ingresosExtraorinariosIcon,
      diferencias_cambio: diferenciasCambioIcon,
    };
    const sourceUri = icons[item] ?? "";
    const name = subcategories.find(({ value }) => value === item)?.name ?? item;
    return <IconChoice sourceUri={sourceUri} name={name} isSelected={selected === item} />;
  };

  const getSubtitle = (subcategory?: string) => {
    const categoryName = categories.find(
      c => c.value.toUpperCase() === form.getFieldState("category")?.value,
    )?.name;
    const subcategoryName = subcategories.find(c => c.value === subcategory)?.name;

    if (categoryName != null) {
      return `${categoryName}${subcategoryName != null ? ` / ${subcategoryName}` : ""}`;
    }
  };

  return (
    <>
      <Space height={24} />

      <SalesCategorySuggestion
        categories={categoriesWithIcons}
        subcategories={subcategoriesWithIcons}
        concept={form}
      />

      <Space height={24} />

      <form.Field name="category">
        {({ value, onChange, error }) =>
          subcategories.length === 0 ? (
            <>
              <Text style={styles.title}>{t("invoices.saleCategory")}</Text>

              <ChoicePicker
                items={categories.map(({ value }) => value.toUpperCase() as SalesCategory)}
                renderItem={item => renderItem(item, value)}
                value={value}
                onChange={value => {
                  onChange(value);
                  form.onChange();
                }}
              />

              <Text style={common.error}>{error}</Text>
            </>
          ) : null
        }
      </form.Field>

      <form.Field name="subcategory">
        {({ value, onChange, error }) =>
          subcategories.length > 0 ? (
            <>
              <Text style={styles.title}>{t("invoices.subcategory")}</Text>
              <Text style={styles.subtitle}>{getSubtitle(value)}</Text>

              <ChoicePicker
                items={subcategories.map(({ value }) => value)}
                renderItem={item => renderItemSubcategories(item, value)}
                value={value}
                onChange={value => {
                  onChange(value);
                  form.onChange();
                }}
              />

              <Text style={common.error}>{error}</Text>
              <Space height={16} />

              <Button
                size="small"
                mode="tertiary"
                style="outlined"
                onPress={() => {
                  form.setFieldValue("category", undefined);
                  form.setFieldValue("subcategory", undefined);
                }}
              >
                {t("invoices.backToCategories")}
              </Button>
            </>
          ) : null
        }
      </form.Field>
    </>
  );
};
