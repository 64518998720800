import download from "@assets/icons/download.svg";
import mail from "@assets/icons/mail.svg";
import { PayrollRelay } from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Popover from "react-native-popover-view";
import { borderColorVariants } from "../../../styles/constants";
import { downloadPDF } from "../../../utils/blob";
import { t } from "../../../utils/i18n";
import { useLoading } from "../../context/LoadingContext";
import { get_pdf } from "../services";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: 4,
    width: "100%",
  },
  menu: {
    padding: 8,
    borderRadius: 8,
  },
  menuItem: {
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
  },
  menuText: {
    marginLeft: 8,
  },
});

type KebabMenuProps = {
  payroll: PayrollRelay;
  setShowSentModal: (show: boolean) => void;
  setPayroll: (payroll: PayrollRelay) => void;
};

const KebabMenu = ({ payroll, setShowSentModal, setPayroll }: KebabMenuProps) => {
  const { setLoading } = useLoading();
  const payrollId = payroll.id as string;
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleSend = () => {
    setShowPopover(false);
    setPayroll(payroll);
    setShowSentModal(true);
  };

  const handleDownload = () => {
    setShowPopover(false);
    setLoading(true);
    get_pdf(payrollId)
      .then(async response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const blob = await response.blob();
        downloadPDF(blob, `${payroll.employee.nif ?? "payroll"}_${payrollId}`);
      })
      .catch((error: Error) => {
        showToast({
          variant: "error",
          title: error.message,
          autoClose: true,
        });
      })
      .finally(() => {
        setLoading(false);
        setShowPopover(false);
      });
  };

  return (
    <View
      style={styles.container}
      onTouchEnd={e => {
        e.stopPropagation();
      }}
    >
      {payroll.status !== "draft" ? (
        <TouchableOpacity onPress={handleSend}>
          <AutoWidthImage sourceUri={mail} height={23} />
        </TouchableOpacity>
      ) : null}

      <Popover
        isVisible={showPopover}
        popoverStyle={styles.menu}
        onRequestClose={() => setShowPopover(false)}
        from={
          <TouchableOpacity onPress={() => setShowPopover(true)}>
            <FiMoreVertical size={20} />
          </TouchableOpacity>
        }
      >
        <View
          style={styles.menu}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          <TouchableOpacity onPress={handleDownload} style={styles.menuItem}>
            <AutoWidthImage sourceUri={download} height={23} width={23} />
            <Text style={styles.menuText}>{t("common.download")}</Text>
          </TouchableOpacity>
        </View>
      </Popover>
    </View>
  );
};

export default KebabMenu;
