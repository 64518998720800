import { DecimalInput } from "@components/DecimalInput";
import { Button } from "@components/forms/Button";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { validateNumericNullableRequired, validateRequired } from "../../../utils/validations";
import { Concept, CreateSalesSuplidoConceptState } from "../types";

const styles = StyleSheet.create({
  line: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gap: 12,
  },
  gap: {
    gap: 8,
  },
});

type Props = {
  large?: boolean;
  concept: Concept;
  deleteDisabled: boolean;
  onChange: (concept: Concept) => void;
  onDelete: () => void;
};

export const RectificationSuplidoConceptItem = ({
  large = true,
  concept,
  deleteDisabled,
  onChange,
  onDelete,
}: Props) => {
  const { Field, listenFields, setFieldValue } = useForm<CreateSalesSuplidoConceptState>({
    id: { initialValue: concept.id },
    description: { initialValue: concept.description, validate: validateRequired },
    price: { initialValue: concept.price, validate: validateNumericNullableRequired },
  });
  const [priceAux, setPriceAux] = useState<string>(String(concept.price ?? ""));
  const [originalPrice] = useState<string>(String(concept.price ?? ""));

  const validateNegativePrice = (value: string, originalValue: string) => {
    const inputValue = parseFloat(value);
    const originalValueNegative = parseFloat(originalValue);
    let errorText = "";
    if (isNaN(inputValue)) {
      errorText = t("invoices.rectify.error.number");
    }

    if (inputValue > 0) {
      errorText = t("invoces.rectify.error.negative");
    }

    if (inputValue < originalValueNegative) {
      errorText = t("invoices.rectify.error.lessThan");
    }

    if (inputValue === 0) {
      errorText = t("invoices.rectify.error.zero");
    }

    return errorText;
  };

  useEffect(() => {
    if (!priceAux) {
      return;
    }

    setFieldValue("price", Number(priceAux));
  }, [priceAux]);

  useEffect(() => {
    const removeListener = listenFields(["description", "price"], values => {
      const data = {
        id: concept.id,
        description: values.description.value,
        price: values.price.value,
      };
      onChange(data as unknown as Concept);
    });
    return () => {
      removeListener();
    };
  }, [listenFields, onChange]);

  return (
    <View style={styles.line}>
      <Field name="id">{() => null}</Field>

      <Box
        direction={large ? "row" : "column"}
        alignItems={large ? "start" : "stretch"}
        justifyContent="spaceBetween"
        style={styles.grid}
      >
        <Field name="description">
          {({ value, onChange, error }) => {
            return (
              <LakeLabel
                label={t("invoices.descriptionSuplido")}
                style={styles.column}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    error={error}
                    onChangeText={onChange}
                    disabled={true}
                  />
                )}
              />
            );
          }}
        </Field>

        <Field name="price">
          {({ error }) => (
            <LakeLabel
              label={t("invoices.price")}
              style={styles.column}
              extra={() => <Text style={styles.red}>*</Text>}
              render={id => (
                <DecimalInput
                  id={id}
                  value={priceAux.toString()}
                  error={error}
                  onChangeDecimal={nextValue => {
                    const validationError = validateNegativePrice(nextValue, originalPrice);
                    if (validationError) {
                      showToast({ title: validationError, variant: "error", autoClose: true });
                    } else {
                      setPriceAux(nextValue);
                    }
                  }}
                />
              )}
            />
          )}
        </Field>
      </Box>

      <Space height={24} />

      <Box direction="row" justifyContent="end" style={styles.gap}>
        <Button
          onPress={onDelete}
          mode="tertiary"
          disabled={deleteDisabled}
          icon={<Icon name="delete-regular" size={16} />}
          reverse={true}
        >
          {t("invoices.removeItem")}
        </Button>
      </Box>
    </View>
  );
};
