import contactsIcon from "@assets/icons/contact.svg";
import hrIcon from "@assets/icons/hr.svg";
import invoicesIcon from "@assets/icons/invoice.svg";
import projectsIcon from "@assets/icons/project.svg";
import reportsIcon from "@assets/icons/reports.svg";
import summaryIcon from "@assets/icons/summary.svg";
import favicon from "@assets/img/favicon.svg";
import trexx from "@assets/img/trexx.svg";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { SidebarNavigationTracker } from "@swan-io/lake/src/components/SidebarNavigationTracker";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useUser } from "../features/context/UserContext";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../styles/constants";
import { env } from "../utils/env";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { checkRule } from "../utils/subscription";
import { NavigationItem } from "./NavigationItem";
import { UserNavigation } from "./UserNavigation";

const styles = StyleSheet.create({
  cursor: {
    cursor: "default",
  },
  sidebarMain: {
    backgroundColor: backgroundColorVariants.sidebar,
  },
  logo: {
    marginTop: 4,
    marginLeft: 4,
    marginBottom: 16,
  },
  logotext: {
    alignItems: "center",
    gap: 11,
    marginLeft: 4,
    marginBottom: 12,
  },
  sidebar: {
    position: "relative",
    flexGrow: 0,
    flexShrink: 0,
    minHeight: "100%",
  },
  sidebarContent: {
    display: "flex",
    gap: 16,
    paddingTop: 24,
    paddingHorizontal: 16,
    width: 72,
    borderRightWidth: 1,
    borderColor: borderColorVariants.neutral200,
    color: fontColorVariants.gray600,
    transitionDuration: "0.3s",
    transitionProperty: "width",
    height: "100%",
  },
  sidebarContentMobile: {
    paddingHorizontal: 8,
    width: 58,
  },
  openSidebar: {
    width: 220,
  },
});

export const MainSidebar = () => {
  const { user, company, subscription } = useUser();

  const { desktop } = useResponsive(breakpoints.medium);

  const [isOpen, setOpen] = useState(false);

  const onChange = useDebounce((value: boolean) => {
    setOpen(value);
  }, 300);

  if (!user || company?.constitution == null || subscription?.plan == null) {
    return null;
  }

  return (
    <Box direction="row" style={styles.sidebarMain}>
      <Pressable
        onHoverIn={() => onChange(desktop && true)}
        onHoverOut={() => onChange(false)}
        style={styles.cursor}
      >
        <SidebarNavigationTracker
          style={styles.sidebar}
          contentContainerStyle={[
            styles.sidebarContent,
            !desktop && styles.sidebarContentMobile,
            isOpen && styles.openSidebar,
          ]}
        >
          <Box direction="row" style={isOpen ? styles.logotext : styles.logo}>
            <AutoWidthImage ariaLabel="Logo Trexx" sourceUri={favicon} height={26} />

            {isOpen && <AutoWidthImage ariaLabel="Trexx" sourceUri={trexx} height={34} />}
          </Box>

          <View role="navigation">
            <NavigationItem
              to={Router.SummaryRoot()}
              renderIcon={<AutoWidthImage sourceUri={summaryIcon} height={24} />}
              label={t("common.summary")}
              onlyIcon={!isOpen}
              general={true}
            />

            <NavigationItem
              to={Router.CrmContactsList()}
              renderIcon={<AutoWidthImage sourceUri={contactsIcon} height={24} />}
              label={t("contacts")}
              onlyIcon={!isOpen}
              general={true}
            />

            <NavigationItem
              to={Router.InvoicesSalesList()}
              renderIcon={<AutoWidthImage sourceUri={invoicesIcon} height={24} />}
              label={t("invoices")}
              onlyIcon={!isOpen}
              general={true}
            />

            <NavigationItem
              to={Router.ReportsProfitAndLossRoot()}
              renderIcon={<AutoWidthImage sourceUri={reportsIcon} height={24} />}
              label={t("reports")}
              onlyIcon={!isOpen}
              general={true}
              isLocked={!checkRule(subscription, "has_p_and_g_type")}
            />

            <NavigationItem
              to={Router.AccountingList()}
              renderIcon={<AutoWidthImage sourceUri={summaryIcon} height={24} />}
              label={t("accounting")}
              onlyIcon={!isOpen}
              general={true}
            />

            <NavigationItem
              to={Router.HrPayrollsRoot()}
              renderIcon={<AutoWidthImage sourceUri={hrIcon} height={24} />}
              label={t("common.rrhh")}
              onlyIcon={!isOpen}
              general={true}
              isLocked={!checkRule(subscription, "has_hhrr")}
            />

            <NavigationItem
              to={Router.ProjectsRoot()}
              renderIcon={<AutoWidthImage sourceUri={projectsIcon} height={24} />}
              label={t("common.projects")}
              onlyIcon={!isOpen}
              general={true}
              isLocked={false}
            />

            <NavigationItem
              to={env.BANKING_URL}
              icon="building-bank-regular"
              label={t("common.banking")}
              onlyIcon={!isOpen}
              general={true}
              isLocked={!checkRule(subscription, "has_swan")}
            />
          </View>

          <UserNavigation open={isOpen} />
        </SidebarNavigationTracker>
      </Pressable>
    </Box>
  );
};
