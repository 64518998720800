import { Calendar } from "@components/Calendar";
import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { InputRounded } from "@components/forms/Input";
import { ProfitAndLossDocument, ProfitAndLossOutput } from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { ScrollView, StyleSheet, View } from "react-native";
import { useDateFilter } from "../../../hooks/useDateFilter";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";
import { Navigation } from "../components/Navigation";
import { ProfitAndLossAnalysis } from "../components/ProfitAndLossAnalysis";
import { ProfitAndLossReport } from "../components/ProfitAndLossReport";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  heading: {
    zIndex: 1,
  },
  filters: {
    gap: 16,
  },
});

export const ProfitAndLossArea = () => {
  const { desktop } = useResponsive();

  const { from, to } = useDateFilter();

  const { data: summaryData } = useUrqlQuery(
    {
      query: ProfitAndLossDocument,
      variables: {
        filters: {
          dateRange: { gte: from, lte: to },
        },
      },
    },
    [from, to],
  );

  const summary = summaryData
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.profitAndLoss))
    .getWithDefault({} as ProfitAndLossOutput);

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <Navigation />
            </Sidebar>
          )}

          <ScrollView>
            <View style={styles.root}>
              <View style={commonStyles.fill} role="main">
                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.header}
                >
                  <InputRounded>
                    <LakeSearchField
                      placeholder={t("common.search")}
                      initialValue={""}
                      onChangeText={() => {}}
                    />
                  </InputRounded>

                  <Box direction="row" style={styles.filters}>
                    <Calendar />
                  </Box>
                </Box>

                <ResponsiveContainer breakpoint={breakpoints.medium}>
                  {({ large }) => (
                    <Box
                      direction={large ? "row" : "column"}
                      alignItems="center"
                      justifyContent="spaceBetween"
                      style={styles.heading}
                    >
                      <Heading title={t("reports.profitAndLoss")} />

                      <View>
                        <Space height={12} />
                      </View>
                    </Box>
                  )}
                </ResponsiveContainer>

                <ProfitAndLossAnalysis summary={summary} />
                <ProfitAndLossReport summary={summary} />
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
