import { StyleSheet } from "react-native";
import { COLORS } from "../values/colors";
import { fontColorVariants } from "./constants";

export const common = StyleSheet.create({
  w100: {
    width: "100%",
  },
  h100: {
    height: "100%",
  },
  bgWhite: {
    backgroundColor: COLORS.WHITE,
  },
  bgPrimary50: {
    backgroundColor: COLORS.PRIMARY50,
  },
  underline: {
    textDecorationLine: "underline",
  },
  flexBasis50: {
    flexBasis: "50%",
  },
  weight500: {
    fontWeight: "500",
  },
  weight600: {
    fontWeight: "600",
  },
  weight700: {
    fontWeight: "700",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  error: {
    color: fontColorVariants.destructive500,
  },
  lowerCase: {
    textTransform: "lowercase",
  },
  hidden: {
    display: "none",
  },
  wrap: {
    flexWrap: "wrap",
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
