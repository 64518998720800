import { Calendar } from "@components/Calendar";
import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { InputRounded } from "@components/forms/Input";
import {
  AccountingEntriesDocument,
  AccountingStaticDocument,
  EntryRelay,
  Static,
} from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlPaginatedQuery, useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useMemo } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useDateFilter } from "../../../hooks/useDateFilter";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { extractQueryData } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { DetailsList } from "../components/AccountingList";
import { AccountingListFiltersState, ListFilter } from "../components/AccountingListFilters";
import { ImportWizard } from "../components/ImportWizard";
import { Navigation } from "../components/Navigation";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
  list: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
});

const NUM_TO_RENDER = 5;

export const Accounting = () => {
  const { desktop } = useResponsive();

  const { from, to } = useDateFilter();

  const route = Router.useRoute(["AccountingList"]);

  const params = match(route)
    .with({ name: "AccountingList" }, ({ params }) => params)
    .otherwise(() => {});

  const filters: AccountingListFiltersState = useMemo(() => {
    return {
      search: params?.search,
      type: params?.type,
    };
  }, [params]);

  const {
    data: query,
    nextData,
    reload,
  } = useUrqlPaginatedQuery(
    {
      query: AccountingEntriesDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          search: params?.search,
          date: { gte: from, lte: to },
          type: { inList: params?.type },
        },
      },
    },
    [filters],
  );

  const { data: staticData } = useUrqlQuery({ query: AccountingStaticDocument }, []);
  const types = extractQueryData(staticData, "value.value.accountingStatic.type") as Static[];

  const data = query
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ accountingEntries }) => Option.fromNullable(accountingEntries))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <Navigation />
            </Sidebar>
          )}

          <ScrollView>
            <View style={styles.root}>
              <View style={commonStyles.fill} role="main">
                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.header}
                >
                  <InputRounded>
                    <LakeSearchField
                      placeholder={t("common.search")}
                      initialValue={""}
                      onChangeText={search => Router.push("AccountingList", { search })}
                    />
                  </InputRounded>

                  <Box direction="row" justifyContent="end" alignItems="end">
                    <Calendar />
                    <Space width={8} />

                    <ListFilter
                      filters={filters}
                      types={types}
                      onChange={filters => Router.push("AccountingList", filters)}
                    />
                  </Box>
                </Box>

                <Heading title={t("accounting.ledger")} />

                <Box style={styles.list}>
                  <DetailsList
                    data={data as EntryRelay[]}
                    onActiveRowChange={() => {}}
                    onRefreshRequest={reload}
                    onEndReached={() => {}}
                    loading={{ isLoading: nextData.isLoading(), count: 20 }}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                  />
                </Box>
              </View>
            </View>
          </ScrollView>

          <LakeModal
            title={t("accounting.import.title")}
            visible={params?.import != null}
            maxWidth={1000}
            onPressClose={() => {
              Router.push("AccountingList");
              reload();
            }}
          >
            <ImportWizard
              onPressClose={() => {
                Router.push("AccountingList");
                reload();
              }}
            />
          </LakeModal>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
