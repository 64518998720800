import { createContext, useContext, useEffect } from "react";

type UserContextType = {
  user: User | null;
  company: Company | null;
  subscription: SubscriptionInfo | null;
  reload: () => void;
  reloadCompany: () => void;
};

import {
  Company,
  CompanyDocument,
  SubscriptionInfo,
  User,
  UserSubscriptionDocument,
} from "@graphql/crm";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ReactNode } from "react";
import { Router } from "../../utils/routes";
import { extractQueryData } from "../../utils/validations";

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const route = Router.useRoute(["SigninPage", "OnboardingPage", "PlansPage", "SummaryArea"]);

  const { data, reload } = useUrqlQuery({ query: UserSubscriptionDocument }, [route?.name]);

  const user = extractQueryData(data, "value.value.user") as User;
  const subscription = extractQueryData(data, "value.value.subscription") as SubscriptionInfo;

  const { data: companyData, reload: reloadCompany } = useUrqlQuery({ query: CompanyDocument }, [
    route?.name,
  ]);
  const company = extractQueryData(companyData, "value.value.company") as Company;

  useEffect(() => {
    if (route?.name == null || (route?.name === "SummaryArea" && user != null)) {
      return;
    }
    reload();
    reloadCompany();
  }, [route?.name]);

  useEffect(() => {
    if (company != null && company.constitution === null) {
      Router.replace("OnboardingPage");
      return;
    }
    if (subscription != null && subscription.plan === null) {
      Router.replace("PlansPage");
      return;
    }
  }, [company, subscription]);

  return (
    <UserContext.Provider value={{ user, company, subscription, reload, reloadCompany }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Hook must be used within a UserProvider");
  }
  return context;
};
