import { RightPanel } from "@components/RightPanel";
import { Button, ButtonGroup } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import Input from "@components/forms/Input";
import {
  CalculateInvoiceDocument,
  ConceptType,
  CreatePurchaseInvoiceDocument,
  InvoiceTotals,
  Issuer,
  NerPurchaseInvoice,
  PurchaseConcept,
  PurchaseConceptInput,
  PurchaseInvoiceCreateStaticDocument,
  PurchaseInvoiceRelay,
  SupplierRelay,
  SupplierRelayEdge,
  SuppliersDocument,
  UpdatePurchaseInvoiceDocument,
} from "@graphql/crm";
import { AsyncData, Result } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { RadioGroup } from "@swan-io/lake/src/components/RadioGroup";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { P, match } from "ts-pattern";
import { useNestedForm } from "../../../components/NestedForm";
import {
  Concept,
  CreateConceptState,
  CreatePurchaseConceptState,
  CreatePurchaseState,
  PurchaseConceptState,
} from "../../../features/invoices/types";
import { common } from "../../../styles/common";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { encodeDate, encodeDateISO, getToday } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { Router } from "../../../utils/routes";
import {
  extractQueryData,
  handlerErrors,
  tapError,
  validateHasItems,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { useInvoiceHistory } from "../hooks/useInvoiceHistory";
import { extractPurchaseStatic } from "../utils";
import { ExpirationDate } from "./ExpirationDate";
import { InvoiceTotal } from "./InvoiceTotal";
import { PurchaseConceptItem } from "./PurchaseConceptItem";
import { SupplierCreate } from "./SupplierCreate";
import { Tabs } from "./Tabs";

const styles = StyleSheet.create({
  contain: {
    backgroundColor: backgroundColorVariants.white,
    paddingHorizontal: 32,
    paddingVertical: 56,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    width: "96dvw",
    maxWidth: 900,
    marginBottom: 16,
  },
  title: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 24,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 24,
  },
  columnMin: {
    width: 300,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
    minWidth: 190,
  },
  gap: {
    gap: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
  newItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  newItemText: {
    fontSize: 14,
    fontWeight: "500",
    color: fontColorVariants.neutral800,
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
  },
  typeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

type Props = {
  invoice?: PurchaseInvoiceRelay;
  ner?: boolean;
  invoiceNer?: NerPurchaseInvoice;
  onRefreshRequest?: () => void;
};

export const PurchaseEditor = ({ invoice, onRefreshRequest, ner = false, invoiceNer }: Props) => {
  const { onSubmit, setMainForm } = useNestedForm();
  const getNewConcept = () => ({
    id: crypto.randomUUID(),
    description: "",
    price: 0,
    discountAmount: 0,
    discountPercentage: 0,
    expenseType: "corriente",
    type: "normal",
  });

  const conceptForm = () => {
    if (invoice === undefined) {
      return [getNewConcept()];
    }
  };

  const { Field, setFieldValue, getFieldState, submitForm, listenFields } =
    useForm<CreatePurchaseState>({
      issuerId: {
        initialValue: invoice?.issuer?.id !== undefined ? Number(invoice?.issuer.id) : undefined,
        validate: validateNumericNullableRequired,
      },
      seriesCurrent: { initialValue: invoice?.seriesCurrent ?? "", validate: validateRequired },
      issueDate: {
        initialValue: invoice?.issueDate != null ? encodeDateISO(invoice?.issueDate) : getToday(),
        validate: validateRequired,
      },
      expirationDate: {
        initialValue: invoice?.expirationDate != null ? encodeDateISO(invoice?.expirationDate) : "",
        validate: validateRequired,
        strategy: "onSubmit",
      },
      concepts: {
        initialValue: conceptForm() as PurchaseConcept[] | Concept[],
        validate: validateHasItems,
      },
      status: { initialValue: invoice?.status ?? "PENDING" },
      type: { initialValue: (invoice?.type as string) ?? null, validate: validateRequired },
      paymentMethod: { initialValue: invoice?.paymentMethod ?? "" },
      iban: { initialValue: invoice?.iban ?? "", validate: validateIban },
      notes: { initialValue: invoice?.notes ?? "" },
    });

  const [, calculateInvoice] = useUrqlMutation(CalculateInvoiceDocument);
  const [open, setOpen] = useState(false);
  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [totals, setTotals] = useState<InvoiceTotals>();
  const [currentId, setCurrentId] = useState<string>();

  const callCalculateInvoice = () => {
    const { value } = getFieldState("concepts");

    calculateInvoice({
      input: {
        lineItems: value.map(concept => ({
          description: concept.description,
          quantity: concept.quantity ?? 1,
          unitPrice: concept.unitPrice === "" ? undefined : concept.unitPrice,
          subtotal: concept.subtotal === "" ? undefined : concept.subtotal,
          discountPercentage: concept.discountPercentage,
          discountAmount: concept.discountAmount,
          vatRate: concept.vat,
          irpfPercentage: concept.irpf,
          total: concept.price,
          type: (concept.type?.toUpperCase() as ConceptType) ?? "NORMAL",
        })),
      },
    }).mapOk(data => {
      const lineItems = extractQueryData(data.calculateInvoice, "lineItems") as Concept[];

      let isCalculated = false;
      value.forEach((concept, index) => {
        const lineItem = lineItems[index];
        if (lineItem) {
          concept.total = lineItem.total as string;
          concept.discountAmount = lineItem.discountAmount;
          concept.irpfAmount = lineItem.irpfAmount as string;
          concept.vatAmount = lineItem.vatAmount as string;
          concept.taxBase = lineItem.taxBase as string;
          concept.price = Number(lineItem.total);

          if (concept.unitPrice != lineItem.unitPrice) {
            concept.unitPrice = lineItem.unitPrice as string;
            isCalculated = true;
          }
          if (concept.subtotal != lineItem.subtotal) {
            concept.subtotal = lineItem.subtotal as string;
            isCalculated = true;
          }
        }
      });
      if (isCalculated) {
        setFieldValue("concepts", [...value] as Concept[]);
      }

      const totals = extractQueryData(data.calculateInvoice, "totals") as InvoiceTotals;
      setTotals(totals);
    });
  };

  const refresh = useDebounce<void>(() => callCalculateInvoice(), 500);

  useEffect(() => {
    const removeResultsListener = listenFields(["concepts"], () => refresh());

    return () => removeResultsListener();
  }, [listenFields, refresh]);

  useEffect(() => {
    if (invoiceNer !== undefined) {
      reloadSuppliers();
      const totals = structuredClone(invoiceNer.totals);
      const concepts: CreateConceptState[] = [];
      invoiceNer?.lineItems.forEach(concept => {
        concepts.push({
          id: concept.id ?? "",
          description: concept.description ?? "",
          unitType: concept.unitType ?? "",
          unitPrice: concept.unitPrice as string,
          price: concept.price as number,
          vat: concept.vat ?? "",
          quantity: concept.quantity,
          irpf: concept.irpf ?? "",
          discountAmount: concept.discountAmount as number,
          discountPercentage: concept.discountPercentage as number,
          expenseType: concept.expenseType ?? "",
          type: concept.type ?? "",
          subtotal: concept.subtotal as number,
          category: concept.category ?? undefined,
          subcategory: concept.subcategory ?? "",
        });
      });
      setFieldValue(
        "issuerId",
        Boolean(invoiceNer.issuer) ? Number(invoiceNer.issuer?.id) : undefined,
      );
      setFieldValue("seriesCurrent", invoiceNer.invoice.seriesCurrent as string);
      setFieldValue("issueDate", invoiceNer.invoice.issueDate);
      setFieldValue("expirationDate", invoiceNer.invoice.expirationDate);
      setFieldValue("concepts", concepts as PurchaseConcept[]);
      setFieldValue("paymentMethod", invoiceNer.invoice.paymentMethod as string);
      setFieldValue("iban", invoiceNer.invoice.iban as string);
      setFieldValue("notes", invoiceNer.invoice.notes as string);
      setFieldValue("status", invoiceNer.invoice.status);
      setTotals(totals);
      invoiceNer.invoice.type = invoice?.type;
    }
  }, [invoiceNer]);

  useEffect(() => {
    setOpen(!getFieldState("type").value);
  }, [getFieldState("type").value]);

  const [showSupplierCreate, setShowSupplierCreate] = useState(false);
  const [suplier, setSupplier] = useState<SupplierRelay | undefined>();

  const { data: suppliersQuery, reload: reloadSuppliers } = useUrqlQuery(
    { query: SuppliersDocument, variables: { first: 100, filters: {} } },
    [],
  );
  const suppliers = extractQueryData(
    suppliersQuery,
    "value.value.suppliers.edges",
  ) as SupplierRelayEdge[];

  const { data } = useUrqlQuery({ query: PurchaseInvoiceCreateStaticDocument }, []);

  match(data).with(AsyncData.P.Done(Result.P.Error(P.select())), tapError);

  const paymentMethods = extractPurchaseStatic(data, "paymentMethods");
  const expirationDate = extractPurchaseStatic(data, "expirationDate");
  const statusType = extractPurchaseStatic(data, "statusType");

  const history = useInvoiceHistory({ purchaseInvoiceId: invoice?.id as string });

  const customer = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.purchaseInvoiceCreateStatic.customer)
    .getWithDefault({} as Issuer);

  useEffect(() => {
    if (invoice !== undefined) {
      reloadSuppliers();
      const concepts: CreatePurchaseConceptState[] = [];
      invoice?.concepts.forEach(concept => {
        concepts.push({
          id: concept.id ?? "",
          description: concept.description ?? "",
          unitType: concept.unitType ?? "",
          unitPrice: concept.unitPrice as string,
          price: Number(concept.price),
          vat: concept.vat ?? "",
          quantity: concept.quantity,
          irpf: concept.irpf ?? "",
          discountAmount: concept.discountAmount as number,
          discountPercentage: concept.discountPercentage as number,
          expenseType: concept.expenseType ?? "",
          subtotal: concept.subtotal as string,
          type: concept.type ?? "",
          category: concept?.category ?? undefined,
          subcategory: concept.subcategory ?? undefined,
          amortizationRate: Number(concept?.amortizationRate) ?? 0,
          usefulLife: concept?.usefulLife ?? 0,
        });
      });
      setFieldValue("issuerId", Boolean(invoice.issuer) ? Number(invoice.issuer?.id) : undefined);
      setFieldValue("seriesCurrent", invoice.seriesCurrent as string);
      setFieldValue("issueDate", encodeDateISO(invoice.issueDate as string));
      setFieldValue("expirationDate", encodeDateISO(invoice.expirationDate as string));
      setFieldValue("concepts", concepts as PurchaseConcept[]);
      setFieldValue("paymentMethod", invoice.paymentMethod as string);
      setFieldValue("iban", invoice.iban as string);
      setFieldValue("notes", invoice.notes as string);
      setFieldValue("status", invoice.status ?? "DRAFT");

      callCalculateInvoice();
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice?.id !== undefined && !ner) {
      callCalculateInvoice();
    }
  }, []);

  const handleNewConcept = () => {
    const newConcept = getNewConcept();

    const { value } = getFieldState("concepts");
    setCurrentId(newConcept.id);
    setFieldValue("concepts", [...value, newConcept] as PurchaseConcept[]);
  };

  const [, createInvoice] = useUrqlMutation(CreatePurchaseInvoiceDocument);
  const [, updateInvoice] = useUrqlMutation(UpdatePurchaseInvoiceDocument);

  const handleSubmit = () => {
    submitForm(values => {
      const input = {
        ...values,
        seriesCurrent: values.seriesCurrent,
        issuerId: Number(values.issuerId),
        issueDate: values.issueDate !== undefined ? encodeDate(values.issueDate) : "",
        expirationDate:
          values.expirationDate !== undefined ? encodeDate(values.expirationDate) : "",
        totalDiscount: totals?.totalDiscount ?? 0,
        totalVat: totals?.totalVat ?? 0,
        totalTaxBase: totals?.totalTaxBase ?? 0,
        totalIrpf: totals?.totalIrpf ?? 0,
        subtotal: totals?.subtotal ?? 0,
        total: totals?.total ?? 0,
      };
      if (invoice !== undefined || ner) {
        Object.assign(input, { id: ner ? invoiceNer?.invoice.id : invoice?.id });
        updateInvoice({
          input: {
            ...input,
            concepts: values.concepts?.map(concept => {
              if (concept.id.length === 36) {
                const { id, ...rest } = concept;
                return rest;
              }
              return concept;
            }) as PurchaseConceptInput[],
          },
        })
          .mapOk(data => {
            match(data.updatePurchaseInvoice)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesPurchaseList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      } else {
        createInvoice({
          input: {
            ...input,
            concepts: values.concepts?.map(concept => {
              const { id, ...rest } = concept;
              return rest;
            }) as PurchaseConceptInput[],
          },
        })
          .mapOk(data => {
            match(data.createPurchaseInvoice)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesPurchaseList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      }
    });
  };

  const [submit, setSubmit] = useState(false);

  const onClick = () => {
    setSubmit(true);
    setMainForm(() => handleSubmit);
  };

  useEffect(() => {
    if (submit) {
      onSubmit();
      setSubmit(false);
    }
  }, [submit]);

  return (
    <View>
      <Field name="type">{() => null}</Field>
      <Tabs history={history} status={statusType} onPress={() => setCurrentId("")} />
      <Space height={12} />

      <ResponsiveContainer breakpoint={breakpoints.medium}>
        {({ large }) => (
          <View style={styles.contain}>
            <View>
              <Box
                direction={large ? "row" : "column"}
                alignItems={large ? "end" : "stretch"}
                justifyContent="spaceBetween"
                style={styles.gap}
              >
                <View style={styles.columnMin}>
                  <Text style={styles.title}>{t("invoice")}</Text>

                  <LakeLabel
                    label={t("invoices.yourData")}
                    render={() => (
                      <>
                        <LakeText>{customer?.name}</LakeText>
                        <LakeText>{customer?.nif}</LakeText>
                        <LakeText>{customer?.address}</LakeText>
                      </>
                    )}
                  />

                  <Space height={32} />

                  <Field name="seriesCurrent">
                    {({ value, onChange, error }) => (
                      <LakeLabel
                        label={t("invoices.invoiceNumber")}
                        render={id => (
                          <LakeTextInput
                            id={id}
                            value={value}
                            onChangeText={onChange}
                            hideErrors={error === undefined}
                            error={error}
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Space height={20} />
                </View>

                <View style={large && styles.column}>
                  <Box
                    direction="row"
                    justifyContent="spaceBetween"
                    style={[styles.gap, common.wrap]}
                  >
                    <View style={styles.column}>
                      <Field name="issueDate">
                        {({ value, onChange, error }) => (
                          <DatePicker
                            format={locale.dateFormat}
                            firstWeekDay={locale.firstWeekday}
                            value={value}
                            onChange={value => {
                              onChange(value);
                              setFieldValue("expirationDate", "");
                            }}
                            label={t("invoices.issueDate") + "*"}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>

                    <View style={styles.column}>
                      <Field name="expirationDate">
                        {({ value, onChange, error }) => (
                          <ExpirationDate
                            label={t("invoices.dueDate") + "*"}
                            items={expirationDate}
                            forceCalendar={invoice?.expirationDate != null}
                            issueDate={getFieldState("issueDate").value}
                            value={value}
                            onChange={onChange}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>
                  </Box>

                  <Text style={styles.subtitle}>{t("invoices.supplierData")}</Text>

                  <Field name="issuerId">
                    {({ value, onChange, error }) => (
                      <LakeLabel
                        label={t("contact.supplier")}
                        render={() => (
                          <LakeSelect
                            items={
                              suppliers?.map(item => ({
                                name: `${item.node.name} ${item.node.nif != null ? ` (${item.node.nif})` : ""}`,
                                value: parseInt(item.node.id as string),
                              })) ?? []
                            }
                            value={value}
                            onValueChange={onChange}
                            hideErrors={error === undefined}
                            error={error}
                          />
                        )}
                        style={styles.actions}
                        actions={
                          <Box direction="row" justifyContent="spaceBetween">
                            <Pressable
                              style={styles.action}
                              onPress={() => {
                                setSupplier(undefined);
                                setShowSupplierCreate(true);
                              }}
                            >
                              <Icon
                                name="add-filled"
                                size={12}
                                color={fontColorVariants.neutral500}
                              />

                              <Text style={styles.actionText}>{t("invoices.newSupplier")}</Text>
                            </Pressable>

                            {value != null && (
                              <Pressable
                                style={styles.action}
                                onPress={() => {
                                  setSupplier(
                                    suppliers?.find(c => c.node.id === value.toString())?.node,
                                  );
                                  setShowSupplierCreate(true);
                                }}
                              >
                                <Icon
                                  name="edit-regular"
                                  size={12}
                                  color={fontColorVariants.neutral500}
                                />

                                <Text style={styles.actionText}>{t("invoices.editSupplier")}</Text>
                              </Pressable>
                            )}
                          </Box>
                        }
                      />
                    )}
                  </Field>

                  {showSupplierCreate && (
                    <SupplierCreate
                      visible={showSupplierCreate}
                      supplier={suplier}
                      onClose={() => {
                        reloadSuppliers();
                        setShowSupplierCreate(false);
                      }}
                    />
                  )}
                </View>
              </Box>

              <Space height={32} />

              <Field name="concepts">
                {({ value, error }) => (
                  <Box style={styles.gap}>
                    {value?.map(concept => (
                      <PurchaseConceptItem
                        key={concept.id}
                        invoiceType={getFieldState("type").value}
                        isActive={currentId === concept.id}
                        setCurrentId={() => setCurrentId(concept.id)}
                        concept={concept as PurchaseConceptState}
                        concepts={value as PurchaseConceptState[]}
                        onChange={concept => {
                          const { value } = getFieldState("concepts");
                          setFieldValue(
                            "concepts",
                            value.map(c =>
                              c.id === concept.id ? { ...c, ...concept } : c,
                            ) as PurchaseConcept[],
                          );
                        }}
                        onDelete={() => {
                          const { value } = getFieldState("concepts");
                          setFieldValue(
                            "concepts",
                            value.filter(c => c.id !== concept.id) as PurchaseConcept[],
                          );
                        }}
                      />
                    ))}

                    <Pressable style={styles.newItem} onPress={handleNewConcept}>
                      <Icon
                        name="add-circle-regular"
                        size={20}
                        color={fontColorVariants.neutral800}
                      />

                      <Text style={styles.newItemText}>{t("invoices.addNewItem")}</Text>
                    </Pressable>

                    <Text style={styles.red}>{error}</Text>
                  </Box>
                )}
              </Field>

              <Field name="notes">
                {Input({
                  label: t("invoices.notes"),
                })}
              </Field>

              <Space height={24} />

              <Box
                direction="row"
                justifyContent="spaceBetween"
                alignItems="end"
                style={styles.gap}
              >
                <Box direction="column" style={[styles.column, styles.gap]}>
                  <Field name="paymentMethod">
                    {({ value, onChange, error, ref }) => (
                      <LakeLabel
                        label={t("contact.paymentMethod")}
                        render={id => (
                          <LakeSelect
                            id={id}
                            ref={ref}
                            value={value}
                            hideErrors={error === undefined}
                            error={error}
                            items={paymentMethods}
                            onValueChange={value => {
                              onChange(value);
                              setPaymentMethodType(value);
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>

                  {["transferencia", "domiciliacion"].includes(paymentMethodType) && (
                    <LakeLabel
                      label={t("contact.iban")}
                      render={id => (
                        <Field name="iban">
                          {({ value, onChange, onBlur, error, validating, ref }) => (
                            <LakeTextInput
                              id={id}
                              ref={ref}
                              value={printIbanFormat(value)}
                              validating={validating}
                              hideErrors={error === undefined}
                              error={error}
                              onChangeText={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        </Field>
                      )}
                    />
                  )}
                </Box>

                <InvoiceTotal totals={totals} />
              </Box>
            </View>
          </View>
        )}
      </ResponsiveContainer>

      <Box direction="row" justifyContent="end">
        <Button
          mode="tertiary"
          size="large"
          disabled={false}
          onPress={() => Router.push("InvoicesPurchaseList", { visible: undefined })}
        >
          {t("common.cancel")}
        </Button>

        <ButtonGroup transparent={false}>
          <Button
            style="group"
            size="large"
            disabled={false}
            icon={<SaveIcon />}
            reverse={true}
            onPress={onClick}
          >
            {t("common.save")}
          </Button>
        </ButtonGroup>
      </Box>

      <RightPanel visible={open} overlay={true}>
        <View style={styles.rightpanel}>
          <View style={styles.typeContainer}>
            <LakeLabel
              label={t("invoice.type")}
              render={() => (
                <Field name="type">
                  {({ value, onChange }) => (
                    <RadioGroup
                      items={[
                        { name: t("invoice.current"), value: "current" },
                        { name: t("invoice.nonCurrent"), value: "non_current" },
                      ]}
                      value={value}
                      direction="row"
                      onValueChange={value => {
                        setOpen(false);
                        onChange(value);
                      }}
                    />
                  )}
                </Field>
              )}
            />
          </View>
        </View>
      </RightPanel>
    </View>
  );
};
