import { FullViewportLayer } from "@swan-io/lake/src/components/FullViewportLayer";
import { ScrollView, StyleSheet, View } from "react-native";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import useGetInvoiceData from "../hooks/useGetInvoiceData";
import { RectificationEditor } from "./RectificationEditor";

const styles = StyleSheet.create({
  gradient1: {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translate(-60%, -80%)",
    opacity: 0.9,
    width: "50%",
    height: "70%",
    backgroundImage:
      "radial-gradient(33.64% 25.02% at 50% 50%, rgba(89, 226, 255, 0.22) 0%, rgba(108, 168, 243, 0.00) 100%)",
  },
  gradient2: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(0, -30%)",
    width: "50%",
    height: "70%",
    backgroundImage:
      "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.15) 0%, rgba(245, 228, 255, 0.00) 100%)",
  },
  background: {
    position: "absolute",
    bottom: 0,
    backgroundColor: COLORS.WHITE,
    width: "100dvw",
    height: "50dvh",
  },
  container: {
    backgroundImage: COLORS.BACKGROUND,
  },
  grid: {
    display: "grid",
    placeContent: "center",
    width: "100dvw",
    minHeight: "100dvh",
    paddingVertical: 16,
    transitionProperty: "transform",
    transitionDuration: "500ms",
    transitionTimingFunction: "ease-in-out",
  },
});

type Props = {
  visible?: boolean;
  onRefreshRequest?: () => void;
};

export const RectificationCreate = ({ visible = false, onRefreshRequest }: Props) => {
  const route = Router.useRoute(["InvoicesSalesRectify", "InvoicesSalesRectifyDetail"]);

  const invoice = useGetInvoiceData(route?.params);

  return (
    <FullViewportLayer visible={visible}>
      <ScrollView style={styles.container}>
        <View style={styles.gradient1} />
        <View style={styles.gradient2} />
        <View style={styles.background} />

        <View style={styles.grid}>
          {invoice != null && (
            <RectificationEditor invoice={invoice} onRefreshRequest={onRefreshRequest} />
          )}
        </View>
      </ScrollView>
    </FullViewportLayer>
  );
};
