import { Form } from "@swan-io/lake/src/components/Form";
import { Space } from "@swan-io/lake/src/components/Space";
import { UploadArea } from "@swan-io/shared-business/src/components/UploadArea";
import { Form as FormType } from "react-ux-form";
import { match } from "ts-pattern";
import { FormValues } from "../features/contacts/components/ImportWizard";
import { t } from "../utils/i18n";

type Props = {
  onRemoveDocument: (fileId: string) => void;
  handleUpload: (files: File[]) => void;
  setFinishedStatus: (filename: string, error?: string) => void;
  Field: FormType<FormValues>["Field"];
};

const ACCEPTED_FORMATS = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

const MAX_SUPPORTING_DOCUMENT_UPLOAD_SIZE_MB = 5;

export const ImportStepUpload = ({
  onRemoveDocument,
  handleUpload,
  setFinishedStatus,
  Field,
}: Props) => {
  return (
    <>
      <Space height={48} />

      <Form>
        <Field name="file">
          {({ value, onChange, error }) => (
            <UploadArea
              layout="vertical"
              error={error}
              onDropAccepted={files => {
                onChange([...value, { id: "NO_ID_YET", status: "uploading", progress: 0 }]);
                handleUpload(files);
              }}
              onDropRejected={files => {
                onChange([...value, { id: "NO_ID_YET", status: "uploading", progress: 0 }]);
                if (files.length > 0 && files[0]?.file) {
                  const message = match(files[0].errors?.[0]?.code)
                    .with("file-invalid-type", () => t("import.invalidDocumentType"))
                    .with("file-too-large", () =>
                      t("import.hugeDocument", {
                        maxSizeMB: MAX_SUPPORTING_DOCUMENT_UPLOAD_SIZE_MB,
                      }),
                    )
                    .otherwise(() => files[0]?.errors?.[0]?.message);
                  setFinishedStatus(files[0].file.name, message);
                }
              }}
              documents={value}
              accept={ACCEPTED_FORMATS}
              icon="arrow-upload-regular"
              description={t("import.documentTypes", {
                maxSizeMB: MAX_SUPPORTING_DOCUMENT_UPLOAD_SIZE_MB,
              })}
              maxSize={MAX_SUPPORTING_DOCUMENT_UPLOAD_SIZE_MB * 1000 * 1000}
              onRemoveDocument={onRemoveDocument}
            />
          )}
        </Field>
      </Form>

      <Space height={48} />
    </>
  );
};
