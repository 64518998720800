import { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FiMoreVertical } from "react-icons/fi";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Popover from "react-native-popover-view";
import { borderColorVariants } from "../../../../styles/constants";
import { t } from "../../../../utils/i18n";
import { Router } from "../../../../utils/routes";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: 4,
    width: "100%",
  },
  menu: {
    padding: 8,
    borderRadius: 8,
  },
  menuItem: {
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
  },
  menuText: {
    marginLeft: 8,
  },
});

type KebabMenuProps = {
  employeeId: string;
  setShowAssignmentModal: (show: boolean) => void;
  setEmployeeAssignmentId: (employeeId: string) => void;
};

const KebabMenu = ({
  employeeId,
  setShowAssignmentModal,
  setEmployeeAssignmentId,
}: KebabMenuProps) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleCreatePayroll = () => {
    Router.push("HrPayrollsCreate", { employeeId });
  };

  const handleEmployeeAssignment = () => {
    setEmployeeAssignmentId(employeeId);
    setShowAssignmentModal(true);
    setShowPopover(false);
  };

  return (
    <View
      style={styles.container}
      onTouchEnd={e => {
        e.stopPropagation();
      }}
    >
      <Popover
        isVisible={showPopover}
        popoverStyle={styles.menu}
        onRequestClose={() => setShowPopover(false)}
        from={
          <TouchableOpacity onPress={() => setShowPopover(true)}>
            <FiMoreVertical size={20} />
          </TouchableOpacity>
        }
      >
        <View
          style={styles.menu}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          <TouchableOpacity onPress={handleCreatePayroll} style={styles.menuItem}>
            <FaPlus size={14} />
            <Text style={styles.menuText}>{t("hr.createPayroll")}</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={handleEmployeeAssignment} style={styles.menuItem}>
            <FaPlus size={14} />
            <Text style={styles.menuText}>{t("project.assign")}</Text>
          </TouchableOpacity>
        </View>
      </Popover>
    </View>
  );
};

export default KebabMenu;
