import trash from "@assets/icons/delete.svg";
import edit from "@assets/icons/edit.svg";
import { DeletePurchaseInvoiceDocument, PurchaseInvoiceRelay } from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Popover from "react-native-popover-view";
import { match } from "ts-pattern";
import { borderColorVariants } from "../../../styles/constants";
import { isDateInCurrentQuarter } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { errorHandling } from "../../../utils/validations";
import { useLoading } from "../../context/LoadingContext";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: 4,
    width: "100%",
  },
  menu: {
    padding: 8,
    borderRadius: 8,
  },
  menuItem: {
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
  },
  menuText: {
    marginLeft: 8,
  },
});

type KebabMenuProps = {
  invoice: PurchaseInvoiceRelay;
  onRefreshRequest: () => void;
  setShowAssignmentModal: (show: boolean) => void;
  setInvoice: (invoice: PurchaseInvoiceRelay) => void;
};

const KebabMenuPurchase = ({
  invoice,
  onRefreshRequest,
  setInvoice,
  setShowAssignmentModal,
}: KebabMenuProps) => {
  const { setLoading } = useLoading();
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [, deleteInvoice] = useUrqlMutation(DeletePurchaseInvoiceDocument);

  const handleDelete = () => {
    setLoading(true);
    deleteInvoice({ id: invoice.id as string }).mapOk(data => {
      setLoading(false);
      match(data.deletePurchaseInvoice)
        .with({ __typename: "DeleteOutput" }, _ => {
          showToast({
            title: t("invoice.deleted"),
            variant: "success",
            autoClose: true,
          });
          onRefreshRequest();
        })
        .with({ __typename: "OperationInfo" }, errorHandling);
    });
  };

  const handleAssignProject = () => {
    setInvoice(invoice);
    setShowAssignmentModal(true);
    setShowPopover(false);
  };

  const canDelete =
    isDateInCurrentQuarter(invoice?.issueDate as string) || ["PENDING"].includes(invoice.status);

  return (
    <View
      style={styles.container}
      onTouchEnd={e => {
        e.stopPropagation();
      }}
    >
      <Popover
        isVisible={showPopover}
        popoverStyle={styles.menu}
        onRequestClose={() => setShowPopover(false)}
        from={
          <TouchableOpacity onPress={() => setShowPopover(true)}>
            <FiMoreVertical size={20} />
          </TouchableOpacity>
        }
      >
        <View
          style={styles.menu}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          {canDelete && (
            <TouchableOpacity onPress={handleDelete} style={styles.menuItem}>
              <AutoWidthImage sourceUri={trash} height={23} width={23} />
              <Text style={styles.menuText}>{t("common.delete")}</Text>
            </TouchableOpacity>
          )}

          <TouchableOpacity onPress={handleAssignProject} style={styles.menuItem}>
            <AutoWidthImage sourceUri={edit} height={23} width={23} />
            <Text style={styles.menuText}>{t("project.assign")}</Text>
          </TouchableOpacity>
        </View>
      </Popover>
    </View>
  );
};

export default KebabMenuPurchase;
