import { NavigationItem } from "@components/NavigationItem";
import { NavigationSubitem } from "@components/NavigationSubitem";
import { Space } from "@swan-io/lake/src/components/Space";
import { FaArrowUp } from "react-icons/fa6";
import { View } from "react-native";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { checkRule } from "../../../utils/subscription";
import { useUser } from "../../context/UserContext";

export const Navigation = () => {
  const { subscription } = useUser();
  const route = Router.useRoute(["HrEmployeesRoot"]);

  return (
    <View role="navigation">
      <NavigationItem
        to={Router.HrPayrollsRoot()}
        label={t("common.payrolls")}
        isLocked={!checkRule(subscription, "has_hhrr")}
      />

      <NavigationItem
        to={Router.HrEmployeesRoot()}
        label={t("common.employees")}
        isLocked={!checkRule(subscription, "has_hhrr")}
      />

      {match(route)
        .with({ name: "HrEmployeesRoot" }, () => (
          <>
            <NavigationSubitem
              to={Router.HrEmployeesRoot({ create: "" })}
              label={t("hr.newEmployee")}
              icon="add-circle-regular"
              isLocked={!checkRule(subscription, "has_hhrr")}
            />

            <NavigationSubitem
              to={Router.HrEmployeesRoot({ import: "" })}
              renderIcon={<FaArrowUp />}
              label={t("common.import")}
            />

            <Space height={12} />
          </>
        ))
        .otherwise(() => null)}
    </View>
  );
};
