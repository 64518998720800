import { ErrorView } from "@components/ErrorView";
import { Heading } from "@components/Heading";
import { InputRounded } from "@components/forms/Input";
import { ContactsDocument } from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { FocusTrapRef } from "@swan-io/lake/src/components/FocusTrap";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ListRightPanel } from "@swan-io/lake/src/components/ListRightPanel";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useUrqlPaginatedQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useCallback, useMemo, useRef, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { Create } from "./Create";
import { Detail } from "./Detail";
import { DetailsList } from "./DetailsList";
import { ImportWizard } from "./ImportWizard";
import { ContactFiltersState, ContactType } from "./ListFilter";

const styles = StyleSheet.create({
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  container: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
});

const NUM_TO_RENDER = 7;

type Props = {
  create?: string;
  importVisible: boolean;
  search?: string;
  type?: ContactType[];
};

export const List = ({ create, importVisible, search, type }: Props) => {
  const filters: ContactFiltersState = useMemo(() => {
    return { type, search } as const;
  }, [type, search]);

  const { data, nextData, reload, setAfter } = useUrqlPaginatedQuery(
    {
      query: ContactsDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          type: { inList: type },
          search: search ?? "",
        },
      },
    },
    [filters],
  );

  const contacts = data
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ contacts }) => Option.fromNullable(contacts))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const [activeContactId, setActiveContactId] = useState<string | null>(null);

  const panelRef = useRef<FocusTrapRef | null>(null);

  const onActiveRowChange = useCallback((element: HTMLElement) => {
    return panelRef.current?.setInitiallyFocusedElement(element);
  }, []);

  const onClose = () => {
    setActiveContactId(null);
    reload();
  };

  return (
    <>
      <View style={commonStyles.fill} role="main">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          style={styles.header}
        >
          <InputRounded>
            <LakeSearchField
              placeholder={t("common.search")}
              initialValue={filters.search ?? ""}
              onChangeText={search => Router.push("CrmContactsList", { ...filters, search })}
            />
          </InputRounded>
        </Box>

        <Heading
          title={match(type)
            .with(["customer"], () => t("common.customers"))
            .with(["supplier"], () => t("common.suppliers"))
            .otherwise(() => t("contacts"))}
        />

        <Box style={styles.container}>
          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: data => (
                  <DetailsList
                    contacts={contacts}
                    onRefreshRequest={reload}
                    onActiveRowChange={onActiveRowChange}
                    activeRowId={activeContactId ?? undefined}
                    onEndReached={() => {
                      if (data?.contacts.pageInfo.hasNextPage === true) {
                        setAfter(data?.contacts?.pageInfo.endCursor ?? undefined);
                      }
                    }}
                    loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER }}
                    getRowLink={({ item }) => (
                      <Pressable onPress={() => setActiveContactId(item.id as string)} />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                  />
                ),
              }),
          })}
        </Box>
      </View>

      <Create
        visibleParam={create}
        onRefreshRequest={() => {
          reload();
          onClose();
        }}
      />

      <ListRightPanel
        ref={panelRef}
        keyExtractor={item => item.id as string}
        activeId={activeContactId}
        onActiveIdChange={setActiveContactId}
        onClose={onClose}
        items={contacts}
        render={(contact, large) => <Detail large={large} contact={contact} onClose={onClose} />}
        closeLabel={t("common.closeButton")}
        previousLabel={t("common.next")}
        nextLabel={t("common.back")}
      />

      <LakeModal
        title={t("contact.import.title")}
        visible={importVisible}
        maxWidth={1000}
        onPressClose={() => {
          Router.push("CrmContactsList");
          reload();
        }}
      >
        <ImportWizard
          onPressClose={() => {
            Router.push("CrmContactsList");
            reload();
          }}
        />
      </LakeModal>
    </>
  );
};
