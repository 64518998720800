import {
  Model111Output,
  Model115Output,
  Model130Output,
  Model303Output,
  TaxesModelsDocument,
} from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { common } from "../styles/common";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../styles/constants";
import { formatCurrency, t } from "../utils/i18n";
import { CloseIcon } from "../utils/icons";
import { extractQueryData } from "../utils/validations";
import { RightPanel } from "./RightPanel";
import { SummaryModel } from "./SummaryModel";

const styles = StyleSheet.create({
  box: {
    borderRadius: 25,
    boxShadow:
      "0 67.609px 54.087px 0 rgba(201, 201, 201, 0.07), 0 28.246px 22.596px 0 rgba(108, 73, 172, 0.05), 0 15.101px 12.081px 0 rgba(108, 73, 172, 0.04), 0 8.466px 6.773px 0 rgba(108, 73, 172, 0.04), 0 4.496px 3.597px 0 rgba(108, 73, 172, 0.03), 0 1.871px 1.497px 0 rgba(108, 73, 172, 0.02)",
    paddingVertical: 24,
    paddingHorizontal: 18,
    backgroundColor: backgroundColorVariants.dark,
    color: fontColorVariants.white,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "500",
    color: fontColorVariants.white,
    marginBottom: 32,
  },
  rightpanel: {
    padding: 24,
    width: 620,
    maxWidth: "100vw",
  },
  model: {
    fontSize: 20,
  },
  explain: {
    fontSize: 12,
  },
  detail: {
    backgroundColor: backgroundColorVariants.neutral100,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral300,
    borderRadius: 8,
    paddingVertical: 24,
    paddingHorizontal: 20,
    gap: 16,
  },
  header: {
    fontSize: 12,
    fontWeight: "600",
  },
  row2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 8,
  },
  row3: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr",
    gap: 8,
  },
  row4: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1fr 1fr",
    gap: 8,
  },
  lastItem: {
    borderRightWidth: 0,
  },
});

type Props = {
  from?: string;
  to?: string;
};

export const Taxes = ({ from, to }: Props) => {
  const [model, setModel] = useState<
    Model111Output | Model130Output | Model303Output | Model115Output | null
  >(null);

  const { data } = useUrqlQuery(
    {
      query: TaxesModelsDocument,
      variables: {
        filters: {
          dateRange: { gte: from, lte: to },
        },
      },
    },
    [from, to],
  );

  const model303 = extractQueryData(data, "value.value.getModel303") as Model303Output;
  const model130 = extractQueryData(data, "value.value.getModel130") as Model130Output;
  const model111 = extractQueryData(data, "value.value.getModel111") as Model111Output;
  const model115 = extractQueryData(data, "value.value.getModel115") as Model115Output;

  return (
    <Box style={styles.box}>
      <Text style={styles.title}>{t("taxes.taxForecast")}</Text>

      <Box style={styles.grid}>
        <SummaryModel
          title={t("taxes.vatToLiquidate")}
          subtitle={t("taxes.modelNumber", { model: 303 })}
          total={model303?.liquidation as number}
          text={""}
          onPress={() => setModel(model303)}
        />

        <SummaryModel
          title={t("taxes.irpfToLiquidate")}
          subtitle={t("taxes.modelNumber", { model: 130 })}
          total={model130?.result as number}
          text={""}
          onPress={() => setModel(model130)}
        />

        <SummaryModel
          title={t("taxes.retentions")}
          subtitle={t("taxes.modelNumber", { model: 111 })}
          total={model111?.total as number}
          text={""}
          onPress={() => setModel(model111)}
        />

        <SummaryModel
          title={t("taxes.retentionsPerRent")}
          subtitle={t("taxes.modelNumber", { model: 115 })}
          total={model115?.total as number}
          text={""}
          onPress={() => setModel(model115)}
          style={styles.lastItem}
        />

        <RightPanel
          visible={model != null}
          overlay={true}
          onPressClose={() => setModel(null)}
          style={styles.rightpanel}
        >
          <View style={styles.rightpanel}>
            <Pressable onPress={() => setModel(null)}>
              <CloseIcon />
            </Pressable>

            <Space height={24} />

            {model?.__typename === "Model303Output" && (
              <>
                <Text style={styles.model}>{t("taxes.modelNumber", { model: 303 })}</Text>
                <Space height={20} />
                <Text style={styles.explain}>{t("taxes.model303Explain")}</Text>
                <Space height={24} />

                <View style={styles.detail}>
                  <Box style={styles.row3}>
                    <Text></Text>
                    <Text style={[styles.header, common.textRight]}>{t("taxes.base")}</Text>
                    <Text style={[styles.header, common.textRight]}>{t("taxes.quota")}</Text>
                  </Box>

                  <Box style={styles.row3}>
                    <Text>{t("taxes.vatCharged")}</Text>
                    <Amount value={model?.totalChargedBase as number} />
                    <Amount value={model?.totalChargedAmount as number} />
                  </Box>

                  <Box style={styles.row3}>
                    <Text>{t("taxes.vatSupported")}</Text>
                    <Amount value={model?.totalSupportedBase as number} />
                    <Amount value={model?.totalSupportedAmount as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text style={common.weight600}>{t("taxes.liquidationToPay")}</Text>

                    <Text style={[common.textRight, common.weight600]}>
                      {formatCurrency(model?.liquidation as number, "EUR")}
                    </Text>
                  </Box>
                </View>
              </>
            )}

            {model?.__typename === "Model111Output" && (
              <>
                <Text style={styles.model}>{t("taxes.modelNumber", { model: 111 })}</Text>
                <Space height={20} />
                <Text style={styles.explain}>{t("taxes.model111Explain")}</Text>
                <Space height={24} />

                <View style={styles.detail}>
                  <Box style={styles.row4}>
                    <Text></Text>
                    <Text style={[styles.header, common.textCenter]}>{t("taxes.recipients")}</Text>
                    <Text style={[styles.header, common.textRight]}>{t("taxes.amount")}</Text>
                    <Text style={[styles.header, common.textRight]}>{t("taxes.retention")}</Text>
                  </Box>

                  <Box style={styles.row4}>
                    <Text>I. {t("taxes.withholdingTaxes")}</Text>
                    <Text style={common.textCenter}>{model?.retentions?.recipients}</Text>
                    <Amount value={model?.retentions?.base as number} />
                    <Amount value={model?.retentions?.total as number} />
                  </Box>

                  <Box style={styles.row4}>
                    <Text>II. {t("taxes.incomeActivities")}</Text>
                    <Text style={common.textCenter}>{model?.activities?.recipients}</Text>
                    <Amount value={model?.activities?.base as number} />
                    <Amount value={model?.activities?.total as number} />
                  </Box>

                  <Box style={styles.row4}>
                    <Text>III. {t("taxes.prizesRaffles")}</Text>
                    <Text style={common.textCenter}>{0}</Text>
                    <Amount value={0} />
                    <Amount value={0} />
                  </Box>

                  <Box style={styles.row4}>
                    <Text>IV. {t("taxes.capitalGains")}</Text>
                    <Text style={common.textCenter}>{0}</Text>
                    <Amount value={0} />
                    <Amount value={0} />
                  </Box>

                  <Box style={styles.row4}>
                    <Text>V. {t("taxes.assignmentRights")}</Text>
                    <Text style={common.textCenter}>{0}</Text>
                    <Amount value={0} />
                    <Amount value={0} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text style={common.weight600}>{t("taxes.totalLiquidation")}</Text>

                    <Text style={[common.textRight, common.weight600]}>
                      {formatCurrency(model?.liquidation as number, "EUR")}
                    </Text>
                  </Box>
                </View>
              </>
            )}

            {model?.__typename === "Model130Output" && (
              <>
                <Text style={styles.model}>{t("taxes.modelNumber", { model: 130 })}</Text>
                <Space height={20} />
                <Text style={styles.explain}>{t("taxes.model130Explain")}</Text>
                <Space height={24} />

                <View style={styles.detail}>
                  <Text style={styles.header}>1. {t("taxes.titleAct1")}</Text>

                  <Box style={styles.row2}>
                    <Text>a) {t("taxes.computableIncome")}</Text>
                    <Amount value={model?.act1ComputableIncome as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>b) {t("taxes.taxDeductibleExpenses")}</Text>
                    <Amount value={model?.act1TaxDeductibleExpenses as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>c) {t("taxes.netYield")}</Text>
                    <Amount value={model?.act1NetYield as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>d) {t("taxes.paymentAccount")}</Text>
                    <Amount value={model?.act1TwentyPercent as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>e) {t("taxes.deductions")}</Text>
                    <Amount value={model?.act1WithholdingsPaymentsAccount as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>{t("taxes.fraccionatedPayment")}</Text>
                    <Amount value={model?.act1QuarterlyPrepayment as number} />
                  </Box>

                  <Text style={styles.header}>2. {t("taxes.titleAct2")}</Text>

                  <Box style={styles.row2}>
                    <Text>a) {t("taxes.revenueVolume")}</Text>
                    <Amount value={model?.act2RevenueQuarter as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>b) {t("taxes.paymentAccount")}</Text>
                    <Amount value={model?.act2TwoPercent as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>c) {t("taxes.deductions")}</Text>
                    <Amount value={model?.act1WithholdingsPaymentsAccount as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text>{t("taxes.fraccionatedPayment")}</Text>
                    <Amount value={model?.act2QuarterlyPrepayment as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text style={common.weight600}>{t("taxes.liquidationToPay")}</Text>

                    <Text style={[common.textRight, common.weight600]}>
                      {formatCurrency(model?.total as number, "EUR")}
                    </Text>
                  </Box>
                </View>
              </>
            )}

            {model?.__typename === "Model115Output" && (
              <>
                <Text style={styles.model}>{t("taxes.modelNumber", { model: 115 })}</Text>
                <Space height={20} />
                <Text style={styles.explain}>{t("taxes.model115Explain")}</Text>
                <Space height={24} />

                <View style={styles.detail}>
                  <Box style={styles.row4}>
                    <Text></Text>
                    <Text style={[styles.header, common.textCenter]}>{t("taxes.recipients")}</Text>
                    <Text style={[styles.header, common.textRight]}>{t("taxes.amount")}</Text>
                    <Text style={[styles.header, common.textRight]}>{t("taxes.retention")}</Text>
                  </Box>

                  <Box style={styles.row4}>
                    <Text>{t("taxes.withholdingsPrepayments")}</Text>
                    <Text style={common.textCenter}>{model?.recipients || 0}</Text>
                    <Amount value={model?.basisWithholdingsPaymentsAccount as number} />
                    <Amount value={model?.withholdingsPrepayments as number} />
                  </Box>

                  <Box style={styles.row2}>
                    <Text style={common.weight600}>{t("taxes.totalLiquidation")}</Text>

                    <Text style={[common.textRight, common.weight600]}>
                      {formatCurrency(model?.total as number, "EUR")}
                    </Text>
                  </Box>
                </View>
              </>
            )}
          </View>
        </RightPanel>
      </Box>
    </Box>
  );
};

const Amount = ({ value = 0 }: { value: number }) => (
  <Text style={common.textRight}>{formatCurrency(value, "EUR")}</Text>
);
