export const borderColorVariants = {
  primary: "rgb(229, 231, 235)",
  primary200: "var(--color-current-200)",
  primary500: "var(--color-current-500)",
  secondary: "#6c757d",
  success: "#198754",
  danger: "#dc3545",
  warning: "#ffc107",
  info: "#0dcaf0",
  light: "#f8f9fa",
  dark: "#212529",
  white: "#fff",
  gray100: "#E8E7E8",
  gray200: "#C7C7C8",
  gray300: "#abaaad",
  gray400: "#8F8E91",
  gray500: "#737276",
  neutral200: "#E5E7EB",
  neutral300: "#D1D5DB",
  neutral500: "#6B7280",
  neutral400: "#9CA3AF",
  neutral600: "#5C5A5F",
  transparent: "transparent",
  blue: "#007BCA",
};

export const fontColorVariants = {
  primary: "rgb(229, 231, 235)",
  primary50: "var(--color-current-50)",
  primary100: "var(--color-current-100)",
  primary200: "var(--color-current-200)",
  primary300: "var(--color-current-300)",
  primary500: "var(--color-current-500)",
  neutral50: "#F9FAFB",
  neutral100: "#F3F4F6",
  neutral200: "#E5E7EB",
  neutral300: "#D1D5DB",
  neutral400: "#9CA3AF",
  neutral500: "#6B7280",
  neutral600: "#5C5A5F",
  neutral700: "#374151",
  neutral800: "#1F2937",
  neutral900: "#111827",
  gray100: "#E8E7E8",
  gray400: "#8F8E91",
  gray500: "#737276",
  gray600: "#5C5A5F",
  gray700: "#262626",
  gray900: "#383A3B",
  sidebar: "#F4F4F4",
  success100: "#DCFCE7",
  success800: "#166534",
  destructive100: "#FEE2E2",
  destructive500: "#EF4444",
  destructive800: "#991B1B",
  dark: "#212529",
  white: "#fff",
  zinc600: "#52525B",
  blue: "#007BCA",
  label: "#45434C",
};

export const backgroundColorVariants = {
  primary: "rgb(229, 231, 235)",
  primary50: "var(--color-current-50)",
  primary100: "var(--color-current-100)",
  primary300: "var(--color-current-300)",
  primary500: "var(--color-current-500)",
  neutral10: "#FDFDFE",
  neutral50: "#F9FAFB",
  neutral100: "#F3F4F6",
  neutral200: "#E5E7EB",
  neutral300: "#D1D5DB",
  neutral400: "#9CA3AF",
  neutral500: "#6B7280",
  neutral700: "#374151",
  neutral800: "#1F2937",
  neutral900: "#111827",
  gray50: "#FAFAFA",
  gray100: "#DDDDDD",
  gray200: "#C7C7C8",
  gray400: "#8F8E91",
  gray600: "#5C5A5F",
  gray700: "#262626",
  gray900: "#383A3B",
  sidebar: "#F4F4F4",
  overlay: "rgba(0, 0, 0, 0.5)",
  success100: "#DCFCE7",
  success800: "#166534",
  destructive100: "#FEE2E2",
  destructive500: "#EF4444",
  destructive800: "#991B1B",
  dark: "#1C1C1E",
  dark2: "#484848",
  white: "#fff",
  transparent: "transparent",
  plans: "#F6F6F6",
  blue: "#007BCA",
};
