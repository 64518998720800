import { Payroll, PayrollDocument } from "@graphql/crm";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { extractQueryData } from "../../../utils/validations";

export const useGetPayroll = (id?: string) => {
  const { data: query } = useUrqlQuery({
    query: PayrollDocument,
    variables: {
      id: id as string,
    },
    pause: id == null,
  });

  return extractQueryData(query, "value.value.payroll") as Payroll;
};
