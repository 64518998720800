import { Static, Status } from "@graphql/crm";
import { Dict } from "@swan-io/boxed";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FilterDateDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useMemo, useState } from "react";
import { locale, t } from "../../../utils/i18n";
import { staticToItem } from "../../../utils/utils";
import {
  isAfterEmitedAtSelectable,
  isBeforeEmitedAtSelectable,
  validateAfterEmitedAt,
  validateBeforeEmitedAt,
} from "../../../utils/validations";

type Props = {
  filters: PayrollFiltersState;
  statusItems: Static[];
  onChange: (values: Partial<PayrollFiltersState>) => void;
};

const isAfterEmitedAtFilter: FilterDateDef = {
  type: "date",
  label: t("hr.filters.isAfterEmitedAt"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateAfterEmitedAt,
  isSelectable: isAfterEmitedAtSelectable,
};

const isBeforeEmitedAtFilter: FilterDateDef = {
  type: "date",
  label: t("hr.filters.isBeforeEmitedAt"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateBeforeEmitedAt,
  isSelectable: isBeforeEmitedAtSelectable,
};

const statusFilter: FilterCheckboxDef<Status> = {
  type: "checkbox",
  label: t("common.status"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const filtersDefinition = {
  status: statusFilter,
  isAfterEmitedAt: isAfterEmitedAtFilter,
  isBeforeEmitedAt: isBeforeEmitedAtFilter,
};

export type PayrollFiltersState = FiltersState<typeof filtersDefinition> & {
  search?: string | undefined;
};

export const ListFilter = ({ filters, onChange, statusItems }: Props) => {
  statusFilter.items = staticToItem<Status>(statusItems);

  const filtersWithoutSearch = useMemo(() => {
    const { search, ...filtersWithoutSearch } = filters;
    return filtersWithoutSearch;
  }, [filters]);

  const availableFilters: { name: keyof typeof filtersWithoutSearch; label: string }[] = useMemo(
    () => [
      {
        name: "status",
        label: t("common.status"),
      },
      {
        name: "isAfterEmitedAt",
        label: t("hr.filters.isAfterEmitedAt"),
      },
      {
        name: "isBeforeEmitedAt",
        label: t("hr.filters.isBeforeEmitedAt"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filtersWithoutSearch)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filtersWithoutSearch)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filtersWithoutSearch]);

  return (
    <>
      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={value => {
          onChange({ ...value });
        }}
        onChangeOpened={setOpenFilters}
      />

      <FilterChooser
        filters={filtersWithoutSearch}
        openFilters={openFilters}
        label={t("common.filters")}
        title={""}
        onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
        availableFilters={availableFilters}
      />
    </>
  );
};
