import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { InputRounded } from "@components/forms/Input";
import {
  PayrollRelay,
  PayrollStatus,
  PayrollsDocument,
  PayrollsStaticDocument,
  Static,
} from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlPaginatedQuery, useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useMemo } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { ManageParamsOauthSwan } from "../../../utils/auth";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { extractQueryData } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { Navigation as ReportsNavigation } from "../components/Navigation";
import { DetailsList } from "../components/PayrollDetailsList";
import { ListFilter, PayrollFiltersState } from "../components/PayrollListFilter";
import { PayrollStats } from "../components/PayrollStats";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  list: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
});

const NUM_TO_RENDER = 7;

export const Payrolls = () => {
  ManageParamsOauthSwan("HrPayrollsList");
  const route = Router.useRoute(["HrPayrollsList"]);
  const params = match(route)
    .with({ name: "HrPayrollsList" }, ({ params }) => params)
    .otherwise(() => {});
  const { desktop } = useResponsive();

  const filters: PayrollFiltersState = useMemo(() => {
    return {
      search: params?.search,
      isAfterEmitedAt: params?.isAfterEmitedAt,
      isBeforeEmitedAt: params?.isBeforeEmitedAt,
      status: params?.status,
    } as PayrollFiltersState;
  }, [params?.search, params?.isAfterEmitedAt, params?.isBeforeEmitedAt, params?.status]);

  const {
    data: query,
    nextData,
    reload,
    isForceReloading,
  } = useUrqlPaginatedQuery(
    {
      query: PayrollsDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          status: { inList: params?.status as PayrollStatus[] },
          search: params?.search ?? undefined,
          issueDate: { gte: params?.isAfterEmitedAt, lte: params?.isBeforeEmitedAt },
        },
      },
    },
    [filters],
  );

  const { data: staticData } = useUrqlQuery({ query: PayrollsStaticDocument }, []);
  const statusItems = extractQueryData(staticData, "value.value.payrollsStatic.status") as Static[];

  const data = query
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ payrolls }) => Option.fromNullable(payrolls))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <ReportsNavigation />
            </Sidebar>
          )}

          <ScrollView>
            <View style={styles.root}>
              <View style={commonStyles.fill} role="main">
                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.header}
                >
                  <InputRounded>
                    <LakeSearchField
                      placeholder={t("common.search")}
                      initialValue={""}
                      onChangeText={search => Router.push("HrPayrollsList", { ...filters, search })}
                    />
                  </InputRounded>

                  <Box direction="row" justifyContent="end" alignItems="end">
                    <ListFilter
                      filters={filters}
                      statusItems={statusItems}
                      onChange={filters => Router.push("HrPayrollsList", filters)}
                    />
                  </Box>
                </Box>

                <Heading title={t("common.payrolls")}>
                  <PayrollStats forceReload={isForceReloading} filters={filters} />
                </Heading>

                <Box style={styles.list}>
                  <DetailsList
                    data={data as PayrollRelay[]}
                    statusItems={statusItems}
                    onActiveRowChange={() => {}}
                    onRefreshRequest={reload}
                    onEndReached={() => {}}
                    loading={{ isLoading: nextData.isLoading(), count: 20 }}
                    getRowLink={({ item }) => (
                      <Pressable
                        onPress={() =>
                          Router.push("HrPayrollsUpdate", { payrollId: item.id as string })
                        }
                      />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                  />
                </Box>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
