import { NavigationItem } from "@components/NavigationItem";
import { NavigationSubitem } from "@components/NavigationSubitem";
import { View } from "react-native";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";

export const Navigation = () => {
  return (
    <View role="navigation">
      <NavigationItem to={Router.ProjectsRoot()} label={t("projects")} />

      <NavigationSubitem
        to={Router.ProjectsRoot({ create: "" })}
        icon="add-circle-regular"
        label={t("project.new")}
      />
    </View>
  );
};
