import statsImage from "@assets/img/purchase-stats.webp";
import { HorizonalValues } from "@components/HorizontalValues";
import { Bars } from "@components/chart/Bars";
import { Doughnut } from "@components/chart/Doughnut";
import { PurchaseInvoicesStatsDocument } from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { useUrqlPaginatedQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect } from "react";
import { StyleSheet } from "react-native";
import { backgroundColorVariants, borderColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  desktop: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
  },
  mobile: {
    gap: 12,
  },
  box: {
    flex: 1,
    flexWrap: "wrap",
    borderRadius: 25,
    borderWidth: 2,
    borderColor: borderColorVariants.white,
    boxShadow:
      "0 67.609px 54.087px 0 rgba(201, 201, 201, 0.07), 0 28.246px 22.596px 0 rgba(108, 73, 172, 0.05), 0 15.101px 12.081px 0 rgba(108, 73, 172, 0.04), 0 8.466px 6.773px 0 rgba(108, 73, 172, 0.04), 0 4.496px 3.597px 0 rgba(108, 73, 172, 0.03), 0 1.871px 1.497px 0 rgba(108, 73, 172, 0.02)",
    paddingVertical: 16,
    paddingHorizontal: 32,
    backgroundImage: "linear-gradient(194deg, #4B4B4B, #262626)",
  },
});

type Props = {
  forceReload?: boolean;
  search?: string;
  gte?: string;
  lte?: string;
};

export const PurchaseStats = ({ forceReload = false, search, gte, lte }: Props) => {
  const { data: stats, reload } = useUrqlPaginatedQuery(
    {
      query: PurchaseInvoicesStatsDocument,
      variables: {
        filters: {
          search,
          issueDate: { gte, lte },
        },
      },
    },
    [search, gte, lte],
  );

  const { labels, dataset1, count, totalAmountPaid, totalAmountPending, total, labels2, dataset2 } =
    stats
      .toOption()
      .flatMap(result => result.toOption())
      .flatMap(row => Option.fromNullable(row.purchaseInvoicesStats))
      .map(
        ({
          labels,
          dataset1,
          count,
          totalAmountPaid,
          totalAmountPending,
          total,
          labels2,
          dataset2,
        }) => {
          return {
            labels,
            dataset1,
            count,
            totalAmountPaid,
            totalAmountPending,
            total,
            labels2,
            dataset2,
          };
        },
      )
      .getWithDefault({
        labels: [],
        dataset1: [],
        count: 0,
        totalAmountPaid: 0,
        totalAmountPending: 0,
        total: 0,
        labels2: [],
        dataset2: [],
      });

  useEffect(() => {
    if (!forceReload) {
      return;
    }
    reload();
  }, [forceReload]);

  return (
    <ResponsiveContainer>
      {({ large }) => (
        <Box style={styles.container}>
          <HorizonalValues
            data={[
              {
                title: t("invoices.totalInvoices"),
                value: count.toString(),
              },
              {
                title: t("invoices.totalAmountPaid"),
                value: formatCurrency(totalAmountPaid as number, "EUR"),
              },
              {
                title: t("invoices.totalAmountPending"),
                value: formatCurrency(totalAmountPending as number, "EUR"),
              },
            ]}
            image={statsImage}
            imageHeight={150}
            style={{ backgroundColor: backgroundColorVariants.dark2 }}
          />

          <Box style={[!large && styles.mobile, large && styles.desktop]}>
            <Box style={styles.box}>
              <Bars
                labels={labels}
                datasets={[
                  {
                    data: [],
                  },
                  {
                    data: dataset1.map(value => value as number),
                  },
                ]}
              />
            </Box>

            <Box
              direction="row"
              alignItems="center"
              justifyContent="spaceBetween"
              style={styles.box}
            >
              <Doughnut
                labels={labels2}
                datasets={[
                  {
                    data: dataset2.map(value => value as number),
                  },
                ]}
                totalTitle={t("invoices.totalInvoices")}
                total={total as number}
              />
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContainer>
  );
};
