import { ErrorView } from "@components/ErrorView";
import { ContactRelay, CustomerDocument, SupplierDocument } from "@graphql/crm";
import { AsyncData, Result } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { ListRightPanelContent } from "@swan-io/lake/src/components/ListRightPanel";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ActivityIndicator, ScrollView, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { DetailEditor } from "./DetailEditor";
import { getColor, getTypename } from "./ListCells";

type Props = {
  contact: ContactRelay;
  large: boolean;
  onClose?: () => void;
};

const styles = StyleSheet.create({
  scrollContainerMobile: {
    // used only for sticky tabs
    minHeight: "100%",
  },
  scrollContainerDesktop: {
    ...commonStyles.fill,
  },
  container: {
    ...commonStyles.fill,
  },
  tile: {
    alignItems: "center",
  },
  wrapText: {
    wordBreak: "break-all",
  },
  tag: {
    borderRadius: 50,
    paddingHorizontal: 12,
  },
});

export const Detail = ({ contact, large, onClose }: Props) => {
  const document = match(contact.type)
    .with("CUSTOMER", () => CustomerDocument)
    .with("SUPPLIER", () => SupplierDocument)
    .exhaustive();

  const { data, reload } = useUrqlQuery(
    { query: document, variables: { id: String(contact.pk) } },
    [],
  );

  if (contact === null) {
    return <ErrorView />;
  }

  return (
    <ScrollView
      contentContainerStyle={large ? styles.scrollContainerDesktop : styles.scrollContainerMobile}
    >
      <View style={styles.container}>
        <ListRightPanelContent large={large}>
          <Tile style={styles.tile}>
            <Box alignItems="center">
              <Tag color={getColor(contact.type)} style={styles.tag}>
                {getTypename(contact.type)}
              </Tag>

              <Space height={12} />

              <LakeHeading
                level={1}
                variant={large ? "h1" : "h3"}
                align="center"
                style={styles.wrapText}
              >
                {contact.name}
              </LakeHeading>

              <Space height={8} />

              <LakeHeading level={3} variant="h4" align="center" color={colors.gray[700]}>
                {contact.nif}
              </LakeHeading>
            </Box>
          </Tile>
        </ListRightPanelContent>

        <Space height={24} />

        {match(data)
          .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
            <ActivityIndicator color={colors.gray[900]} />
          ))
          .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
            <ListRightPanelContent large={large}>
              <DetailEditor
                type={contact.type}
                contact={data}
                reload={reload}
                onRefreshRequest={() => {
                  onClose?.();
                }}
              />
            </ListRightPanelContent>
          ))
          .otherwise(() => (
            <ErrorView />
          ))}
      </View>
    </ScrollView>
  );
};
