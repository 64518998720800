import { DatePickerComplex } from "@components/forms/DatePickerComplex";
import { Box } from "@swan-io/lake/src/components/Box";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { Pressable, StyleSheet, Text, View, ViewStyle } from "react-native";
import { useDateFilter } from "../hooks/useDateFilter";
import { borderColorVariants, fontColorVariants } from "../styles/constants";
import { encodeDateISO, toDate } from "../utils/date";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  overlay: {
    position: "fixed",
    inset: 0,
    zIndex: 9,
  },
  calendar: {
    gap: 12,
    userSelect: "none",
    paddingVertical: 10,
    paddingHorizontal: 20,
    color: fontColorVariants.gray500,
    borderWidth: 1,
    borderColor: borderColorVariants.gray300,
    borderRadius: 6,
  },
  calendarText: {
    color: fontColorVariants.gray600,
    fontWeight: "600",
  },
});

type Props = {
  style?: ViewStyle;
  open?: boolean;
  onOpen?: (open: boolean) => void;
};

export const Calendar = ({ style, open = false, onOpen }: Props) => {
  const { from, to, setDates } = useDateFilter();

  const [openCalendar, setOpenCalendar] = useState(open);

  useEffect(() => {
    onOpen?.(open);
  }, [open]);

  return (
    <View>
      <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
        <Box direction="row" style={styles.calendar}>
          <Text style={styles.calendarText}>
            {from && to ? `${encodeDateISO(from)} - ${encodeDateISO(to)}` : t("common.date")}
          </Text>

          <FaChevronDown size={14} />
        </Box>
      </Pressable>

      {openCalendar && (
        <>
          <Pressable style={styles.overlay} onPress={() => setOpenCalendar(false)} />

          <DatePickerComplex
            from={toDate(from)}
            to={toDate(to)}
            onChange={dates => {
              setDates(...dates);
              setOpenCalendar(false);
            }}
            style={style}
          />
        </>
      )}
    </View>
  );
};
