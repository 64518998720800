import background from "@assets/img/bg-clock-card.webp";
import logo from "@assets/img/logo-trexx.svg";
import { PlanCard } from "@components/PlanCard";
import { Plan, PlansDocument } from "@graphql/crm";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ScrollView, StyleSheet, Text, View, useWindowDimensions } from "react-native";
import { useUser } from "../features/context/UserContext";
import { common } from "../styles/common";
import { t } from "../utils/i18n";
import { COLORS } from "../values/colors";

const styles = StyleSheet.create({
  left: {
    display: "flex",
    flexBasis: "30%",
    justifyContent: "space-between",
    paddingTop: 54,
    backgroundImage: `url(${background})`,
    backgroundSize: "contain",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
  },
  leftContainer: {
    paddingHorizontal: 35,
  },
  right: {
    padding: 45,
  },
  container: {
    alignItems: "center",
    gap: 24,
    paddingBottom: 32,
  },
  title: {
    fontSize: 23,
    fontWeight: "800",
  },
  subtitle: {
    fontSize: 10,
    fontWeight: "400",
    color: COLORS.NEUTRAL500,
  },
  plans: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 20,
  },
});

export const PlansPage = () => {
  const { company } = useUser();
  const { data } = useUrqlQuery({ query: PlansDocument }, []);

  const { width } = useWindowDimensions();
  const isDesktop = width > 1145;

  const plans = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.plans)
    .getWithDefault([])
    .filter(row => row.constitution === company?.constitution);

  return (
    <>
      <ScrollView centerContent={true} style={common.bgPrimary50}>
        <Box direction={"row"} style={common.h100}>
          {isDesktop && (
            <Box style={[styles.left, common.h100]}>
              <View style={styles.leftContainer}>
                <AutoWidthImage sourceUri={logo} height={48} alt="Trexx" />
              </View>
            </Box>
          )}

          <ScrollView centerContent={true} style={[styles.right, common.bgWhite]}>
            <Box direction="column" style={styles.container}>
              <View>
                <LakeHeading level={1} style={styles.title}>
                  {t("plan.title")}
                </LakeHeading>

                <Space height={8} />
                <Text style={styles.subtitle}>{t("plan.subtitle")}</Text>
              </View>

              <Box style={styles.plans}>
                {plans.map(plan => (
                  <PlanCard key={plan.id} plan={plan as Plan} />
                ))}
              </Box>
            </Box>
          </ScrollView>
        </Box>
      </ScrollView>
    </>
  );
};
