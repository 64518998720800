import { Modal, StyleSheet, View } from "react-native";
import BeatLoader from "react-spinners/BeatLoader";
import { useLoading } from "../features/context/LoadingContext";
import { backgroundColorVariants } from "../styles/constants";

const styles = StyleSheet.create({
  overlayContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: backgroundColorVariants.overlay,
    display: "flex",
    justifyContent: "center",
    alignIems: "center",
    zIndex: 999999999,
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignIems: "center",
    zIndex: 999999999,
  },
});

export const Loader = () => {
  const { loading } = useLoading();

  return (
    <>
      {Boolean(loading) ? (
        <Modal transparent={true} animationType="none" visible={loading} onRequestClose={() => {}}>
          <View style={styles.overlayContainer}>
            <BeatLoader color="#ffffff" loading={loading} size={15} cssOverride={styles.loader} />
          </View>
        </Modal>
      ) : null}
    </>
  );
};
