import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DateCell } from "@components/forms/DateCell";
import { DefaultCell } from "@components/forms/DefaultCell";
import { PriceCell } from "@components/forms/PriceCell";
import { PurchaseInvoiceCreateStaticDocument, PurchaseInvoiceRelay } from "@graphql/crm";
import { AsyncData, Result } from "@swan-io/boxed";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ReactElement, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { P, match } from "ts-pattern";
import { encodeDateISO } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { tapError } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { extractPurchaseStatic } from "../utils";
import { ColumnHeader } from "./ColumnHeader";
import KebabMenuPurchase from "./KebabMenuPurchase";
import { ProjectAssignmentModal } from "./ProjectAssignmentModal";
import { PurchaseUpdateStatusModal } from "./PurchaseUpdateStatusModal";
import { StatusCell } from "./StatusCell";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  invoices: PurchaseInvoiceRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  setActiveInvoice: (invoice: PurchaseInvoiceRelay) => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<PurchaseInvoiceRelay, ExtraInfo>) => ReactElement;
  sortBy?: string;
  setSort?: (columnId: string) => void;
};
type ExtraInfo = undefined;

export const PurchaseDetailsList = ({
  invoices,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  onRefreshRequest,
  renderEmptyList,
  sortBy,
  setSort,
}: Props) => {
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<PurchaseInvoiceRelay>();
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false);
  const { data } = useUrqlQuery({ query: PurchaseInvoiceCreateStaticDocument }, []);

  match(data).with(AsyncData.P.Done(Result.P.Error(P.select())), tapError);

  const paymentMethods = extractPurchaseStatic(data, "paymentMethods");

  const columns: ColumnConfig<PurchaseInvoiceRelay, ExtraInfo>[] = [
    {
      id: "issueDate",
      width: 120,
      title: t("invoices.issueDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DateCell date={item.issueDate ?? ""} align="left" />,
      sortable: true,
    },
    {
      id: "seriesCurrent",
      width: 140,
      title: t("invoices.seriesNumber"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.seriesCurrent ?? ""} />,
      sortable: true,
    },
    {
      id: "customer",
      width: "grow",
      title: t("contact.supplier"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.issuer?.name ?? ""} />,
      sortable: true,
    },
    {
      id: "status",
      width: "grow",
      title: t("common.status"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="left" />,
      renderCell: ({ item }) => (
        <StatusCell
          status={item.status ?? ""}
          text={item.statusDescription ?? ""}
          paymentDate={encodeDateISO(item.paymentDate ?? "")}
          paymentMethod={paymentMethods.find(pm => pm.id === item.paymentMethod)?.name ?? ""}
          align="left"
          onChangeStatus={() => {
            if (["PENDING", "OVERDUED"].includes(item.status)) {
              setInvoice(item);
              setUpdateStatusModal(true);
            }
          }}
          invoiceId={String(item.id)}
          setInvoiceId={() => null}
          onAction={() => null}
        />
      ),
      sortable: true,
    },
    {
      id: "totalTaxBase",
      width: 140,
      title: t("invoices.taxBase"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.totalTaxBase as number} align="right" />,
      sortable: true,
    },
    {
      id: "vat",
      width: 100,
      title: t("invoices.iva"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={Number(item.totalVat)} align="right" />,
    },
    {
      id: "irpf",
      width: 100,
      title: t("invoices.irpf"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.totalIrpf as number} align="right" />,
    },
    {
      id: "total",
      width: 100,
      title: t("invoices.total"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.total as number} align="right" />,
      sortable: true,
    },
    {
      id: "options",
      width: 80,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <KebabMenuPurchase
          invoice={item}
          onRefreshRequest={onRefreshRequest}
          setShowAssignmentModal={setShowAssignmentModal}
          setInvoice={setInvoice}
        />
      ),
    },
  ];

  const smallColumns: ColumnConfig<PurchaseInvoiceRelay, ExtraInfo>[] = columns.filter(c =>
    ["issueDate", "customer", "total", "options"].includes(c.id),
  );

  return (
    <>
      <PlainListView
        withoutScroll={true}
        data={invoices}
        keyExtractor={item => item.id as string}
        headerHeight={36}
        rowHeight={63}
        headerStyle={styles.header as ViewStyle}
        headerBackgroundColor="#EDEDED"
        groupHeaderHeight={36}
        extraInfo={undefined}
        columns={columns}
        smallColumns={smallColumns}
        activeRowId={activeRowId}
        onActiveRowChange={onActiveRowChange}
        loading={loading}
        onEndReached={onEndReached}
        getRowLink={getRowLink}
        renderEmptyList={renderEmptyList}
        breakpoint={breakpoints.medium}
        sortBy={sortBy}
        setSort={setSort}
      />

      <PurchaseUpdateStatusModal
        visible={updateStatusModal}
        onPressClose={() => {
          onRefreshRequest();
          setUpdateStatusModal(false);
        }}
        invoice={invoice}
      />

      <ProjectAssignmentModal
        showAssignment={showAssignmentModal}
        invoiceId={invoice?.id as string}
        type="PURCHASE"
        onPressClose={() => {
          onRefreshRequest();
          setShowAssignmentModal(false);
        }}
      />
    </>
  );
};
