import { Series } from "@graphql/crm";
import { getYear } from "./date";

export const substitueValues = (serie: Series): string => {
  const { format, prefix, currentSequence, year } = serie;
  return format
    .replace("{PREFIX}", prefix ?? "")
    .replace("{YEAR}", year?.toString() ?? getYear().toString())
    .replace("{SERIE}", currentSequence.toString().padStart(4, "0"))
    .replace("{SEPARATOR}", "/");
};
