import { Static } from "@graphql/crm";
import { Dict } from "@swan-io/boxed";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useMemo, useState } from "react";
import { t } from "../../../utils/i18n";
import { staticToItemLower } from "../../../utils/utils";

type Props = {
  filters: AccountingListFiltersState;
  types: Static[];
  onChange: (values: Partial<AccountingListFiltersState>) => void;
};

const typeFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("accounting.type"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const filtersDefinition = {
  type: typeFilter,
};

export type AccountingListFiltersState = FiltersState<typeof filtersDefinition> & {
  search?: string | undefined;
};

export const ListFilter = ({ filters, onChange, types }: Props) => {
  typeFilter.items = staticToItemLower<string>(types);
  const filtersWithoutSearch = useMemo(() => {
    const { search, ...filtersWithoutSearch } = filters;
    return filtersWithoutSearch;
  }, [filters]);

  const availableFilters: { name: keyof typeof filtersWithoutSearch; label: string }[] = useMemo(
    () => [
      {
        name: "type",
        label: t("accounting.type"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filtersWithoutSearch)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filtersWithoutSearch)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filtersWithoutSearch]);

  return (
    <>
      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={value => {
          onChange({ ...value });
        }}
        onChangeOpened={setOpenFilters}
      />

      <FilterChooser
        filters={filtersWithoutSearch}
        openFilters={openFilters}
        label={t("common.filters")}
        title={""}
        onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
        availableFilters={availableFilters}
      />
    </>
  );
};
