import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { ProfitAndLossOutput } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { StyleSheet } from "react-native";
import { ViewStyle } from "react-native/Libraries/StyleSheet/StyleSheetTypes";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";

const styles = StyleSheet.create({
  container: {
    backgroundColor: backgroundColorVariants.white,
    padding: 8,
  },
  header: {
    fontWeight: "600",
    "--color-gray-900": fontColorVariants.gray500,
    "--text-medium-font-size": 12,
  },
  row: {
    backgroundColor: backgroundColorVariants.gray50,
  },
  rowHead: {
    backgroundColor: backgroundColorVariants.gray400,
    color: fontColorVariants.white,
  },
});

type Row = {
  name: string;
  amount: number;
  percent: unknown;
  sector: unknown;
  diff: unknown;
  style: ViewStyle;
  notes?: string;
};
type ExtraInfo = undefined;

type Props = {
  summary: ProfitAndLossOutput;
};

export const ProfitAndLossReport = ({ summary }: Props) => {
  const { amounts, percents, sector, diff } = summary;

  const data: Row[] = [
    {
      name: t("reports.cifraNegocio"),
      amount: (amounts?.cifraNegocio as number) ?? 0,
      percent: percents?.cifraNegocio,
      sector: sector?.cifraNegocio,
      diff: diff?.cifraNegocio,
      style: styles.rowHead,
    },
    {
      name: t("reports.variacionExistencias"),
      amount: (amounts?.variacionExistencias as number) ?? 0,
      percent: percents?.variacionExistencias,
      sector: sector?.variacionExistencias,
      diff: diff?.variacionExistencias,
      notes:
        Number(diff?.variacionExistencias) > 0
          ? t("reports.variacionExistenciasNotes1")
          : t("reports.variacionExistenciasNotes2"),
      style: styles.row,
    },
    {
      name: t("reports.trabajosRealizadosEmpresaActivo"),
      amount: (amounts?.trabajosRealizadosEmpresaActivo as number) ?? 0,
      percent: percents?.trabajosRealizadosEmpresaActivo,
      sector: sector?.trabajosRealizadosEmpresaActivo,
      diff: diff?.trabajosRealizadosEmpresaActivo,
      style: styles.row,
    },
    {
      name: t("reports.aprovisionamientos"),
      amount: (amounts?.aprovisionamientos as number) ?? 0,
      percent: percents?.aprovisionamientos,
      sector: sector?.aprovisionamientos,
      diff: diff?.aprovisionamientos,
      notes:
        Number(diff?.aprovisionamientos) > 0
          ? t("reports.aprovisionamientosNotes1")
          : t("reports.aprovisionamientosNotes2"),
      style: styles.row,
    },
    {
      name: t("reports.margenBruto"),
      amount: (amounts?.margenBruto as number) ?? 0,
      percent: percents?.margenBruto,
      sector: sector?.margenBruto,
      diff: diff?.margenBruto,
      style: styles.rowHead,
    },
    {
      name: t("reports.otrosIngresosExplotacion"),
      amount: (amounts?.otrosIngresosExplotacion as number) ?? 0,
      percent: percents?.otrosIngresosExplotacion,
      sector: sector?.otrosIngresosExplotacion,
      diff: diff?.otrosIngresosExplotacion,
      style: styles.row,
    },
    {
      name: t("reports.otrosGastosExplotacion"),
      amount: (amounts?.otrosGastosExplotacion as number) ?? 0,
      percent: percents?.otrosGastosExplotacion,
      sector: sector?.otrosGastosExplotacion,
      diff: diff?.otrosGastosExplotacion,
      notes:
        Number(diff?.otrosGastosExplotacion) > 0
          ? t("reports.otrosGastosExplotacionNotes1")
          : t("reports.otrosGastosExplotacionNotes2"),
      style: styles.row,
    },
    {
      name: t("reports.valorAnadido"),
      amount: (amounts?.valorAnadido as number) ?? 0,
      percent: percents?.valorAnadido,
      sector: sector?.valorAnadido,
      diff: diff?.valorAnadido,
      style: styles.rowHead,
    },
    {
      name: t("reports.gastosPersonal"),
      amount: (amounts?.gastosPersonal as number) ?? 0,
      percent: percents?.gastosPersonal,
      sector: sector?.gastosPersonal,
      diff: diff?.gastosPersonal,
      notes:
        Number(diff?.gastosPersonal) > 0
          ? t("reports.gastosPersonalNotes1")
          : t("reports.gastosPersonalNotes2"),
      style: styles.row,
    },
    {
      name: t("reports.ebitda"),
      amount: (amounts?.ebitda as number) ?? 0,
      percent: percents?.ebitda,
      sector: sector?.ebitda,
      diff: diff?.ebitda,
      notes: Number(diff?.ebitda) > 0 ? t("reports.ebitdaNotes1") : t("reports.ebitdaNotes2"),
      style: styles.rowHead,
    },
    {
      name: t("reports.amortizaciones"),
      amount: (amounts?.amortizaciones as number) ?? 0,
      percent: percents?.amortizaciones,
      sector: sector?.amortizaciones,
      diff: diff?.amortizaciones,
      notes:
        Number(diff?.amortizaciones) > 0
          ? t("reports.amortizacionesNotes1")
          : t("reports.amortizacionesNotes2"),
      style: styles.row,
    },
    {
      name: t("reports.enajenacionesInmovilizado"),
      amount: (amounts?.enajenacionesInmovilizado as number) ?? 0,
      percent: percents?.enajenacionesInmovilizado,
      sector: sector?.enajenacionesInmovilizado,
      diff: diff?.enajenacionesInmovilizado,
      style: styles.row,
    },
    {
      name: t("reports.otros"),
      amount: (amounts?.otros as number) ?? 0,
      percent: percents?.otros,
      sector: sector?.otros,
      diff: diff?.otros,
      style: styles.row,
    },
    {
      name: t("reports.ebit"),
      amount: (amounts?.ebit as number) ?? 0,
      percent: percents?.ebit,
      sector: sector?.ebit,
      diff: diff?.ebit,
      style: styles.rowHead,
    },
    {
      name: t("reports.ingresosFinancieros"),
      amount: (amounts?.ingresosFinancieros as number) ?? 0,
      percent: percents?.ingresosFinancieros,
      sector: sector?.ingresosFinancieros,
      diff: diff?.ingresosFinancieros,
      style: styles.row,
    },
    {
      name: t("reports.gastosFinancieros"),
      amount: (amounts?.gastosFinancieros as number) ?? 0,
      percent: percents?.gastosFinancieros,
      sector: sector?.gastosFinancieros,
      diff: diff?.gastosFinancieros,
      notes:
        Number(diff?.gastosFinancieros) > 0
          ? t("reports.gastosFinancierosNotes1")
          : t("reports.gastosFinancierosNotes2"),
      style: styles.row,
    },
    {
      name: t("reports.ebt"),
      amount: (amounts?.ebt as number) ?? 0,
      percent: percents?.ebt,
      sector: sector?.ebt,
      diff: diff?.ebt,
      style: styles.rowHead,
    },
    {
      name: t("reports.impuestos"),
      amount: (amounts?.impuestos as number) ?? 0,
      percent: percents?.impuestos,
      sector: sector?.impuestos,
      diff: diff?.impuestos,
      style: styles.row,
    },
    {
      name: t("reports.resultadoNeto"),
      amount: (amounts?.resultadoNeto as number) ?? 0,
      percent: percents?.resultadoNeto,
      sector: sector?.resultadoNeto,
      diff: diff?.resultadoNeto,
      style: styles.rowHead,
    },
    {
      name: t("reports.cashflowOperativo"),
      amount: (amounts?.cashflowOperativo as number) ?? 0,
      percent: percents?.cashflowOperativo,
      sector: sector?.cashflowOperativo,
      diff: diff?.cashflowOperativo,
      style: styles.rowHead,
    },
  ];

  const columns: ColumnConfig<Row, ExtraInfo>[] = [
    {
      id: "name",
      title: t("reports.concepts"),
      width: "grow",
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.name} style={item.style} align="left" />,
    },
    {
      id: "amount",
      title: t("reports.amount"),
      width: 100,
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => (
        <DefaultCell data={formatCurrency(item.amount, "EUR")} align="right" style={item.style} />
      ),
    },
    {
      id: "percent",
      title: t("reports.percentCN"),
      width: 100,
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => (
        <DefaultCell data={`${item.percent as string}%`} align="right" style={item.style} />
      ),
    },
    {
      id: "sector",
      title: t("reports.percentSector"),
      width: 100,
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => (
        <DefaultCell data={`${item.sector as string}%`} align="right" style={item.style} />
      ),
    },
    {
      id: "diff",
      title: t("reports.percentDiff"),
      width: 100,
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => (
        <>
          <DefaultCell data={`${item.diff as string}%`} align="right" style={item.style}>
            <Space width={4} />

            {Number(item.diff) > 0 && <FaArrowUp color="#16A34A" size={12} />}
            {Number(item.diff) < 0 && <FaArrowDown color="#E11D48" size={12} />}
          </DefaultCell>
        </>
      ),
    },
    {
      id: "notes",
      title: t("reports.notes"),
      width: 300,
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.notes ?? ""} style={item.style} />,
    },
  ];

  const smallColumns: ColumnConfig<Row, ExtraInfo>[] = columns.filter(c => c.id !== "notes");

  return (
    <Box style={styles.container}>
      <PlainListView
        withoutScroll={true}
        data={data}
        keyExtractor={item => item.name}
        headerHeight={40}
        rowHeight={40}
        rowStyle={({ style }) => style}
        headerStyle={styles.header as ViewStyle}
        headerBackgroundColor="#EDEDED"
        groupHeaderHeight={40}
        extraInfo={undefined}
        columns={columns}
        smallColumns={smallColumns}
        breakpoint={breakpoints.medium}
      />
    </Box>
  );
};
